import React, { useContext } from "react";
import Navigation from "../../../components/navigation/Navigation";
import MainRoomContext from "../../../contexts/MainRoomContext";
import LeaveModal from "../../../modals/LeaveModal";
import useLeaveQuizMutation from "../../../hooks/mutations/useLeaveQuizMutation";
import useNavigateHome from "../../../hooks/useNavigateHome";
import SettingsController from "../../SettingsController";
import useNavigateToSurvey from "../../../hooks/useNavigateToSurvey";
import QuizContext from "../../../../contexts/QuizContext";
import QuizNavigationContext from "../../../contexts/QuizNavigationContext";
import SettingsContext from "../../../contexts/SettingsContext";

const QuizNavigationController = () => {
  const { started, myPlayerId, myHostId } = useContext(QuizContext);
  const { muted, setMuted, videoStopped, setVideoStopped } = useContext(
    MainRoomContext
  );
  const {
    showSettingsModal,
    setShowSettingsModal,
    showLeaveModal,
    setShowLeaveModal,
  } = useContext(QuizNavigationContext);
  const { initSettings } = useContext(SettingsContext);

  const [leaveQuizMutation] = useLeaveQuizMutation();

  const navigateHome = useNavigateHome();
  const navigateToSurvey = useNavigateToSurvey();

  const handleShowSettings = () => {
    initSettings();
    setShowSettingsModal(true);
  };

  const handleLeave = async () => {
    if (myPlayerId && started) {
      await navigateToSurvey();
    } else if (!started || myHostId) {
      await leaveQuizMutation();
      await navigateHome();
    }
  };

  return (
    <>
      <Navigation
        micState={muted ? "off" : "on"}
        videoState={videoStopped ? "off" : "on"}
        onMicStateChange={(newState) => setMuted(newState === "off")}
        onVideoStateChange={(newState) => setVideoStopped(newState === "off")}
        onShowSettingsModal={handleShowSettings}
        onShowLeaveModal={() => setShowLeaveModal(true)}
        modal={
          <>
            {showSettingsModal && (
              <SettingsController onDone={() => setShowSettingsModal(false)} />
            )}
            {showLeaveModal && (
              <LeaveModal
                onLeave={handleLeave}
                onCancel={() => setShowLeaveModal(false)}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default QuizNavigationController;
