import React, { useContext, useState } from "react";
import Modal from "../Modal";
import DangerButton from "../buttons/DangerButton";
import OurButton from "../buttons/Button";
import QuizContext from "../../contexts/QuizContext";

export default ({ team, onClose }) => {
  const { deleteTeam } = useContext(QuizContext);
  const [submitted, setSubmitted] = useState(false);

  const handleDeleteTeam = async () => {
    setSubmitted(true);
    await deleteTeam(team.id);
    onClose();
  };

  const teamText = team.name || "this unnamed team";

  return (
    <Modal
      title="Delete Team"
      text={`Are you sure you want to delete ${teamText}?`}
      buttons={
        <>
          <DangerButton
            size="large"
            onClick={handleDeleteTeam}
            disabled={submitted}
            data-cy="delete-team-button"
          >
            {submitted ? "Deleting" : "Delete"}
          </DangerButton>
          <OurButton
            size="large"
            onClick={onClose}
            data-cy="cancel-delete-team-button"
          >
            Cancel
          </OurButton>
        </>
      }
    />
  );
};
