import React, { useContext, useState } from "react";
import styled from "styled-components";
import PageHeading from "../PageHeading";
import PlayerList from "../PlayerList";
import Button from "../buttons/Button";
import CreateTeamModal from "../modals/CreateTeamModal";
import QuizContext from "../../contexts/QuizContext";
import _ from "lodash";
import { LIGHT_GREY_2, MUTED_TEXT } from "../../colors";

const NonTeamPlayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: ${LIGHT_GREY_2};
  padding: 20px;
  border-radius: 10px;
  position: relative;
`;

const Message = styled.div`
  text-align: center;
  color: ${MUTED_TEXT};
`;

const TeamAndPlayerActions = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
`;

const NameTeamJoinOrCreate = () => {
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const { quiz, myPlayerId } = useContext(QuizContext);

  const handleCreateTeamClick = () => setShowCreateTeamModal(true);
  const handleCloseCreateTeam = () => setShowCreateTeamModal(false);

  const freeAgents = quiz.players.filter((player) => player.team === null);

  const sortedFreeAgents = _.sortBy(freeAgents, [
    (player) => (player.id === myPlayerId ? 0 : 1),
    (player) => (player.name === "" ? "zzzzzzzzzzzz" : player.name),
  ]);

  return (
    <>
      <PageHeading>Join or Create a Team</PageHeading>
      <NonTeamPlayersWrapper data-cy="non-team-players">
        {!quiz.players.length && <Message>No players have joined yet</Message>}
        {freeAgents.length > 0 && (
          <Message>
            {freeAgents.length} player{freeAgents.length !== 1 && "s"} not on a
            team yet
          </Message>
        )}
        {freeAgents.length === 0 && quiz.players.length > 0 && (
          <Message>All players are on a team</Message>
        )}
        {freeAgents.length > 0 && <PlayerList players={sortedFreeAgents} />}
      </NonTeamPlayersWrapper>
      <TeamAndPlayerActions>
        <Button onClick={handleCreateTeamClick}>Create a New Team</Button>
      </TeamAndPlayerActions>
      {showCreateTeamModal && (
        <CreateTeamModal
          onCreate={handleCloseCreateTeam}
          onCancel={handleCloseCreateTeam}
        />
      )}
    </>
  );
};

export default NameTeamJoinOrCreate;
