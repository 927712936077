import styled from "styled-components";
import { LIGHT_REGULAR_TEXT } from "../../colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${LIGHT_REGULAR_TEXT};
`;

export const Title = styled.div`
  font-size: 30px;
  line-height: 120%;
`;

export const InstructionList = styled.ol`
  max-width: 600px;
  font-size: 24px;
`;

export const Instruction = styled.li`
  margin-bottom: 20px;
`;

export const InstructionHeading = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
  line-height: 120%;
`;

export const SubInstructionList = styled.ol`
  max-width: 600px;
  font-size: 20px;
`;

export const SubInstruction = styled.li`
  margin-bottom: 20px;
`;

export const SubInstructionHeading = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
  line-height: 120%;
`;
