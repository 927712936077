import React, { useContext } from "react";
import PageHeading from "../PageHeading";
import MainRoomContext from "../../contexts/MainRoomContext";
import { DEBUG } from "../../flags";
import PlayerGallery from "../PlayerGallery";

export default () => {
  const { participantsWithAudio, participantsWithVideo } = useContext(
    MainRoomContext
  );

  return (
    <>
      <PageHeading>Waiting Room</PageHeading>
      {DEBUG && (
        <>
          <div>
            Participants with audio:{" "}
            {`${Object.values(participantsWithAudio).length}`}
          </div>
          <div>
            Participants with video:{" "}
            {`${Object.values(participantsWithVideo).length}`}
          </div>
        </>
      )}
      <PlayerGallery />
    </>
  );
};
