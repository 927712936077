import React from "react";
import styled from "styled-components";
import GradientCard from "../elements/cards/GradientCard";
import { TITLE_HERO } from "../../typography";
import { WHITE } from "../../colors";

const StyledCard = styled(GradientCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px;
`;

const Title = styled.div`
  ${TITLE_HERO};
  color: ${WHITE};
  text-align: center;
`;

const QuizIntro = ({ title }) => {
  return (
    <StyledCard>
      <Title>{title}</Title>
    </StyledCard>
  );
};

export default QuizIntro;
