import React, { useContext } from "react";
import Participant from "../NewParticipant";
import QuizContext from "../../contexts/QuizContext";

const FakeDominantSpeaker = () => {
  const { fakeDominantSpeakerId, hosts, players } = useContext(QuizContext);

  const hostOrPlayer =
    hosts.find((host) => host.id === fakeDominantSpeakerId) ||
    players.find((player) => player.id === fakeDominantSpeakerId);

  return (
    <Participant
      size="dominant"
      participantId={fakeDominantSpeakerId}
      name={hostOrPlayer.name}
      fake
      image={hostOrPlayer.image}
    />
  );
};

export default FakeDominantSpeaker;
