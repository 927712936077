import QuizActionButton from "../buttons/QuizActionButton";
import DangerButton from "../buttons/DangerButton";
import React, { useContext, useState } from "react";
import ResetQuizModal from "../modals/ResetQuizModal";
import QuizContext from "../../contexts/QuizContext";
import _ from "lodash";
import { FACTS, QUESTION_RESULTS } from "../../constants";

export default () => {
  const {
    quiz,
    whatToShow,
    question,
    showFacts,
    nextQuestion,
    showRoundScores,
    canResetQuiz,
  } = useContext(QuizContext);

  if (!quiz) {
    return null;
  }

  const round = _.get(quiz, ["rounds", quiz.roundNumber - 1]);

  const moreQuestions = quiz.questionNumber < round.questions.length;

  const [showResetModal, setShowResetModal] = useState();

  const handleResetClick = () => {
    setShowResetModal(true);
  };

  const handleCancelReset = () => {
    setShowResetModal(false);
  };

  return (
    <>
      {whatToShow === QUESTION_RESULTS && question.facts && (
        <QuizActionButton
          size="large"
          label="Show Facts"
          clickedLabel="Showing Facts"
          onClick={showFacts}
        />
      )}
      {(!question.facts || whatToShow === FACTS) && moreQuestions && (
        <QuizActionButton
          size="large"
          label="Next Question"
          clickedLabel="Next Question..."
          onClick={nextQuestion}
        />
      )}
      {(!question.facts || whatToShow === FACTS) && !moreQuestions && (
        <QuizActionButton
          size="large"
          label="Show Round Scores"
          clickedLabel="Showing Round Scores"
          onClick={showRoundScores}
        />
      )}
      {canResetQuiz && (
        <DangerButton size="large" onClick={handleResetClick}>
          Reset Quiz
        </DangerButton>
      )}
      {showResetModal && (
        <ResetQuizModal
          publicQuizId={quiz.publicId}
          onClose={handleCancelReset}
        />
      )}
    </>
  );
};
