import React from "react";
import styled from "styled-components";
import backgroundImage from "../../images/hedgehog-background.png";

const Wrapper = styled.div`
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImage});
  background-size: cover;
`;

const Background = () => {
  return <Wrapper />;
};

export default Background;
