import React, { useContext } from "react";
import styled from "styled-components";
import MainRoomContext from "../../contexts/MainRoomContext";
import QuizContext from "../../contexts/QuizContext";
import TeamRoomContext from "../../contexts/TeamRoomContext";
import { WHITE } from "../../colors";

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: ${WHITE};
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  padding: 20px 30px;
  border-radius: 10px;
`;

const MuteSpeakingMonitor = () => {
  const { inTeamRoom, inMainRoom } = useContext(QuizContext);
  const {
    localSpeaking: localSpeakingMainRoom,
    muted: mutedMainRoom,
  } = useContext(MainRoomContext);
  const {
    localSpeaking: localSpeakingTeamRoom,
    muted: mutedTeamRoom,
  } = useContext(TeamRoomContext);

  // logger.debug("inMainRoom", inMainRoom);
  // logger.debug("inTeamRoom", inTeamRoom);
  // logger.debug("Main room speaking: ", localSpeakingMainRoom);
  // logger.debug("Team room speaking: ", localSpeakingTeamRoom);

  if (
    (inMainRoom && mutedMainRoom && localSpeakingMainRoom) ||
    (inTeamRoom && mutedTeamRoom && localSpeakingTeamRoom)
  ) {
    return <Wrapper>You are muted</Wrapper>;
  }

  return null;
};

export default MuteSpeakingMonitor;
