import React, { useContext } from "react";
import QuizContext from "../../contexts/QuizContext";
import WaitingRoomMainArea from "./WaitingRoomMainArea";
import {
  QUIZ_INTRO,
  NAME_TEAMS,
  ROUND_INTRO,
  QUESTION,
  ANSWERS,
  QUESTION_RESULTS,
  FACTS,
  ROUND_RESULTS,
  QUIZ_RESULTS,
  QUIZ_CONVERSATION,
  MEET_TEAMS,
  TIEBREAKER,
  TIEBREAKER_ANSWERS,
  TIEBREAKER_RESULTS,
} from "../../constants";
import RoundIntroMainArea from "./RoundIntroMainArea";
import QuestionMainArea from "./QuestionMainArea";
import RoundResultsMainArea from "./RoundResultsMainArea";
import QuizResultsMainArea from "./QuizResultsMainArea";
import SurveyMainArea from "./SurveyMainArea";
import { NEW_WAITING_ROOM } from "../../flags";
import NewWaitingRoomMainArea from "./NewWaitingRoomMainArea";
import QuizIntroMainArea from "./QuizIntroMainArea";
import NameTeamsMainArea from "./NameTeamsMainArea";
import QuizConversationMainArea from "./QuizConversationMainArea";
import MeetTeamsMainArea from "./MeetTeamsMainArea";
import AssistingMainArea from "./AssistingMainArea";
import TiebreakerMainArea from "./TiebreakerMainArea";

export default () => {
  const { quiz, myPlayerId, myTeamId, assistingTeamId } = useContext(
    QuizContext
  );

  if (!quiz) {
    return null;
  }

  if (quiz.ended) {
    return <SurveyMainArea quiz={quiz} />;
  }

  if (assistingTeamId) {
    return <AssistingMainArea />;
  }

  if (!quiz.started) {
    if (NEW_WAITING_ROOM) {
      return <NewWaitingRoomMainArea quiz={quiz} />;
    }
    return <WaitingRoomMainArea quiz={quiz} />;
  }

  if (
    quiz.started &&
    myPlayerId &&
    !myTeamId &&
    ![QUIZ_INTRO, NAME_TEAMS].includes(quiz.whatToShow)
  ) {
    return <WaitingRoomMainArea quiz={quiz} />;
  }

  switch (quiz.whatToShow) {
    case QUIZ_INTRO:
      return <QuizIntroMainArea />;

    case NAME_TEAMS:
      return <NameTeamsMainArea />;

    case MEET_TEAMS:
      return <MeetTeamsMainArea />;

    case ROUND_INTRO:
      return <RoundIntroMainArea quiz={quiz} />;

    case QUESTION:
    case ANSWERS:
    case QUESTION_RESULTS:
    case FACTS:
      return <QuestionMainArea quiz={quiz} />;

    // return <AnswerMainArea quiz={quiz} />;
    //
    // return <QuestionResultsMainArea quiz={quiz} />;
    //
    case ROUND_RESULTS:
      return <RoundResultsMainArea quiz={quiz} />;

    case QUIZ_RESULTS:
      return <QuizResultsMainArea quiz={quiz} />;

    case TIEBREAKER:
    case TIEBREAKER_ANSWERS:
    case TIEBREAKER_RESULTS:
      return <TiebreakerMainArea quiz={quiz} />;

    case QUIZ_CONVERSATION:
      return <QuizConversationMainArea />;

    default:
      return null;
  }
};
