import React, { useContext, useState } from "react";
import PageHeading from "../PageHeading";
import styled from "styled-components";
import MainRoomContext from "../../contexts/MainRoomContext";
import QuizContext from "../../contexts/QuizContext";
import _ from "lodash";
import { useDrop } from "react-dnd";
import ItemTypes from "../../util/dndItemTypes";
import { DEBUG } from "../../flags";
import PlayerList from "../PlayerList";
import Button from "../buttons/Button";
import PlayerRecycleBin from "../PlayerRecycleBin";
import CreateTeamModal from "../modals/CreateTeamModal";
import LoadingScreen from "../LoadingScreen";
import { ACCENT_3, LIGHT_GREY_2, MUTED_TEXT } from "../../colors";

const NonTeamPlayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: ${LIGHT_GREY_2};
  padding: 20px;
  border-radius: 10px;
  position: relative;
  ${({ dropHighlight }) =>
    dropHighlight &&
    `
    background-color: ${ACCENT_3};
  `}
`;

const Message = styled.div`
  text-align: center;
  color: ${MUTED_TEXT};
`;

const TeamAndPlayerActions = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
`;

const PlayerRecycleBinWrapper = styled.div`
  margin-left: 5px;
`;

const NameTeamsHost = () => {
  const { participantsWithAudio } = useContext(MainRoomContext);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const { quiz, teams, myHostId, myPlayerId, removeFromTeam } = useContext(
    QuizContext
  );

  const freeAgents = quiz.players.filter((player) => player.team === null);

  const sortedFreeAgents = _.sortBy(freeAgents, [
    (player) => (player.id === myPlayerId ? 0 : 1),
    (player) => (player.name === "" ? "zzzzzzzzzzzz" : player.name),
  ]);

  const [isDropping, setIsDropping] = useState(false);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.PLAYER,
    canDrop: (item) => {
      if (freeAgents.find((player) => player.id === item.id)) {
        return false;
      }
      return true;
    },
    drop: async (item) => {
      setIsDropping(true);
      await removeFromTeam(item.id);
      setIsDropping(false);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const handleCreateTeamClick = () => setShowCreateTeamModal(true);
  const handleCloseCreateTeam = () => setShowCreateTeamModal(false);

  const teamsAreStillNaming = !!teams.find((team) => team.name === null);

  const getHeading = () => {
    if (freeAgents.length > 0) {
      return "Assign Players to Teams";
    } else if (teamsAreStillNaming) {
      return "Waiting for Team Names";
    } else {
      return "Ready to Start";
    }
  };

  return (
    <>
      <PageHeading>{getHeading()}</PageHeading>
      {DEBUG && (
        <div>Participants with audio: {participantsWithAudio.length}</div>
      )}
      <NonTeamPlayersWrapper
        ref={drop}
        dropHighlight={(isOver && canDrop) || isDropping}
        data-cy="non-team-players"
      >
        {!quiz.players.length && <Message>No players have joined yet</Message>}
        {freeAgents.length > 0 && (
          <Message>
            {freeAgents.length} player{freeAgents.length !== 1 && "s"} not on a
            team yet
          </Message>
        )}
        {freeAgents.length === 0 && quiz.players.length > 0 && (
          <Message>All players are on a team</Message>
        )}
        {freeAgents.length > 0 && <PlayerList players={sortedFreeAgents} />}
      </NonTeamPlayersWrapper>
      <TeamAndPlayerActions>
        <Button onClick={handleCreateTeamClick}>Create a New Team</Button>
        {myHostId && (
          <PlayerRecycleBinWrapper>
            <PlayerRecycleBin />
          </PlayerRecycleBinWrapper>
        )}
      </TeamAndPlayerActions>
      {showCreateTeamModal && (
        <CreateTeamModal
          onCreate={handleCloseCreateTeam}
          onCancel={handleCloseCreateTeam}
        />
      )}
      {isDropping && <LoadingScreen />}
    </>
  );
};

export default NameTeamsHost;
