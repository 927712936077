import styled from "styled-components";
import { ELEMENT_OFF_WHITE } from "../../colors";
import { PARAGRAPH_SMALLER } from "../../typography";

const SubInstructionHeading = styled.div`
  color: ${ELEMENT_OFF_WHITE};
  ${PARAGRAPH_SMALLER}
`;

export default SubInstructionHeading;
