import React, { useContext } from "react";
import styled from "styled-components";
import QuizContext from "../../contexts/QuizContext";
import WaitingRoomActions from "./WaitingRoomActions";
import {
  QUIZ_INTRO,
  NAME_TEAMS,
  ROUND_INTRO,
  QUESTION,
  ANSWERS,
  QUESTION_RESULTS,
  FACTS,
  ROUND_RESULTS,
  QUIZ_RESULTS,
  QUIZ_CONVERSATION,
  MEET_TEAMS,
  TIEBREAKER,
  TIEBREAKER_ANSWERS,
  TIEBREAKER_RESULTS,
} from "../../constants";
import RoundIntroActions from "./RoundIntroActions";
import QuestionActions from "./QuestionActions";
import AnswersActions from "./AnswersActions";
import QuestionResultsActions from "./QuestionResultsActions";
import RoundResultsActions from "./RoundResultsActions";
import QuizResultsActions from "./QuizResultsActions";
import QuizIntroActions from "./QuizIntroActions";
import NameTeamsActions from "./NameTeamsActions";
import QuizConversationActions from "./QuizConversationActions";
import MeetTeamsActions from "./MeetTeamsActions";
import AssistingActions from "./AssistingActions";
import TiebreakerActions from "./TiebreakerActions";
import TiebreakerAnswersActions from "./TiebreakerAnswersActions";
import TiebreakerResultsActions from "./TiebreakerResultsActions";
import { LIGHT_GREY_2 } from "../../colors";
import SurveyActions from "./SurveyActions";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${LIGHT_GREY_2};
  height: 100px;

  button {
    margin: 0 10px;
  }
`;

export default () => {
  const { quiz, myHost, myPlayerId, myHostId, canResetQuiz } = useContext(
    QuizContext
  );

  if (!quiz) {
    return null;
  }

  const renderHostActions = () => {
    if (!quiz.started) {
      return <WaitingRoomActions />;
    }

    if (quiz.ended && canResetQuiz) {
      return <SurveyActions />;
    }

    if (myHost.assisting) {
      return <AssistingActions />;
    }

    switch (quiz.whatToShow) {
      case QUIZ_INTRO:
        return <QuizIntroActions />;

      case NAME_TEAMS:
        return <NameTeamsActions />;

      case MEET_TEAMS:
        return <MeetTeamsActions />;

      case ROUND_INTRO:
        return <RoundIntroActions />;

      case QUESTION:
        return <QuestionActions />;

      case ANSWERS:
        return <AnswersActions />;

      case QUESTION_RESULTS:
      case FACTS:
        return <QuestionResultsActions />;

      case ROUND_RESULTS:
        return <RoundResultsActions />;

      case QUIZ_RESULTS:
        return <QuizResultsActions />;

      case TIEBREAKER:
        return <TiebreakerActions />;

      case TIEBREAKER_ANSWERS:
        return <TiebreakerAnswersActions />;

      case TIEBREAKER_RESULTS:
        return <TiebreakerResultsActions />;

      case QUIZ_CONVERSATION:
        return <QuizConversationActions />;
    }
  };

  if (myPlayerId) {
    return null;
  }

  return <Wrapper>{myHostId && renderHostActions()}</Wrapper>;
};
