import React, { useContext, useEffect, useState } from "react";
import WaitingRoomScreen from "../../../screens/quiz/01/WaitingRoomScreen";
import QuizContext from "../../../../contexts/QuizContext";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import InviteModal from "../../../modals/InviteModal";
import useStartQuizMutation from "../../../hooks/mutations/useStartQuizMutation";
import getAllAudio from "../AudioController/getAllAudio";
import RemovePlayerModal from "../../../modals/RemovePlayerModal";
import useRemovePlayerMutation from "../../../hooks/mutations/useRemovePlayerMutation";
import GalleryPageController, {
  useGalleryPageCount,
  useGalleryParticipantCount,
} from "../GalleryPageController";

const WaitingRoomController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const { quizName, publicQuizId } = useContext(QuizContext);
  const pageCount = useGalleryPageCount();
  const [pageNum, setPageNum] = useState(1);
  const [showRemovePlayerModal, setShowRemovePlayerModal] = useState();
  const [removePlayerMutation] = useRemovePlayerMutation();
  const [showInviteModal, setShowInviteModal] = useState();
  const [startQuizMutation] = useStartQuizMutation();

  const isHost = Boolean(myHostId);

  const count = useGalleryParticipantCount();

  const handleStartQuiz = () => {
    startQuizMutation({
      variables: {
        newJoinExperience: true,
      },
    });
  };

  const handleRemovePlayer = async () => {
    await removePlayerMutation({
      variables: {
        playerId: showRemovePlayerModal.id,
      },
    });

    setShowRemovePlayerModal(false);
  };

  useEffect(() => {
    if (pageNum > pageCount) {
      setPageNum(pageCount);
    }
  }, [pageNum, pageCount]);

  return (
    <>
      <WaitingRoomScreen
        audio={getAllAudio()}
        isHost={isHost}
        quizName={quizName}
        count={count}
        galleryPage={<GalleryPageController pageNum={pageNum} />}
        pageNum={pageNum}
        pageCount={pageCount}
        onPrevPage={() => setPageNum((prevPageNum) => prevPageNum - 1)}
        onNextPage={() => setPageNum((prevPageNum) => prevPageNum + 1)}
        onSetPage={(newPageNum) => setPageNum(newPageNum)}
        onStartQuiz={handleStartQuiz}
        onShowInviteModal={() => setShowInviteModal(true)}
        resetButton={resetButton}
        navigation={<QuizNavigationController />}
        chat={<QuizChatController />}
        modal={
          <>
            {showRemovePlayerModal && (
              <RemovePlayerModal
                playerName={showRemovePlayerModal.name}
                onRemove={handleRemovePlayer}
                onCancel={() => setShowRemovePlayerModal(false)}
              />
            )}
            {showInviteModal && (
              <InviteModal
                publicQuizId={publicQuizId}
                onClose={() => setShowInviteModal(false)}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default WaitingRoomController;
