import React from "react";
import styled from "styled-components";
import GradientCard from "../elements/cards/GradientCard";
import { PARAGRAPH_LARGE_BOLD } from "../../typography";
import { WHITE } from "../../colors";
import AnswerRow from "../teams/AnswerRow";

const Wrapper = styled(GradientCard)``;

const QuestionText = styled.div`
  ${PARAGRAPH_LARGE_BOLD}
  color: ${WHITE};
  text-align: center;
  padding: 20px 30px;
`;

const CompactQuestion = ({ text, answerText, answerCorrect }) => {
  return (
    <Wrapper data-cy={"CompactQuestion"}>
      <QuestionText>{text}</QuestionText>
      {answerText && (
        <AnswerRow text={answerText} correct={answerCorrect} graded />
      )}
    </Wrapper>
  );
};

export default CompactQuestion;
