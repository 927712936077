import React, { useContext, useState } from "react";
import TeamBreakout from "../../../components/teams/TeamBreakout";
import teamPlayerSortCompare from "../../../util/teamPlayerSortCompare";
import AvatarHostControls from "../../../components/avatars/AvatarHostControls";
import AvatarController from "../AvatarControllers/AvatarController";
import LargeMainButton from "../../../components/buttons/LargeMainButton";
import LargeDangerButton from "../../../components/buttons/LargeDangerButton";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import useMakeTeamLeaderMutation from "../../../hooks/mutations/useMakeTeamLeaderMutation";
import useRemovePlayerMutation from "../../../hooks/mutations/useRemovePlayerMutation";
import RemovePlayerModal from "../../../modals/RemovePlayerModal";
import QuizContext from "../../../../contexts/QuizContext";

const TeamBreakoutController = () => {
  const { myHostId } = useContext(SessionDataContext);
  const { myTeam, assistingTeam } = useContext(QuizContext);
  const [makeTeamLeaderMutation] = useMakeTeamLeaderMutation();
  const [showRemovePlayerModal, setShowRemovePlayerModal] = useState();
  const [removePlayerMutation] = useRemovePlayerMutation();

  const handleMakeTeamLeader = (playerId) => async () => {
    await makeTeamLeaderMutation({
      variables: {
        playerId,
      },
    });
  };

  const handleRemovePlayer = async () => {
    await removePlayerMutation({
      variables: {
        playerId: showRemovePlayerModal.id,
      },
    });

    setShowRemovePlayerModal(false);
  };

  const isHost = Boolean(myHostId);

  const team = myTeam || assistingTeam;

  if (!team) {
    return null;
  }

  return (
    <>
      <TeamBreakout
        emoji={team.emoji}
        teamName={team.name}
        avatars={team.players.sort(teamPlayerSortCompare).map((player) => (
          <AvatarHostControls
            key={player.id}
            avatar={<AvatarController player={player} />}
            controls={[
              ...(!player.leader
                ? [
                    <LargeMainButton
                      key={"make-leader"}
                      onClick={handleMakeTeamLeader(player.id)}
                    >
                      Make Leader
                    </LargeMainButton>,
                  ]
                : []),
              <LargeDangerButton
                key={"remove"}
                noSpinner
                onClick={() =>
                  setShowRemovePlayerModal({
                    id: player.id,
                    name: player.name,
                  })
                }
              >
                Remove
              </LargeDangerButton>,
            ]}
            disabled={!isHost}
          />
        ))}
      />
      {showRemovePlayerModal && (
        <RemovePlayerModal
          playerName={showRemovePlayerModal.name}
          onRemove={handleRemovePlayer}
          onCancel={() => setShowRemovePlayerModal(false)}
        />
      )}
    </>
  );
};

export default TeamBreakoutController;
