import React from "react";
import styled from "styled-components";
import PlayerList from "../../../../PlayerList";
import RecycleBin from "../../../../PlayerRecycleBin";

const Wrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const TeamPlayersRow = ({ team, editing }) => {
  return (
    <Wrapper>
      <PlayerList players={team.players} />
      {editing && <RecycleBin />}
    </Wrapper>
  );
};

export default TeamPlayersRow;
