import React from "react";

const LoadingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="6"
    viewBox="0 0 32 6"
  >
    <circle fill="#fff" stroke="none" cx="3" cy="3" r="3">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.1"
      />
    </circle>
    <circle fill="#fff" stroke="none" cx="16" cy="3" r="3">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.2"
      />
    </circle>
    <circle fill="#fff" stroke="none" cx="29" cy="3" r="3">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.3"
      />
    </circle>
  </svg>
);

export default LoadingIcon;
