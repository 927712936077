import React, { useContext } from "react";
import styled from "styled-components";
import {
  FACTS,
  QUESTION,
  QUIZ_INTRO,
  QUIZ_RESULTS,
  ROUND_INTRO,
  ROUND_RESULTS,
  TIEBREAKER,
  TIEBREAKER_ANSWERS,
  TIEBREAKER_RESULTS,
} from "../../constants";
import _ from "lodash";
import QuizContext from "../../contexts/QuizContext";
import Team from "./Team";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const TeamListNew = () => {
  const {
    quiz,
    myHostId,
    teams,
    whatToShow,
    myTeamAnswer,
    isNonTeamPlayer,
    getTeamRound,
    myTeamInFirstPlace,
    myTeamTiebreaker,
    teamsInFirstPlace,
  } = useContext(QuizContext);

  const getSortedTeams = () => {
    let result = teams;

    if (whatToShow === ROUND_RESULTS) {
      result = _.sortBy(teams, [
        (team) => {
          const teamRound = getTeamRound(team);
          const score = teamRound.score;
          return -score;
          // const score = _.get(team, ["teamRounds", round.number - 1, "score"]);
          // return -score;
        },
      ]);
    }

    if (whatToShow === QUIZ_RESULTS) {
      result = _.sortBy(teams, [(team) => -team.score]);
    }

    if (
      [TIEBREAKER, TIEBREAKER_ANSWERS, TIEBREAKER_RESULTS].includes(whatToShow)
    ) {
      return teamsInFirstPlace;
    }

    return result;
  };

  let hideAll = false;
  if ([QUIZ_INTRO, ROUND_INTRO, FACTS].includes(whatToShow)) {
    hideAll = true;
  }
  if (quiz.whatToShow === QUESTION && !myTeamAnswer && !myHostId) {
    hideAll = true;
  }
  if (
    quiz.whatToShow === TIEBREAKER &&
    myTeamInFirstPlace &&
    !myTeamTiebreaker
  ) {
    hideAll = true;
  }
  if (isNonTeamPlayer) {
    hideAll = false;
  }
  if (hideAll) {
    return null;
  }

  return (
    <Wrapper data-cy="team-list">
      {getSortedTeams().map((team) => (
        <Team key={team.id} team={team} />
      ))}
    </Wrapper>
  );
};

export default TeamListNew;
