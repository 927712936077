import React, { useContext } from "react";
import Chat from "../../../components/chat/Chat";
import QuizChatContext from "../../../../contexts/QuizChatContext";
import ChatMessage from "../../../components/chat/ChatMessage";
import ChatInput from "../../../components/chat/ChatInput";
import EmojiPicker from "../../../components/elements/emojis/EmojiPicker";
import insertTextAtPoint from "../../../util/insertTextAtPoint";
import SelfieController from "../SelfieController";
import SessionDataContext from "../../../../contexts/SessionDataContext";

const QuizChatController = () => {
  const { myHostId } = useContext(SessionDataContext);
  const {
    messages,
    sendChatMessage,
    refetchedAt,
    chatInputValue,
    setChatInputValue,
    showEmojiPicker,
    setShowEmojiPicker,
    selectionStart,
    setSelectionStart,
    sending,
  } = useContext(QuizChatContext);

  const handleSelectEmoji = (emoji) => {
    setChatInputValue((prevInputValue) =>
      insertTextAtPoint(prevInputValue, emoji.native, selectionStart)
    );
    setShowEmojiPicker(false);
  };

  const getIsHost = (message) => Boolean(message.fromHost);

  const getParticipant = (message) => {
    const host = message.fromHost;
    const player = message.fromPlayer;
    return host || player;
  };

  const getName = (message) => {
    const participant = getParticipant(message);
    return participant && participant.name;
  };

  const getTeam = (message) => message.fromPlayer && message.fromPlayer.team;

  const getTeamName = (message) => {
    const team = getTeam(message);
    return team && team.name;
  };

  const getEmoji = (message) => {
    const team = getTeam(message);
    return team && team.emoji;
  };

  return (
    <Chat
      chatMessages={
        messages &&
        messages.map((message) => (
          <ChatMessage
            key={message.id}
            host={getIsHost(message)}
            name={getName(message)}
            teamName={getTeamName(message)}
            emoji={getEmoji(message)}
            selfie={
              <SelfieController id={getParticipant(message).id} type={"chat"} />
            }
            text={message.body}
          />
        ))
      }
      refetchedAt={refetchedAt}
      chatInput={
        <ChatInput
          value={chatInputValue}
          onEmojiPickerClick={() => setShowEmojiPicker(true)}
          emojiPickerOpen={showEmojiPicker}
          onChange={setChatInputValue}
          onSend={sendChatMessage}
          onSelectionStartChange={setSelectionStart}
          sending={sending}
        />
      }
      emojiPicker={
        showEmojiPicker && (
          <EmojiPicker
            onSelect={handleSelectEmoji}
            onClose={() => setShowEmojiPicker(false)}
          />
        )
      }
      isHost={Boolean(myHostId)}
    />
  );
};

export default QuizChatController;
