import { useEffect, useState } from "react";
import getLogger from "../../../util/getLogger";

const logger = getLogger("useTrackPublications");

const useTrackPublications = (participant) => {
  const [trackPublications, setTrackPublications] = useState([]);

  useEffect(() => {
    if (participant) {
      setTrackPublications(Array.from(participant.tracks.values()));

      const trackPublicationAdded = (newTrackPublication) => {
        logger.debug("trackPublicationAdded", newTrackPublication);
        setTrackPublications((prevTrackPublications) => [
          ...prevTrackPublications,
          newTrackPublication,
        ]);
      };

      const trackPublicationRemoved = (oldTrackPublication) => {
        logger.debug("trackPublicationRemoved", oldTrackPublication);
        setTrackPublications((prevTrackPublications) =>
          prevTrackPublications.filter((pub) => pub !== oldTrackPublication)
        );
      };

      participant.on("trackPublished", trackPublicationAdded);
      participant.on("trackUnpublished", trackPublicationRemoved);

      return () => {
        participant.off("trackPublished", trackPublicationAdded);
        participant.off("trackUnpublished", trackPublicationRemoved);
      };
    }
  }, [participant]);

  return trackPublications;
};

export default useTrackPublications;
