import React, { useContext, useState } from "react";
import styled from "styled-components";
import PageHeading from "../PageHeading";
import Button from "../buttons/Button";
import CreateTeamModal from "../modals/CreateTeamModal";
import { useDrop } from "react-dnd";
import ItemTypes from "../../util/dndItemTypes";
import QuizContext from "../../contexts/QuizContext";
import LoadingScreen from "../LoadingScreen";
import PlayerList from "../PlayerList";
import _ from "lodash";
import PlayerRecycleBin from "../PlayerRecycleBin";
import MainRoomContext from "../../contexts/MainRoomContext";
import { DEBUG } from "../../flags";
import { ACCENT_3, LIGHT_GREY_2, MUTED_TEXT } from "../../colors";

const NonTeamPlayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: ${LIGHT_GREY_2};
  padding: 20px;
  border-radius: 10px;
  position: relative;
  ${({ dropHighlight }) =>
    dropHighlight &&
    `
    background-color: ${ACCENT_3};
  `}
`;

const Message = styled.div`
  text-align: center;
  color: ${MUTED_TEXT};
`;

const TeamAndPlayerActions = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
`;

const PlayerRecycleBinWrapper = styled.div`
  margin-left: 5px;
`;

export default ({ quiz }) => {
  const { participantsWithAudio } = useContext(MainRoomContext);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const { myHostId, myPlayerId, myTeamId, removeFromTeam } = useContext(
    QuizContext
  );

  const freeAgents = quiz.players.filter((player) => player.team === null);

  const sortedFreeAgents = _.sortBy(freeAgents, [
    (player) => (player.id === myPlayerId ? 0 : 1),
    (player) => (player.name === "" ? "zzzzzzzzzzzz" : player.name),
  ]);

  const [isDropping, setIsDropping] = useState(false);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.PLAYER,
    canDrop: (item) => {
      if (freeAgents.find((player) => player.id === item.id)) {
        return false;
      }
      return true;
    },
    drop: async (item) => {
      setIsDropping(true);
      await removeFromTeam(item.id);
      setIsDropping(false);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const handleCreateTeamClick = () => setShowCreateTeamModal(true);
  const handleCloseCreateTeam = () => setShowCreateTeamModal(false);

  const getHeading = () => {
    if (myHostId) {
      return "Waiting for Players";
    }
    if (myTeamId) {
      return "Choose Players";
    }
    if (quiz.started && !myTeamId) {
      return "Game in Progress - Join a Team";
    }
    return "Join or Create a Team";
  };

  const playerJoiningLate = !myHostId && quiz.started && !myTeamId;

  return (
    <>
      <PageHeading>{getHeading()}</PageHeading>
      {DEBUG && (
        <div>Participants with audio: {participantsWithAudio.length}</div>
      )}

      {!playerJoiningLate && (
        <NonTeamPlayersWrapper
          ref={drop}
          dropHighlight={(isOver && canDrop) || isDropping}
          data-cy="non-team-players"
        >
          {!quiz.players.length && (
            <Message>No players have joined yet</Message>
          )}
          {freeAgents.length > 0 && (
            <Message>
              {freeAgents.length} player{freeAgents.length !== 1 && "s"} not on
              a team yet
            </Message>
          )}
          {freeAgents.length === 0 && quiz.players.length > 0 && (
            <Message>All players are on a team</Message>
          )}
          {freeAgents.length > 0 && <PlayerList players={sortedFreeAgents} />}
        </NonTeamPlayersWrapper>
      )}
      {!myTeamId && !quiz.started && (
        <TeamAndPlayerActions>
          <Button onClick={handleCreateTeamClick}>Create a New Team</Button>
          {myHostId && (
            <PlayerRecycleBinWrapper>
              <PlayerRecycleBin />
            </PlayerRecycleBinWrapper>
          )}
        </TeamAndPlayerActions>
      )}
      {showCreateTeamModal && (
        <CreateTeamModal
          onCreate={handleCloseCreateTeam}
          onCancel={handleCloseCreateTeam}
        />
      )}
      {isDropping && <LoadingScreen />}
    </>
  );
};
