import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 20px;
  padding: 0 15px 0 10px;
  ${(props) =>
    props.hasInstructions &&
    `
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "intro intro" "instructions hosts";
  `}
`;

const IntroWrapper = styled.div`
  ${(props) =>
    props.hasInstructions &&
    `
    grid-area: intro;
    `}
`;

const InstructionWrapper = styled.div`
  grid-area: instructions;
  height: 100%;
  min-height: 0;
`;

const HostsWrapper = styled.div`
  height: 100%;
  ${(props) =>
    props.hasInstructions &&
    `
    grid-area: hosts;
  `}
`;

const IntroLayout = ({ intro, instructions, hosts }) => {
  const hasInstructions = Boolean(instructions);

  return (
    <Wrapper hasInstructions={hasInstructions}>
      <IntroWrapper hasInstructions={hasInstructions}>{intro}</IntroWrapper>
      {instructions && <InstructionWrapper>{instructions}</InstructionWrapper>}
      <HostsWrapper hasInstructions={hasInstructions}>{hosts}</HostsWrapper>
    </Wrapper>
  );
};

export default IntroLayout;
