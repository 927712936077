import React, { useState } from "react";
import RecycleBinIcon from "../icons/RecycleBinIcon";
import { useDrop } from "react-dnd";
import ItemTypes from "../../util/dndItemTypes";
import styled from "styled-components";
import RemovePlayerModal from "../modals/RemovePlayerModal";
import { ACCENT_3, REGULAR_TEXT } from "../../colors";

const Wrapper = styled.div`
  padding: 20px;
  color: ${REGULAR_TEXT};
  border-radius: 5px;
  ${({ dropHighlight }) =>
    dropHighlight &&
    `
    background-color: ${ACCENT_3};
  `}
`;

const RecycleBin = () => {
  const [showModal, setShowModal] = useState(false);
  const [playerToRemove, setPlayerToRemove] = useState(null);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.PLAYER,
    canDrop: () => {
      return true;
    },
    drop: async (item) => {
      setPlayerToRemove(item);
      setShowModal(true);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const handleModalClose = () => {
    setShowModal(false);
    setPlayerToRemove(null);
  };

  return (
    <Wrapper
      dropHighlight={isOver && canDrop}
      ref={drop}
      data-cy="player-recycle-bin"
    >
      <RecycleBinIcon />
      {showModal && (
        <RemovePlayerModal
          playerId={playerToRemove.id}
          name={playerToRemove.name}
          onClose={handleModalClose}
        />
      )}
    </Wrapper>
  );
};

export default RecycleBin;
