import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import RoundIntro from "../../../components/intros/RoundIntro";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import IntroLayout from "../../../components/layouts/IntroLayout";
import HostsOnIntroScreens from "../../../components/hosts/HostsOnIntroScreens";
import GameInstructions from "../../../components/instructions/02/GameInstructions";

const RoundIntroScreen = ({
  audio,
  isHost,
  hostAvatars,
  roundNumber,
  roundTitle,
  onStartRound,
  resetButton,
  navigation,
  chat,
  modal,
}) => {
  const displayInstructions = isHost && roundNumber === 1;

  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        navigation={navigation}
        chat={chat}
        payload={
          <IntroLayout
            intro={<RoundIntro number={roundNumber} title={roundTitle} />}
            instructions={displayInstructions && <GameInstructions />}
            hosts={<HostsOnIntroScreens hostAvatars={hostAvatars} />}
          />
        }
        actionBar={
          isHost && (
            <ActionBar>
              <LargeDarkButton onClick={onStartRound}>
                Start Round
              </LargeDarkButton>
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default RoundIntroScreen;
