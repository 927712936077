import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation MeetTeams {
      meetTeams {
        ok
      }
    }
  `);
