import React, { useState } from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import Leaderboard from "../../../components/leaderboard/Leaderboard";
import ScreenTitle from "../../../components/titles/ScreenTitle";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import LargeDangerButton from "../../../components/buttons/LargeDangerButton";
import Confetti from "../../../components/celebrations/Confetti";
import GridVerticalScrollLayout from "../../../components/layouts/GridVerticalScrollLayout";

const QuizResultsScreen = ({
  audio,
  isHost,
  hostAvatars,
  quizName,
  teams,
  next,
  winningTeamEmojis,
  roundNumber,
  onNextRound,
  onShowSkipRemainingRoundsModal,
  onQuizConversation,
  onTiebreaker,
  resetButton,
  navigation,
  chat,
  modal,
}) => {
  const [celebrationComplete, setCelebrationComplete] = useState(false);

  let location = "Final Scores";
  if (next === "round") {
    location = `Total Scores after Round ${roundNumber}`;
  } else if (next === "tiebreaker") {
    location = "Tie for First Place!";
  }

  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        hostAvatars={hostAvatars}
        navigation={navigation}
        chat={chat}
        payload={
          <GridVerticalScrollLayout
            header={<ScreenTitle quizName={quizName} location={location} />}
            content={<Leaderboard teams={teams} />}
          />
        }
        actionBar={
          isHost && (
            <ActionBar>
              {next === "round" && (
                <>
                  <LargeDarkButton onClick={onNextRound}>
                    Next Round
                  </LargeDarkButton>
                  <LargeDangerButton
                    noSpinner
                    onClick={onShowSkipRemainingRoundsModal}
                  >
                    Skip Remaining Rounds
                  </LargeDangerButton>
                </>
              )}
              {next === "quiz-conversation" && (
                <LargeDarkButton
                  onClick={onQuizConversation}
                  disabled={!celebrationComplete}
                >
                  Quiz Conversation
                </LargeDarkButton>
              )}
              {next === "tiebreaker" && (
                <LargeDarkButton onClick={onTiebreaker}>
                  Tiebreaker
                </LargeDarkButton>
              )}
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
      {next === "quiz-conversation" && winningTeamEmojis && (
        <Confetti
          emojis={winningTeamEmojis}
          onComplete={() => setCelebrationComplete(true)}
        />
      )}
    </>
  );
};

export default QuizResultsScreen;
