import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation TeamAnswerTiebreaker($answerText: String!, $answerValue: BigInt) {
      teamAnswerTiebreaker(answerText: $answerText, answerValue: $answerValue) {
        team {
          id
        }
      }
    }
  `);
