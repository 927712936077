import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import getLogger from "../util/getLogger";

const Wrapper = styled.div``;

const getInitialMutedSetting = () => {
  if (typeof window !== "undefined") {
    if (window.location.href.startsWith("http://localhost")) {
      return true;
    }
  }
  return false;
};

const AudioParticipant = ({ participant, mute }) => {
  const logger = getLogger(
    `AudioParticipant: ${participant ? participant.identity : null}`
  );

  const [audioTracks, setAudioTracks] = useState([]);

  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) => {
    logger.debug("in trackpubsToTracks");
    logger.debug(trackMap);
    const result1 = Array.from(trackMap.values());
    logger.debug(result1);
    const result2 = result1.map((publication) => publication.track);
    logger.debug(result2);
    const result3 = result2.filter((track) => track !== null);
    logger.debug(result3);
    return result3;
  };

  useEffect(() => {
    logger.debug("mounted");
    return () => logger.debug("unmounting");
  }, []);

  useEffect(() => {
    if (!participant) {
      logger.debug("Participant is not set");
      return;
    }

    logger.debug("Participant changed", participant);

    const trackSubscribed = (track) => {
      logger.info(`Received trackSubscribed event for track ${track}`);
      logger.debug(track);
      if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
        logger.info("Added audio track", track);
      }
    };

    const trackUnsubscribed = (track) => {
      logger.info(`Received trackUnsubscribed event for track ${track}`);
      logger.debug(track);
      if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
        logger.info("Removed audio track", track);
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    logger.debug("Added all participant event listeners");
    logger.debug(participant);

    const parsedAudioTracks = trackpubsToTracks(participant.audioTracks);
    setAudioTracks(parsedAudioTracks);
    logger.info(`Set initial audio tracks: ${parsedAudioTracks.length}`);
    logger.debug(parsedAudioTracks);

    return () => {
      setAudioTracks([]);
      participant.removeAllListeners();
      logger.info("Removed all listeners");
    };
  }, [participant]);

  useEffect(() => {
    logger.debug("Audio tracks have changed", audioTracks);

    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      logger.info("Attached audio track to html element");
      return () => {
        audioTrack.detach(audioRef.current);
        logger.info("Detached audio track from html element");
      };
    } else {
      return () => logger.debug("No audio tracks to detach");
    }
  }, [audioTracks]);

  return (
    <Wrapper>
      <audio
        ref={audioRef}
        autoPlay={true}
        muted={mute || getInitialMutedSetting()}
      />
    </Wrapper>
  );
};

export default AudioParticipant;
