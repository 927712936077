import React, { useEffect, useContext } from "react";
import { MainRoomContextProvider } from "../../contexts/MainRoomContext";
import { TeamRoomContextProvider } from "../../contexts/TeamRoomContext";
import getLogger from "../../util/getLogger";
import { navigate } from "gatsby";
import QuizContext from "../../contexts/QuizContext";
import QuizLayout from "../../components/QuizLayout";
import MainRoomAudio from "../MainRoomAudio";
import { ServerTimeContextProvider } from "../../contexts/ServerTimeContext";
import Bugsnag from "@bugsnag/js";
import { EVENT_RESULTS } from "../../constants";

const logger = getLogger("Quiz");

export default function Quiz() {
  const {
    quiz,
    myHost,
    myHostId,
    myTeamId,
    ended,
    error,
    videoToken,
    eventWhatToShow,
    quizWasReset,
  } = useContext(QuizContext);

  useEffect(() => {
    logger.debug("Error changed");
    logger.debug(error);
    if (error) {
      logger.error(`Error detected: ${error.message}`);
      Bugsnag.notify(error);
      if (
        error.message === "Unknown player" ||
        error.message.startsWith("Invalid quiz") ||
        error.message === "Unknown host" ||
        error.message === "Not in a quiz"
      ) {
        logger.error("As a result of the error, sending user to home page");
        navigate("/");
      }
    }
  }, [error]);

  useEffect(() => {
    if (ended) {
      navigate("/survey/");
    }
  }, [ended]);

  useEffect(() => {
    if (eventWhatToShow === EVENT_RESULTS) {
      navigate("/event/");
    }
  }, [eventWhatToShow]);

  useEffect(() => {
    if (quizWasReset) {
      navigate("/");
    }
  }, [quizWasReset]);

  const roomName = quiz && quiz.id;

  const assistingTeamId =
    quiz && myHostId && myHost && myHost.assisting && myHost.assisting.id;

  const teamRoomName = myTeamId || assistingTeamId;

  if (!(roomName && videoToken)) {
    return null;
  }

  if (ended) {
    return null;
  }

  return (
    <ServerTimeContextProvider>
      <MainRoomContextProvider roomName={roomName} token={videoToken}>
        <TeamRoomContextProvider roomName={teamRoomName} token={videoToken}>
          <QuizLayout />
        </TeamRoomContextProvider>
        <MainRoomAudio />
      </MainRoomContextProvider>
    </ServerTimeContextProvider>
  );
}
