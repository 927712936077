import React from "react";
import { ELEMENT_DEFAULT } from "../../colors";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ControlsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${ELEMENT_DEFAULT};
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  ${Wrapper}:hover &, 
  ${Wrapper}.hover & {
    display: flex;
    cursor: pointer;
  }
`;

const ControlWrapper = styled.div`
  margin: 10px 0;
`;

const AvatarHostControls = ({
  avatar,
  controls,
  disabled,
  className,
  ...rest
}) => {
  return (
    <Wrapper className={className} {...rest}>
      {avatar}
      {!disabled && (
        <ControlsWrapper>
          {controls &&
            controls.map((control) => (
              <ControlWrapper key={control.key}>{control}</ControlWrapper>
            ))}
        </ControlsWrapper>
      )}
    </Wrapper>
  );
};

export default AvatarHostControls;
