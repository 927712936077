import { useContext, useEffect } from "react";
import QuizContext from "../../../contexts/QuizContext";

const useQuizWasResetEffect = (handler) => {
  const { quizWasReset } = useContext(QuizContext);

  useEffect(() => {
    if (quizWasReset) {
      handler();
    }
  }, [quizWasReset]);
};

export default useQuizWasResetEffect;
