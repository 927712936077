import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation ShowRoundScores {
      showRoundScores {
        quiz {
          id
          whatToShow
        }
      }
    }
  `);
