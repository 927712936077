import React, { useContext, useState } from "react";
import styled from "styled-components";
import QuizContext from "../../contexts/QuizContext";
import ReactTooltip from "react-tooltip";
import LinkButton from "../buttons/LinkButton";
import AllPlayersModal from "../modals/AllPlayersModal";
import { LIGHT_MUTED_TEXT } from "../../colors";

const MAX_PLAYERS_TO_LIST = 10;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

const PlayerName = styled.div`
  font-size: 18px;
  margin: 5px 0;
`;

const TextWrapper = styled.div`
  color: ${LIGHT_MUTED_TEXT};
`;

const AdditionalPlayers = ({ nonVisiblePlayers }) => {
  const { players } = useContext(QuizContext);
  const [showAllPlayersModal, setShowAllPlayersModal] = useState(false);

  const handleShowAllPlayersClick = () => setShowAllPlayersModal(true);
  const handleCloseAllPlayers = () => setShowAllPlayersModal(false);

  let playersToList;
  if (nonVisiblePlayers.length <= MAX_PLAYERS_TO_LIST) {
    playersToList = nonVisiblePlayers;
  } else {
    playersToList = nonVisiblePlayers.slice(0, MAX_PLAYERS_TO_LIST);
    const numRestOfPlayers = nonVisiblePlayers.length - MAX_PLAYERS_TO_LIST;
    playersToList = [
      ...playersToList,
      {
        id: "evenMorePlayers",
        name: `+ ${numRestOfPlayers} more`,
      },
    ];
  }

  const renderText = () => {
    const nvpLength = nonVisiblePlayers.length;

    if (nvpLength === players.length) {
      if (nvpLength === 0) {
        return "No players have joined";
      }
      if (nvpLength === 1) {
        return "1 player has joined";
      }
      return `${nvpLength} players have joined`;
    }

    if (nvpLength === 0) {
      return "Show all";
    }

    return `+ ${nvpLength} more`;
  };

  const noLink =
    nonVisiblePlayers.length === players.length &&
    nonVisiblePlayers.length === 0;

  return (
    <>
      <Wrapper data-tip data-for="additionalPlayersToolTip">
        {noLink ? (
          <TextWrapper>{renderText()}</TextWrapper>
        ) : (
          <LinkButton onClick={handleShowAllPlayersClick}>
            <TextWrapper>{renderText()}</TextWrapper>
          </LinkButton>
        )}
      </Wrapper>
      {nonVisiblePlayers.length > 0 && (
        <ReactTooltip
          id="additionalPlayersToolTip"
          effect="solid"
          place="right"
          delayShow={500}
        >
          {playersToList.map((player) => (
            <PlayerName key={player.id}>{player.name}</PlayerName>
          ))}
        </ReactTooltip>
      )}
      {showAllPlayersModal && (
        <AllPlayersModal onClose={handleCloseAllPlayers} />
      )}
    </>
  );
};

export default AdditionalPlayers;
