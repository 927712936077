import React from "react";
import styled from "styled-components";
import { ELEMENT_DARKER, WHITE } from "../../colors";
import { TITLE_HEADER } from "../../typography";

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: ${WHITE};
  background-color: ${ELEMENT_DARKER};
  ${TITLE_HEADER}
  padding: 20px 30px;
  border-radius: 10px;
`;

const SpeakingWhileMutedWarning = () => {
  return <Wrapper>You are muted</Wrapper>;
};

export default SpeakingWhileMutedWarning;
