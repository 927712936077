import React, { useContext, useState } from "react";
import QuizContext from "../../../../contexts/QuizContext";
import hostSortCompare from "../../../util/hostSortCompare";
import AvatarController from "../AvatarControllers/AvatarController";
import AvatarHostControls from "../../../components/avatars/AvatarHostControls";
import LargeDangerButton from "../../../components/buttons/LargeDangerButton";
import RemovePlayerModal from "../../../modals/RemovePlayerModal";
import useRemovePlayerMutation from "../../../hooks/mutations/useRemovePlayerMutation";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import GalleryLayout from "../../../components/layouts/GalleryLayout";
import FeatureFlagContext from "../../../../contexts/FeatureFlagContext";
import MainRoomContext from "../../../contexts/MainRoomContext";
import inTesting from "../../../../util/inTesting";

const DEFAULT_PAGE_SIZE = 16;

export const useGalleryParticipantCount = () => {
  const { participants, participantsWithVideo } = useContext(MainRoomContext);

  const { hosts, players } = useContext(QuizContext);

  const numHosts = hosts
    ? hosts.filter((host) => participantsWithVideo[host.id] || inTesting())
        .length
    : 0;

  const numPlayers = players
    ? players.filter((player) => participants[player.id] || inTesting()).length
    : 0;

  return numHosts + numPlayers;
};

export const useGalleryPageCount = () => {
  const { flags } = useContext(FeatureFlagContext);
  const numParticipants = useGalleryParticipantCount();

  const pageSize = flags["max-video-gallery-size"] || DEFAULT_PAGE_SIZE;

  return Math.max(Math.ceil(numParticipants / pageSize), 1);
};

const GalleryPageController = ({ pageNum }) => {
  const { flags } = useContext(FeatureFlagContext);
  const pageSize = flags["max-video-gallery-size"] || DEFAULT_PAGE_SIZE;

  const { myHostId } = useContext(SessionDataContext);

  const zeroBasedPageNum = pageNum - 1;
  const { hosts, players } = useContext(QuizContext);
  const { participants, participantsWithVideo } = useContext(MainRoomContext);
  const [showRemovePlayerModal, setShowRemovePlayerModal] = useState();
  const [removePlayerMutation] = useRemovePlayerMutation();

  const isHost = Boolean(myHostId);

  const handleRemovePlayer = async () => {
    await removePlayerMutation({
      variables: {
        playerId: showRemovePlayerModal.id,
      },
    });

    setShowRemovePlayerModal(false);
  };

  const avatars = [
    ...hosts
      .filter((host) => participantsWithVideo[host.id] || inTesting())
      .sort(hostSortCompare)
      .map((host) => <AvatarController key={host.id} host={host} />),
    ...players
      .filter((player) => participants[player.id] || inTesting())
      .map((player) => (
        <AvatarHostControls
          key={player.id}
          avatar={<AvatarController player={player} />}
          controls={[
            <LargeDangerButton
              key={"remove"}
              noSpinner
              onClick={() =>
                setShowRemovePlayerModal({
                  id: player.id,
                  name: player.name,
                })
              }
            >
              Remove
            </LargeDangerButton>,
          ]}
          disabled={!isHost}
        />
      )),
  ];

  return (
    <>
      <GalleryLayout
        participants={avatars.slice(
          zeroBasedPageNum * pageSize,
          (zeroBasedPageNum + 1) * pageSize
        )}
        aspectRatio={4 / 3}
      />
      {showRemovePlayerModal && (
        <RemovePlayerModal
          playerName={showRemovePlayerModal.name}
          onRemove={handleRemovePlayer}
          onCancel={() => setShowRemovePlayerModal(false)}
        />
      )}
    </>
  );
};

export default GalleryPageController;
