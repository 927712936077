import { useEffect, useState } from "react";
import getLogger from "../../../util/getLogger";

const logger = getLogger("useIsTrackStarted");

const useIsTrackStarted = (track) => {
  const [isStarted, setIsStarted] = useState(track ? track.isStarted : false);

  useEffect(() => {
    logger.debug("in useEffect");
    setIsStarted(Boolean(track ? track.isStarted : false));
    logger.debug("initial status", Boolean(track ? track.isStarted : false));

    if (track) {
      const setStarted = () => {
        logger.debug("in setStarted");
        setIsStarted(true);
      };

      logger.debug("setting up started handlers", track);

      track.on("started", setStarted);

      return () => {
        track.off("started", setStarted);
      };
    }
  }, [track]);

  return isStarted;
};

export default useIsTrackStarted;
