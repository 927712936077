import { useEffect } from "react";
import Pusher from "pusher-js";
import getPusherAppKey from "../../../util/getPusherAppKey";
import inTesting from "../../../util/inTesting";
import getLogger from "../../../util/getLogger";

const logger = getLogger("usePusherChannel");

const usePusherChannel = (channelName, handler) => {
  useEffect(() => {
    if (inTesting()) {
      logger.debug(
        "We are running in test mode, so skipping any connection attempts to pusher"
      );
      return;
    }

    if (channelName) {
      const pusher = new Pusher(getPusherAppKey(), {
        cluster: "us3",
      });

      const channel = pusher.subscribe(channelName);

      logger.info("subscribed to pusher channel", channelName);

      channel.bind_global(async (eventName, data) => {
        logger.info(
          `received pusher event on channel ${channelName}: ${eventName} with data`,
          data
        );
        logger.debug(data);
        handler(eventName, data);
      });

      return () => {
        channel.unbind();
        pusher.disconnect();
      };
    }
  }, [channelName]);
};

export default usePusherChannel;
