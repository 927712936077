import React, { useContext } from "react";
import styled from "styled-components";
import QuizActionLinkButton from "../../../../buttons/QuizActionLinkButton";
import QuizContext from "../../../../../contexts/QuizContext";
import { Wrapper as TeamWrapper } from "../../index";

const Wrapper = styled.div`
  margin-left: 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
    ${({ subtle }) =>
      subtle &&
      `
    opacity: 0;
    
    ${TeamWrapper}:hover & {
      opacity: 1;
    }
  `}}
`;

const TeamTiebreakerChangeClosest = ({ team, subtle, closest }) => {
  const { changeClosestTeamTiebreaker } = useContext(QuizContext);

  const handleClosestClick = () => changeClosestTeamTiebreaker(team.id);

  return (
    <Wrapper subtle={subtle} data-cy="adjust-score">
      {!closest && (
        <QuizActionLinkButton
          key="closest"
          label="Closest"
          clickedLabel="Changing"
          onClick={handleClosestClick}
        >
          Accept
        </QuizActionLinkButton>
      )}
    </Wrapper>
  );
};

export default TeamTiebreakerChangeClosest;
