import React from "react";
import ChatArea from "../../ChatArea";
import { QuizChatContextProvider } from "../../../contexts/QuizChatContext";

const ChatSection = () => {
  return (
    <QuizChatContextProvider>
      <ChatArea />
    </QuizChatContextProvider>
  );
};

export default ChatSection;
