import React from "react";
import styled from "styled-components";
import GradientCard from "../elements/cards/GradientCard";
import { PARAGRAPH_NORMAL, PARAGRAPH_NORMAL_BOLD } from "../../typography";
import { ELEMENT_OFF_WHITE, ELEMENT_OFF_WHITE_LIGHT } from "../../colors";

const StyledCard = styled(GradientCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 75px;
`;

const FactHeading = styled.div`
  ${PARAGRAPH_NORMAL_BOLD}
  color: ${ELEMENT_OFF_WHITE};
  margin-bottom: 20px;
`;

const FactsWrapper = styled.div`
  ${PARAGRAPH_NORMAL}
  color: ${ELEMENT_OFF_WHITE_LIGHT};
  max-width: 600px;
  
  & p {
    margin: 5px 0;
  }
`;

const Facts = ({ facts }) => {
  return (
    <StyledCard>
      <FactHeading>FUN FACTS</FactHeading>
      <FactsWrapper dangerouslySetInnerHTML={{ __html: facts }} />
    </StyledCard>
  );
};

export default Facts;
