import React, { useContext } from "react";
import PageHeading from "../PageHeading";
import QuizContext from "../../contexts/QuizContext";

export default () => {
  const { roundNumber, numRounds, tiebreakerNext } = useContext(QuizContext);
  const moreRounds = roundNumber < numRounds;

  let text;
  if (moreRounds) {
    text = `Total Scores after Round ${roundNumber}`;
  } else if (tiebreakerNext) {
    text = "Tie for First Place!";
  } else {
    text = "Final Scores";
  }

  return <PageHeading data-cy="page-heading">{text}</PageHeading>;
};
