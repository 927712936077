import React, { useContext, useEffect, useState } from "react";
import TiebreakerQuestion from "../Tiebreaker/TiebreakerQuestion";
import QuizContext from "../../contexts/QuizContext";

export default ({ quiz }) => {
  const { teamAnswerQuestion } = useContext(QuizContext);

  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);

  const handleAnswer = async (answerText) => {
    setIsSubmittingAnswer(true);
    await teamAnswerQuestion(answerText);
  };

  useEffect(() => {
    setIsSubmittingAnswer(false);
  }, [quiz.whatToShow]);

  return (
    <TiebreakerQuestion
      onAnswer={handleAnswer}
      isSubmitting={isSubmittingAnswer}
    />
  );
};
