import React from "react";
import AudioController from "./index";

const getAssistantsAudio = (assistants) => {
  let result = [];

  if (assistants) {
    result = [
      ...result,
      assistants.map((assistant) => (
        <AudioController key={assistant.id} participantId={assistant.id} />
      )),
    ];
  }

  return result;
};

export default getAssistantsAudio;
