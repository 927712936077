import React from "react";
import ParticipantVideo from "./ParticipantVideo";
import styled from "styled-components";
import {
  LARGE,
  LARGE_HEIGHT,
  LARGE_WIDTH,
  SMALL,
  SMALL_HEIGHT,
  SMALL_WIDTH,
  XLARGE,
  XLARGE_HEIGHT,
  XLARGE_WIDTH,
} from "../../dimensions";
import TextWithTooltipOnOverflow from "../TextWithTooltipOnOverflow";
import { LIGHT_GREY_4, LIGHT_REGULAR_TEXT, NEUTRAL_2 } from "../../colors";

const Wrapper = styled.div`
  ${({ size }) =>
    size === XLARGE &&
    `
    width: ${XLARGE_WIDTH}px;
  `}
  ${({ size }) =>
    size === LARGE &&
    `
    width: ${LARGE_WIDTH}px;
  `}
  ${({ size }) =>
    size === SMALL &&
    `
    width: ${SMALL_WIDTH}px;
  `}
  ${({ size }) =>
    size === "dominant" &&
    `
    width: 480px;
  `}
  ${({ hide }) =>
    hide &&
    `
    display: none;
  `}
`;

const Name = styled.div`
  font-size: 14px;
  color: ${LIGHT_REGULAR_TEXT};
  background-color: ${NEUTRAL_2};
  width: 100%;
  text-align: center;
  border-radius: 0 0 10px 10px;
  height: 20px;
`;

const NameTextWrapper = styled.div`
  padding: 0 7px;
`;

const ParticipantVideoWrapper = styled.div`
  width: 120px;
  height: 90px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${LIGHT_GREY_4};
  ${({ size }) =>
    size === XLARGE &&
    `
    width: ${XLARGE_WIDTH}px;
    height: ${XLARGE_HEIGHT}px;
  `}
  ${({ size }) =>
    size === LARGE &&
    `
    width: ${LARGE_WIDTH}px;
    height: ${LARGE_HEIGHT}px;
  `}
  ${({ size }) =>
    size === SMALL &&
    `
    width: ${SMALL_WIDTH}px;
    height: ${SMALL_HEIGHT}px;
  `}
  ${({ size }) =>
    size === "dominant" &&
    `
    width: 480px;
    height: 360px;
  `}
`;

const NewParticipant = ({ size, name, hide, ...restOfProps }) => {
  return (
    <Wrapper hide={hide} size={size}>
      <ParticipantVideoWrapper size={size} data-cy="participant">
        <ParticipantVideo {...restOfProps} />
      </ParticipantVideoWrapper>
      <Name>
        <NameTextWrapper>
          <TextWithTooltipOnOverflow text={name} />
        </NameTextWrapper>
      </Name>
    </Wrapper>
  );
};

export default NewParticipant;
