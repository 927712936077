import React from "react";
import styled from "styled-components";
import {
  ELEMENT_OFF_WHITE,
  ELEMENT_OFF_WHITE_LIGHT,
  PLATFORM_ERROR_LIGHT,
  PLATFORM_SUCCESS,
  TEXT_DEFAULT,
} from "../../colors";
import { PARAGRAPH_NORMAL_BOLD } from "../../typography";
import Answer from "../elements/answers/Answer";
import LightLinkButton from "../buttons/LightLinkButton";

const Wrapper = styled.div`
  border-radius: 0 0 10px 10px;
  padding: 0 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${PARAGRAPH_NORMAL_BOLD};
  position: relative;
  color: ${TEXT_DEFAULT};
  background-color: ${ELEMENT_OFF_WHITE};

  ${(props) =>
    props.correct === true &&
    `
    background-color: ${PLATFORM_SUCCESS};
  `}

  ${(props) =>
    props.correct === false &&
    `
    background-color: ${PLATFORM_ERROR_LIGHT};
  `}
`;

const TextWrapper = styled.div`
  flex: 1;
  text-align: center;
`;

const NoAnswer = styled.span`
  ${(props) =>
    [true, false].includes(props.correct) &&
    `
    color: ${ELEMENT_OFF_WHITE_LIGHT};
  `}
  ${PARAGRAPH_NORMAL_BOLD}
  opacity: 0.6;
`;

const AnswerText = styled.span`
  color: ${TEXT_DEFAULT};

  ${(props) =>
    [true, false].includes(props.correct) &&
    `
    color: ${ELEMENT_OFF_WHITE_LIGHT};
  `}
`;

const AnswerIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ChangeGradeButtonWrapper = styled.div`
  display: none;
  align-items: center;

  ${Wrapper}:hover &,
  ${Wrapper}.hover & {
      display: flex;
  }

  ${(props) =>
    !props.subtle &&
    `
    display: flex;
    
  `}
`;

const AnswerRow = ({
  text,
  graded,
  correct,
  inTiebreaker,
  canChangeGrade,
  onChangeGrade,
}) => {
  return (
    <Wrapper correct={correct}>
      <TextWrapper>
        {text && <AnswerText correct={correct}>{text}</AnswerText>}
        {!text && <NoAnswer correct={correct}>no answer</NoAnswer>}
      </TextWrapper>
      {graded && (
        <AnswerIconWrapper>
          {canChangeGrade && (
            <ChangeGradeButtonWrapper subtle={canChangeGrade === "subtle"}>
              {!inTiebreaker && (
                <>
                  {correct ? (
                    <LightLinkButton
                      leftSpinner
                      key={"reject"}
                      onClick={onChangeGrade}
                      data-cy-name={"Reject"}
                    >
                      Reject
                    </LightLinkButton>
                  ) : (
                    <LightLinkButton
                      leftSpinner
                      key={"accept"}
                      onClick={onChangeGrade}
                      data-cy-name={"Accept"}
                    >
                      Accept
                    </LightLinkButton>
                  )}
                </>
              )}
              {inTiebreaker && !correct && (
                <LightLinkButton
                  leftSpinner
                  key={"closest"}
                  onClick={onChangeGrade}
                  data-cy-name={"Closest"}
                >
                  Closest
                </LightLinkButton>
              )}
            </ChangeGradeButtonWrapper>
          )}
          <Answer correct={correct} />
        </AnswerIconWrapper>
      )}
    </Wrapper>
  );
};

export default AnswerRow;
