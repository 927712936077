import styled from "styled-components";
import PrimaryCard from "../elements/cards/PrimaryCard";
import { ELEMENT_OFF_WHITE } from "../../colors";
import ScrollBar from "../layouts/ScrollBar";

const HostInstructions = styled(PrimaryCard)`
  max-width: 600px;
  max-height: 100%;
  box-sizing: border-box;
  padding: 20px;
  color: ${ELEMENT_OFF_WHITE};
  overflow: auto;
  ${ScrollBar}
`;

export default HostInstructions;
