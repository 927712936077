import React, { useContext } from "react";
import styled from "styled-components";
import QuizActionLinkButton from "../../../../buttons/QuizActionLinkButton";
import QuizContext from "../../../../../contexts/QuizContext";
import { Wrapper as TeamWrapper } from "../../index";

const Wrapper = styled.div`
  margin-left: 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
    ${({ subtle }) =>
      subtle &&
      `
    opacity: 0;
    
    ${TeamWrapper}:hover & {
      opacity: 1;
    }
  `}}
`;

const TeamAnswerAdjust = ({ team, subtle, correct, incorrect }) => {
  const { adjustScore } = useContext(QuizContext);

  const handleAcceptClick = () => adjustScore(team.id, true);
  const handleRejectClick = () => adjustScore(team.id, false);

  return (
    <Wrapper subtle={subtle} data-cy="adjust-score">
      {incorrect && (
        <QuizActionLinkButton
          key="accept"
          label="Accept"
          clickedLabel="Accepting"
          onClick={handleAcceptClick}
        >
          Accept
        </QuizActionLinkButton>
      )}
      {correct && (
        <QuizActionLinkButton
          key="reject"
          label="Reject"
          clickedLabel="Rejecting"
          onClick={handleRejectClick}
        >
          Reject
        </QuizActionLinkButton>
      )}
    </Wrapper>
  );
};

export default TeamAnswerAdjust;
