import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import Facts from "../../../components/intros/Facts";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import IntroLayout from "../../../components/layouts/IntroLayout";
import HostsOnIntroScreens from "../../../components/hosts/HostsOnIntroScreens";

const FactsScreen = ({
  audio,
  isHost,
  hostAvatars,
  facts,
  next = "question",
  onNextQuestion,
  onRoundResults,
  resetButton,
  navigation,
  chat,
  modal,
}) => {
  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        navigation={navigation}
        chat={chat}
        payload={
          <IntroLayout
            intro={<Facts facts={facts} />}
            hosts={<HostsOnIntroScreens hostAvatars={hostAvatars} />}
          />
        }
        actionBar={
          isHost && (
            <ActionBar>
              {next === "question" && (
                <LargeDarkButton onClick={onNextQuestion}>
                  Next Question
                </LargeDarkButton>
              )}
              {next === "round-results" && (
                <LargeDarkButton onClick={onRoundResults}>
                  Round Results
                </LargeDarkButton>
              )}
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default FactsScreen;
