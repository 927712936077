import React, { useContext, useEffect, useState } from "react";
import getLogger from "../util/getLogger";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import inTesting from "../util/inTesting";
import Pusher from "pusher-js";
import getPusherAppKey from "../util/getPusherAppKey";
import QuizContext from "./QuizContext";
import getTimeStamp from "../util/getTimeStamp";
import useThreadProcessor from "../hooks/useThreadProcessor";

const logger = getLogger("QuizChatContext");

const GET_CURRENT_QUIZ_CHAT = gql`
  query GetCurrentQuizChat {
    chat: getCurrentQuizChat {
      id
      messages {
        id
        body
        fromPlayer {
          id
          name
          team {
            id
            name
            emoji
          }
        }
        fromHost {
          id
          name
        }
      }
    }
  }
`;

const SEND_CHAT_MESSAGE = gql`
  mutation SendChatMessage($chat: String!, $body: String!) {
    sendChatMessage(chat: $chat, body: $body) {
      ok
    }
  }
`;

const QuizChatContext = React.createContext();

export const QuizChatContextProvider = (props) => {
  const { chatId } = useContext(QuizContext);
  const [getCurrentQuizChat, { data }] = useLazyQuery(GET_CURRENT_QUIZ_CHAT);
  const [chat, setChat] = useState(null);
  const [refetchedAt, setRefetchedAt] = useState(null);

  const [sendChatMessageMutation] = useMutation(SEND_CHAT_MESSAGE);

  const [chatInputValue, setChatInputValue] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectionStart, setSelectionStart] = useState(0);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (data) {
      setChat(data.chat);
    }
  }, [data]);

  const handleChatUpdate = async (update) => {
    switch (update.update) {
      case "chatMutated":
        setChat(update.data.chat);
        setRefetchedAt(getTimeStamp());
    }
  };

  const { addMessage } = useThreadProcessor(handleChatUpdate);

  useEffect(() => {
    if (inTesting()) {
      logger.debug(
        "We are running in test mode, so skipping any connection attempts to pusher"
      );
      getCurrentQuizChat();
      return;
    }

    if (chatId) {
      const pusher = new Pusher(getPusherAppKey(), {
        cluster: "us3",
      });

      const channel = pusher.subscribe(`${chatId}`);
      logger.info("subscribed to channel: ", chatId);

      channel.bind_global(async (eventName, data) => {
        logger.debug(`received pusher event: ${eventName} with data`, data);
        if (eventName === "thread") {
          addMessage(data);
        } else if (eventName === "message") {
          if (data.text === "chat message sent") {
            logger.info("Chat message was sent");
            await handleChatUpdate(data);
          }
        }
      });

      getCurrentQuizChat();

      return () => {
        channel.unbind();
        pusher.disconnect();
      };
    }
  }, [chatId]);

  // useEffect(() => {
  //   if (whatToShow === MEET_TEAMS) {
  //     // refresh once teams are set up to get the names and emojis
  //     handleChatUpdate();
  //   }
  // }, [whatToShow]);

  const sendChatMessage = async (body) => {
    setSending(true);
    logger.info("Send Chat Message");
    await sendChatMessageMutation({
      variables: {
        chat: "quiz",
        body,
      },
    });
    setSending(false);
  };

  const messages = chat && chat.messages;

  return (
    <QuizChatContext.Provider
      value={{
        chat,
        messages,
        sendChatMessage,
        refetchedAt,

        chatInputValue,
        setChatInputValue,
        showEmojiPicker,
        setShowEmojiPicker,
        selectionStart,
        setSelectionStart,
        sending,
      }}
    >
      {props.children}
    </QuizChatContext.Provider>
  );
};

export default QuizChatContext;
