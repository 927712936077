import { useContext, useEffect, useState } from "react";
import MainRoomContext from "../../../contexts/MainRoomContext";

const useLastDominantSpeakerIdOnTeam = (team) => {
  const { dominantSpeaker } = useContext(MainRoomContext);
  const [lastDominantSpeakerOnTeam, setLastDominantSpeakerOnTeam] = useState();

  useEffect(() => {
    const isSpeakerOnTeam = (speaker) =>
      team.players.find((player) => player.id === speaker.identity);

    if (team && dominantSpeaker) {
      if (isSpeakerOnTeam(dominantSpeaker)) {
        setLastDominantSpeakerOnTeam(dominantSpeaker.identity);
      }
    }
  }, [team, dominantSpeaker]);

  return lastDominantSpeakerOnTeam;
};

export default useLastDominantSpeakerIdOnTeam;
