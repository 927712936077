import React, { useContext, useEffect, useRef, useState } from "react";
import Spinner from "../Spinner";
import { TextInput } from "grommet/index";
import { gql, useMutation } from "@apollo/client";
import styled from "styled-components";
import QuizContext from "../../contexts/QuizContext";
import SendIcon from "../icons/SendIcon";
import { BRAND, WHITE } from "../../colors";
import SubtleButton from "../buttons/SubtleButton";
import getLogger from "../../util/getLogger";

const logger = getLogger("TeamLeaderInput");

const TYPE_ANSWER = gql`
  mutation TeamLeadTypingAnswer($text: String!) {
    teamLeadTypingAnswer(text: $text) {
      ok
    }
  }
`;

const TextInputWrapper = styled.div`
  margin-top: 30px;
  input {
    background-color: ${WHITE};
    padding-right: 50px;
  }
  position: relative;
`;

const SubmitButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
`;

const IconWrapper = styled.div`
  filter: grayscale(100%);
  &:hover {
    filter: none;
  }
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 12px;
  right: 15px;
`;

export default () => {
  const { teamAnswerTiebreaker } = useContext(QuizContext);

  const [answerInput, setAnswerInput] = useState("");
  const answerInputRef = useRef("");

  const [answered, setAnswered] = useState(false);
  const answeredRef = useRef(false);

  const [typeAnswer] = useMutation(TYPE_ANSWER);

  // autosubmit when we are being destroyed with no answer sent
  useEffect(() => {
    logger.debug("instantiated");
    return () => {
      logger.debug("being destroyed");
      if (!answeredRef.current) {
        logger.info("auto-submitting answer", answerInputRef.current);
        sendAnswer();
      }
    };
  }, []);

  const sendAnswer = async () => {
    logger.debug("sendAnswer", answerInputRef.current);
    let valueToSend = answerInputRef.current.trim();
    if (valueToSend.length > 0) {
      setAnswered(true);
      answeredRef.current = true;
      logger.debug("teamAnswerTiebreaker");
      await teamAnswerTiebreaker(valueToSend);
    }
  };

  const handleChange = async (e) => {
    setAnswerInput(e.target.value);
    answerInputRef.current = e.target.value;
    await typeAnswer({
      variables: {
        text: e.target.value,
      },
    });
  };

  const handleKeyDown = async (e) => {
    if (e.keyCode === 13) {
      await sendAnswer();
    }
  };

  const handleSendClick = async () => {
    await sendAnswer();
  };

  return (
    <TextInputWrapper>
      {!answered && answerInput.length > 0 && (
        <SubmitButtonWrapper>
          <SubtleButton data-cy="send-button">
            <IconWrapper>
              <SendIcon color={BRAND} onClick={handleSendClick} />
            </IconWrapper>
          </SubtleButton>
        </SubmitButtonWrapper>
      )}
      {answered && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      <TextInput
        size="large"
        disabled={answered}
        value={answerInput}
        autoFocus
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        data-cy="team-answer-input"
      />
    </TextInputWrapper>
  );
};
