import React, { useContext, useState } from "react";
import Modal from "../Modal";
import DangerButton from "../buttons/DangerButton";
import OurButton from "../buttons/Button";
import { navigate } from "gatsby";
import QuizContext from "../../contexts/QuizContext";

export default ({ onCancel }) => {
  const { leaveQuiz, started, myPlayerId } = useContext(QuizContext);
  const [submitted, setSubmitted] = useState(false);

  const handleResetQuiz = async () => {
    setSubmitted(true);
    await leaveQuiz();
    if (started && myPlayerId) {
      await navigate("/survey/");
    } else {
      await navigate("/");
    }
  };

  return (
    <Modal
      title="Leave Quiz"
      text="Are you sure you want to leave the quiz?"
      buttons={
        <>
          <DangerButton
            size="large"
            onClick={handleResetQuiz}
            disabled={submitted}
            data-cy="leave-button"
          >
            {submitted ? "Leaving" : "Leave"}
          </DangerButton>
          <OurButton size="large" onClick={onCancel}>
            Cancel
          </OurButton>
        </>
      }
    />
  );
};
