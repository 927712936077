import React, { useContext } from "react";
import Modal from "../Modal";
import Button from "../buttons/Button";
import QuizContext from "../../contexts/QuizContext";
import styled from "styled-components";

const BodyWrapper = styled.div`
  width: 90%;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
`;

const PlayerWrapper = styled.div`
  margin: 5px 10px;
`;

const AllPlayersModal = ({ onClose }) => {
  const { players } = useContext(QuizContext);

  return (
    <Modal
      title="All Players"
      body={
        <BodyWrapper>
          {players.map((player) => (
            <PlayerWrapper data-cy="player" key={player.id}>
              {player.name}
            </PlayerWrapper>
          ))}
        </BodyWrapper>
      }
      buttons={
        <>
          <Button size="large" onClick={onClose}>
            Close
          </Button>
        </>
      }
    />
  );
};

export default AllPlayersModal;
