import React from "react";
import styled from "styled-components";
import MainInput from "./MainInput";
import MainButton from "../buttons/MainButton";
import InputWithButton from "./InputWithButton";

const StyledInput = styled(MainInput)`
  width: 100%;
`;

const StyledButton = styled(MainButton)`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 35px;
`;

const MainInputWithButton = ({
  placeholder,
  buttonText,
  disabled,
  buttonDisabled,
  politeAutofocus,
  value,
  onChange,
  onSubmit,
  submitting,
  className,
  inputName,
  buttonName,
  ...rest
}) => {
  return (
    <InputWithButton
      Input={StyledInput}
      Button={StyledButton}
      disabled={disabled}
      placeholder={placeholder}
      politeAutofocus={politeAutofocus}
      buttonText={buttonText}
      buttonDisabled={buttonDisabled}
      value={value}
      onChange={onChange}
      onSubmit={onSubmit}
      submitting={submitting}
      className={className}
      inputName={inputName}
      buttonName={buttonName}
      {...rest}
    />
  );
};

export default MainInputWithButton;
