import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import getLogger from "../../../util/getLogger";
import VideoOffMessage from "./VideoOffMessage";
import MutedMessage from "./MutedMessage";
import isLocalParticipant from "../../../util/isLocalParticipant";
import DeviceContext from "../../../contexts/DeviceContext";
import Video from "./MonitoredVideo";
import NetworkQualityMonitor from "./NetworkQualityMonitor";
// import trackpubsToTracks from "../../util/trackpubsToTracks";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

// const Video = styled.video`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   ${({ hide }) =>
//     hide &&
//     `
//     display: none;
//   `}
// `;

const RealParticipantVideo = ({
  participant,
  localVideoTrack,
  isDominantSpeaker,
  setVideoOff,
}) => {
  const logger = getLogger(
    `RealParticipantVideo: ${participant && participant.identity}${
      isDominantSpeaker ? ": dominant" : ": regular"
    }`
  );

  const { muted, videoStopped } = useContext(DeviceContext);

  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [displayMuted, setDisplayMuted] = useState(false);
  const videoRef = useRef();

  const trackpubsToTracks = (trackMap) => {
    logger.debug("in trackpubsToTracks");
    logger.debug(trackMap);
    const result1 = Array.from(trackMap.values());
    logger.debug(result1);
    const result2 = result1.map((publication) => publication.track);
    logger.debug(result2);
    const result3 = result2.filter((track) => track !== null);
    logger.debug(result3);
    return result3;
  };

  useEffect(() => {
    if (participant) {
      const handleTrackSubscribed = (track) => {
        logger.info(`Received trackSubscribed event for track ${track}`);
        logger.debug(track);
        if (track.kind === "video") {
          setVideoTracks((videoTracks) => [...videoTracks, track]);
          logger.debug("Set video tracks");
        }
        if (track.kind === "audio") {
          setAudioTracks((audioTracks) => [...audioTracks, track]);
          logger.debug("Set audio tracks");
        }
      };

      const handleTrackUnsubscribed = (track) => {
        logger.info(`Received trackUnsubscribed event for track ${track}`);
        logger.debug(track);
        if (track.kind === "video") {
          setVideoTracks((videoTracks) =>
            videoTracks.filter((v) => v !== track)
          );
          logger.debug("Set video tracks");
        }
        if (track.kind === "audio") {
          setAudioTracks((audioTracks) =>
            audioTracks.filter((v) => v !== track)
          );
          logger.debug("Set audio tracks");
        }
      };

      const addParticipantListeners = () => {
        participant.on("trackSubscribed", handleTrackSubscribed);
        participant.on("trackUnsubscribed", handleTrackUnsubscribed);
        logger.debug("Added all room event listeners");
      };

      const removeParticipantListeners = () => {
        participant.off("trackSubscribed", handleTrackSubscribed);
        participant.off("trackUnsubscribed", handleTrackUnsubscribed);
        logger.debug("Removed all room event listeners");
      };

      const init = () => {
        addParticipantListeners();
        logger.debug("participant.videoTracks", participant.videoTracks);
        if (isLocalParticipant(participant)) {
          setVideoTracks([localVideoTrack]);
          logger.debug("set videoTracks to [localVideoTrack]", localVideoTrack);
        } else {
          const parsedVideoTracks = trackpubsToTracks(participant.videoTracks);
          setVideoTracks(parsedVideoTracks);
          logger.info("Set initial video tracks");
          logger.debug(parsedVideoTracks);

          const parsedAudioTracks = trackpubsToTracks(participant.audioTracks);
          setAudioTracks(parsedAudioTracks);
          logger.info("Set initial audio tracks");
          logger.debug(parsedAudioTracks);
        }
        logger.debug("participant", participant);
      };

      init();

      return () => {
        removeParticipantListeners();
        setVideoTracks([]);
        setAudioTracks([]);
      };
    }
  }, [participant]);

  useEffect(() => {
    logger.debug("Video tracks have changed");

    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      logger.info("Attached video track to html element");

      return () => {
        videoTrack.detach(videoRef.current);
        logger.info("Detached video track from html element");
      };
    } else {
      logger.debug("No video tracks to attach");

      return () => {
        logger.debug("No video tracks to detach");
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    if (isLocalParticipant(participant)) {
      if (videoRef.current) {
        videoRef.current.style.transform = "scale(-1, 1)";
      }
    }
  }, [participant, videoRef.current]);

  let videoOff = false;
  if (videoTracks.length === 0) {
    videoOff = true;
  }
  if (isLocalParticipant(participant) && videoStopped) {
    videoOff = true;
  }

  useEffect(() => {
    if (setVideoOff) {
      setVideoOff(videoOff);
    }
  }, [videoOff, setVideoOff]);

  useEffect(() => {
    logger.debug("audio tracks or participant has changed");
    logger.debug("audio tracks", audioTracks);

    if (isLocalParticipant(participant)) {
      if (muted) {
        setDisplayMuted(true);
        logger.debug("displaying muted because local participant is muted");
      } else {
        setDisplayMuted(false);
        logger.debug(
          "not displaying muted because the local participant is not muted"
        );
      }
    } else if (isDominantSpeaker) {
      setDisplayMuted(false);
      logger.debug(
        "not displaying muted because this is the dominant speaker display"
      );
    } else if (audioTracks.length === 0) {
      setDisplayMuted(true);
      logger.debug("displaying muted because there are no audio tracks");
    } else {
      setDisplayMuted(false);
      logger.debug("not displaying muted because there are audio tracks");
    }
  }, [audioTracks, participant, muted]);

  return (
    <Wrapper>
      <Video
        identity={participant && participant.identity}
        hide={videoOff}
        ref={videoRef}
        autoPlay={true}
      />
      <NetworkQualityMonitor participant={participant} />
      {displayMuted && <MutedMessage />}
      {videoOff && <VideoOffMessage />}
    </Wrapper>
  );
};

export default RealParticipantVideo;
