import React, { useState } from "react";
import LinkButton from "./LinkButton";

export default ({ label, clickedLabel, disabled, onClick, ...props }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = async () => {
    setClicked(true);
    await onClick();

    // Let's just assume we are going to be destroyed, so don't bother to
    // reset the clicked state and cause a confusing flash for user
    // setClicked(false);
  };

  return (
    <LinkButton disabled={clicked || disabled} onClick={handleClick} {...props}>
      {clicked ? clickedLabel : label}
    </LinkButton>
  );
};
