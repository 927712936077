import React, { useContext } from "react";
import CompactTeam from "../../../components/teams/CompactTeam";
import useAssistTeamMutation from "../../../hooks/mutations/useAssistTeamMutation";
import useFinishAssistingTeamMutation from "../../../hooks/mutations/useFinishAssistingTeamMutation";
import QuizContext from "../../../../contexts/QuizContext";
import getTeamSelfies from "./getTeamSelfies";

const CompactTeamController = ({
  team,
  naming,
  isHost,
  teamAnswerOrTiebreaker,
}) => {
  const { assistingTeamId } = useContext(QuizContext);

  const [assistTeamMutation] = useAssistTeamMutation();
  const [finishAssistingTeamMutation] = useFinishAssistingTeamMutation();

  const handleAssistTeam = async () => {
    await assistTeamMutation({ variables: { teamId: team.id } });
  };

  const handleFinishAssistingTeam = async () => {
    await finishAssistingTeamMutation();
  };

  return (
    <CompactTeam
      key={team.id}
      naming={naming}
      answer={!naming && teamAnswerOrTiebreaker && teamAnswerOrTiebreaker.text}
      isHost={isHost}
      teamId={team.id}
      teamName={team.name}
      emoji={team.emoji}
      selfies={getTeamSelfies(team, 35)}
      onAssist={handleAssistTeam}
      assisting={team.id === assistingTeamId}
      onFinishAssisting={handleFinishAssistingTeam}
    />
  );
};

export default CompactTeamController;
