import { useEffect, useState } from "react";

const useNetworkQualityLevel = (participant) => {
  const [level, setLevel] = useState();
  const [stats, setStats] = useState();

  useEffect(() => {
    if (participant) {
      const handleLevelChanged = (networkQualityLevel, networkQualityStats) => {
        setLevel(networkQualityLevel);
        setStats(networkQualityStats);
      };

      participant.on("networkQualityLevelChanged", handleLevelChanged);

      setLevel(participant.networkQualityLevel);
      setStats(participant.networkQualityStats);

      return () => {
        participant.off("networkQualityLevelChanged", handleLevelChanged);
      };
    } else {
      setLevel(undefined);
      setStats(undefined);
    }
  }, [participant]);

  return [level, stats];
};

export default useNetworkQualityLevel;
