import React, { useContext, useState } from "react";
import SubtleButton from "../buttons/SubtleButton";
import styled from "styled-components";
import LightA from "../LightA";
import DarkButton from "../buttons/DarkButton";
import LeaveQuizModal from "../modals/LeaveQuizModal";
import getLogger from "../../util/getLogger";
import RefreshIcon from "../../components/icons/RefreshIcon";
import QuizContext from "../../contexts/QuizContext";
import LabelWrapper from "./LabelWrapper";
import AVControls from "./AVControls";
import Logo from "../Logo";
import FeatureFlagContext from "../../contexts/FeatureFlagContext";
import { LIGHT_GREY_1, WHITE } from "../../colors";
import SessionDataContext from "../../contexts/SessionDataContext";
import { navigate } from "gatsby-link";

const Wrapper = styled.div`
  display: flex;
  color: ${WHITE};
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;
  width: 100%;
`;

const LogoWrapper = styled.div``;

const MenuButtonsWrapper = styled.div`
  > * {
    margin-left: 15px;
  }
`;

export default () => {
  const { flags } = useContext(FeatureFlagContext);
  const {
    requestRefetch,
    myHostId,
    myPlayerId,
    myTeamId,
    event,
    leaveQuiz,
  } = useContext(QuizContext);
  const { myEventHostId, myEventId } = useContext(SessionDataContext);

  const isEventHost = event && myEventHostId && myEventId === event.id;

  let loggerName = "HeaderArea";
  if (myHostId) {
    loggerName = `${loggerName}: Host`;
  } else if (myTeamId) {
    loggerName = `${loggerName}: Team ${myTeamId}`;
  } else {
    loggerName = `${loggerName}: Player ${myPlayerId}`;
  }
  const logger = getLogger(loggerName);

  const [refreshingQuiz, setRefreshingQuiz] = useState(false);
  const [showLeaveQuizModal, setShowLeaveQuizModal] = useState(false);

  const handleReturnToEventClick = async () => {
    await leaveQuiz();
    await navigate("/event/");
  };

  const handleLeaveQuizClick = async () => setShowLeaveQuizModal(true);
  const handleLeaveQuizCancel = () => setShowLeaveQuizModal(false);

  const handleRefreshQuiz = async () => {
    logger.info("Refreshing quiz");
    setRefreshingQuiz(true);
    await requestRefetch();
    setRefreshingQuiz(false);
    logger.info("Finished refreshing quiz");
  };

  return (
    <Wrapper>
      <LogoWrapper>
        <SubtleButton>
          <Logo />
        </SubtleButton>
      </LogoWrapper>
      <MenuButtonsWrapper>
        <AVControls />
        {(!flags["hide-refresh-quiz-button"] || myHostId) && (
          <DarkButton disabled={refreshingQuiz} onClick={handleRefreshQuiz}>
            <LabelWrapper>
              <RefreshIcon color={LIGHT_GREY_1} />{" "}
              {refreshingQuiz ? "Refreshing Quiz" : "Refresh Quiz"}
            </LabelWrapper>
          </DarkButton>
        )}
        {isEventHost && (
          <LightA onClick={handleReturnToEventClick}>Return to Event</LightA>
        )}
        {!isEventHost && (
          <LightA onClick={handleLeaveQuizClick}>Leave Quiz</LightA>
        )}
      </MenuButtonsWrapper>
      {showLeaveQuizModal && (
        <LeaveQuizModal onCancel={handleLeaveQuizCancel} />
      )}
    </Wrapper>
  );
};
