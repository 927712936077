import React, { useContext } from "react";
import QuizContext from "../../../../contexts/QuizContext";
import AvatarController from "./AvatarController";
import hostSortCompare from "../../../util/hostSortCompare";
import MainRoomContext from "../../../contexts/MainRoomContext";
import inTesting from "../../../../util/inTesting";

const getHostAvatarControllerList = () => {
  const { hosts } = useContext(QuizContext);
  const { participantsWithVideo } = useContext(MainRoomContext);

  return hosts
    .filter((host) => participantsWithVideo[host.id] || inTesting())
    .sort(hostSortCompare)
    .map((host) => <AvatarController key={host.id} host={host} />);
};

export default getHostAvatarControllerList;
