import React, { useContext } from "react";
import styled from "styled-components";
import DarkPageHeading from "../DarkPageHeading";
import useGetCurrentQuizForSurveyQuery from "./useGetCurrentQuizForSurveyQuery";
import SurveyContext from "../../contexts/SurveyContext";
import SessionDataContext from "../../contexts/SessionDataContext";
import useGetCurrentEventForSurveyQuery from "./useGetCurrentEventForSurveyQuery";
import PageHeading from "../PageHeading";

const Wrapper = styled.div`
  margin: 0 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SurveyResultsHeader = ({ dark }) => {
  const { myPublicEventId } = useContext(SessionDataContext);
  const { data: eventData } = useGetCurrentEventForSurveyQuery();
  const { data: quizData } = useGetCurrentQuizForSurveyQuery();
  const { responses } = useContext(SurveyContext);

  const eventPlayers =
    eventData && eventData.event && eventData.event.eventPlayers;
  const players = quizData && quizData.quiz && quizData.quiz.players;
  const eventPlayerCount = eventPlayers ? eventPlayers.length : 0;
  const playerCount = players ? players.length : 0;

  const totalCount = myPublicEventId ? eventPlayerCount : playerCount;

  return (
    <Wrapper>
      {dark && (
        <DarkPageHeading data-cy="page-heading">Survey Results</DarkPageHeading>
      )}
      {!dark && (
        <PageHeading data-cy="page-heading">Survey Results</PageHeading>
      )}
      {responses.length === 0 && (
        <div>Waiting for first response from {totalCount} players</div>
      )}
      {responses.length > 0 && (
        <div>
          {responses.length} out of {totalCount} players responded
        </div>
      )}
    </Wrapper>
  );
};

export default SurveyResultsHeader;
