import React, { useContext } from "react";
import styled from "styled-components";
import { LIGHT_REGULAR_TEXT } from "../../../../colors";
import HostSignifier from "./HostSignifier";
import TeamName from "./TeamName";
import Name from "./Name";
import QuizContext from "../../../../contexts/QuizContext";
import { NAME_TEAMS, QUIZ_INTRO } from "../../../../constants";
import Avatar from "./Avatar";

const Wrapper = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${LIGHT_REGULAR_TEXT};
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const AvatarWrapper = styled.div`
  flex: 0;
  padding-right: 7px;
`;

const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Sender = ({ message }) => {
  const { started, whatToShow } = useContext(QuizContext);
  const showAvatar = started && ![QUIZ_INTRO, NAME_TEAMS].includes(whatToShow);

  const isHost = !!message.fromHost;
  const team = message.fromPlayer && message.fromPlayer.team;

  let name;
  if (message.fromHost) {
    name = message.fromHost.name;
  } else if (message.fromPlayer) {
    const player = message.fromPlayer;
    name = player.name;
  }

  return (
    <Wrapper>
      {showAvatar && (
        <AvatarWrapper>
          <Avatar message={message} />
        </AvatarWrapper>
      )}
      <TextWrapper>
        {isHost && <HostSignifier>Host</HostSignifier>}
        {team && <TeamName>{team.name}</TeamName>}
        <Name>{name}</Name>
      </TextWrapper>
    </Wrapper>
  );
};

export default Sender;
