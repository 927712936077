import React, { useContext } from "react";
import styled from "styled-components";
import QuizContext from "../../../../../contexts/QuizContext";
import {
  ANSWERS,
  QUESTION,
  QUESTION_RESULTS,
  TIEBREAKER,
  TIEBREAKER_ANSWERS,
  TIEBREAKER_RESULTS,
} from "../../../../../constants";
import TeamAnswerAdjust from "./TeamAnswerAdjust";
import TeamTiebreakerChangeClosest from "./TeamTiebreakerChangeClosest";
import { DARK_GREY_4 } from "../../../../../colors";

const Wrapper = styled.div`
  margin-top: 5px;
  display: flex;
  max-width: 100%;
`;

const AnswerText = styled.div`
  flex: 1;
  margin-left: 50px;
  font-size: 20px;
  line-height: 120%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  ${({ muted }) =>
    muted &&
    `
    font-style: italic;
    color: ${DARK_GREY_4};
    font-weight: normal;
  `}
`;

const TeamAnswerRow = ({ team, correct, incorrect }) => {
  const { myHostId, whatToShow, getTeamAnswer } = useContext(QuizContext);

  if (
    [TIEBREAKER, TIEBREAKER_ANSWERS, TIEBREAKER_RESULTS].includes(whatToShow)
  ) {
    const teamTiebreaker = team && team.teamTiebreaker;
    const answerText = teamTiebreaker && teamTiebreaker.text;

    let showClosest;
    if (myHostId && whatToShow === TIEBREAKER_ANSWERS) {
      showClosest = true;
    } else if (myHostId && whatToShow === TIEBREAKER_RESULTS) {
      showClosest = "subtle";
    } else {
      showClosest = null;
    }

    const closest = teamTiebreaker && teamTiebreaker.closest;

    return (
      <Wrapper>
        <AnswerText muted={!answerText}>
          {answerText ||
            (whatToShow === TIEBREAKER ? "waiting for answer..." : "no answer")}
        </AnswerText>

        {showClosest && (
          <TeamTiebreakerChangeClosest
            team={team}
            subtle={showClosest === "subtle"}
            closest={closest}
          />
        )}
      </Wrapper>
    );
  } else {
    const teamAnswer = getTeamAnswer(team);
    const answerText = teamAnswer && teamAnswer.text;

    let showAdjust;
    if (myHostId && whatToShow === ANSWERS) {
      showAdjust = true;
    } else if (myHostId && whatToShow === QUESTION_RESULTS) {
      showAdjust = "subtle";
    } else {
      showAdjust = null;
    }

    return (
      <Wrapper>
        <AnswerText muted={!answerText}>
          {answerText ||
            (whatToShow === QUESTION ? "waiting for answer..." : "no answer")}
        </AnswerText>
        {showAdjust && (
          <TeamAnswerAdjust
            team={team}
            subtle={showAdjust === "subtle"}
            correct={correct}
            incorrect={incorrect}
          />
        )}
      </Wrapper>
    );
  }
};

export default TeamAnswerRow;
