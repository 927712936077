import React from "react";
import HostInstructions from "../HostInstructions";
import InstructionTitle from "../InstructionTitle";
import InstructionHeading from "../InstructionHeading";
import Instruction from "../Instruction";
import InstructionList from "../InstructionList";
import SubInstructionList from "../SubInstructionList";
import SubInstructionHeading from "../SubInstructionHeading";
import SubInstruction from "../SubInstruction";

const GameInstructions = () => {
  return (
    <HostInstructions>
      <InstructionTitle>Game Play Instructions</InstructionTitle>
      <InstructionList>
        <Instruction>
          <InstructionHeading>
            You have 60 seconds to answer each question.
          </InstructionHeading>
        </Instruction>
        <Instruction>
          <InstructionHeading>
            As a reminder, team rooms are private but the chat is available to
            everyone at all times.
          </InstructionHeading>
          <SubInstructionList type={"a"}>
            <SubInstruction>
              <SubInstructionHeading>
                So no answers in the chat.
              </SubInstructionHeading>
            </SubInstruction>
          </SubInstructionList>
        </Instruction>
        <Instruction>
          <InstructionHeading>No Googling, obviously. </InstructionHeading>
          <SubInstructionList type={"a"}>
            <SubInstruction>
              <SubInstructionHeading>
                If you are here to cheat at trivia, aim higher in life!
              </SubInstructionHeading>
            </SubInstruction>
          </SubInstructionList>
        </Instruction>
      </InstructionList>
    </HostInstructions>
  );
};

export default GameInstructions;
