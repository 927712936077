import React from "react";

const LeftArrowIcon = ({ width = 16, height = 27 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3512 0.856012L13.9985 1.20878L14.3512 0.85601C14.1218 0.626539 13.8114 0.5 13.5 0.5C13.1986 0.5 12.8832 0.614519 12.6458 0.858908L0.858693 12.646C0.858044 12.6467 0.857395 12.6473 0.856748 12.6479C0.381807 13.116 0.38246 13.8843 0.850073 14.3519L12.6434 26.1453C13.1164 26.6182 13.8783 26.6182 14.3512 26.1453C14.8242 25.6724 14.8242 24.9104 14.3512 24.4375L3.41437 13.5007L14.3512 2.5638C14.8242 2.09086 14.8242 1.32895 14.3512 0.856012Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

export default LeftArrowIcon;
