import React from "react";
import styled from "styled-components";
import { ELEMENT_LIGHT, HIGHLIGHT_PRIMARY, WHITE } from "../../../colors";
import RightArrowIcon from "../../../icons/RightArrowIcon";
import LeftArrowIcon from "../../../icons/LeftArrowIcon";
import IconButton from "../../buttons/IconButton";

const ARROW_WIDTH = 8;
const ARROW_HEIGHT = 12;

const Wrapper = styled.div`
  display: flex;
  column-gap: 5px;
`;

const PrevNextButton = styled(IconButton)`
  height: 25px;
  width: 25px;
  background-color: ${ELEMENT_LIGHT};
  border-radius: 25px;
  color: ${WHITE};
`;

const StyledLeftArrowIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: -1px;
`;

const StyledRightArrowIconWrapper = styled(StyledLeftArrowIconWrapper)`
  left: 1px;
`;

const PageButton = styled(PrevNextButton)`
  border-radius: 5px;

  ${(props) =>
    props.currentPage &&
    `
    background-color: ${HIGHLIGHT_PRIMARY};
    
    &:hover:enabled  {
      background-color: ${HIGHLIGHT_PRIMARY};
      cursor: default;
    }
  `}
`;

const PagingIndicatorAndControl = ({
  pageNum,
  numOfPages,
  onPrevPage,
  onNextPage,
  onSetPage,
}) => {
  const handleSetPageClick = (pageNum) => () => {
    onSetPage(pageNum);
  };

  return (
    <Wrapper data-cy={"PagingIndicatorAndControl"}>
      <PrevNextButton
        disabled={pageNum === 1}
        onClick={onPrevPage}
        noSpinner
        data-cy-direction={"prev"}
      >
        <StyledLeftArrowIconWrapper>
          <LeftArrowIcon width={ARROW_WIDTH} height={ARROW_HEIGHT} />
        </StyledLeftArrowIconWrapper>
      </PrevNextButton>
      {[...Array(numOfPages)].map((item, index) => {
        const adjustedIndex = index + 1;
        return (
          <PageButton
            key={index}
            currentPage={pageNum === adjustedIndex}
            onClick={handleSetPageClick(adjustedIndex)}
            noSpinner
            data-cy-page={adjustedIndex}
          >
            {adjustedIndex}
          </PageButton>
        );
      })}
      <PrevNextButton
        disabled={pageNum === numOfPages}
        onClick={onNextPage}
        noSpinner
        data-cy-direction={"next"}
      >
        <StyledRightArrowIconWrapper>
          <RightArrowIcon width={ARROW_WIDTH} height={ARROW_HEIGHT} />
        </StyledRightArrowIconWrapper>
      </PrevNextButton>
    </Wrapper>
  );
};

export default PagingIndicatorAndControl;
