import React from "react";
import AudioController from "./index";

const getTeamAudio = (team) => {
  let result = [];

  if (team && team.players) {
    result = [
      ...team.players.map((participant) => (
        <AudioController key={participant.id} participantId={participant.id} />
      )),
    ];
  }

  return result;
};

export default getTeamAudio;
