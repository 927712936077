import styled from "styled-components";
import { LIGHT_REGULAR_TEXT } from "../../../../colors";

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${LIGHT_REGULAR_TEXT};
`;

export default Name;
