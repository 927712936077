import { useCallback, useContext, useEffect, useState } from "react";
import QuizContext from "../../contexts/QuizContext";
import useInterval from "../../hooks/useInterval";
import ServerTimeContext from "../../contexts/ServerTimeContext";
import getLogger from "../../util/getLogger";
import UserContext from "../../contexts/UserContext";
import { CRITICAL, OK, WARNING } from "../Announcement";

const logger = getLogger("QuestionTimer");

const QuestionTimer = () => {
  const { setAnnouncement } = useContext(UserContext);
  const { question, myHostId, closeQuestion } = useContext(QuizContext);
  const { getSecondsLeft } = useContext(ServerTimeContext);
  const [closingQuestion, setClosingQuestion] = useState(false);

  const closesAt = question.closesAt;

  const updateSecondsLeft = useCallback(() => {
    if (closesAt) {
      const secondsLeft = getSecondsLeft(closesAt);
      logger.debug("seconds left", secondsLeft);

      let text = `${secondsLeft} seconds left`;
      let level = OK;

      if (secondsLeft <= 10) {
        level = WARNING;
      }

      if (secondsLeft <= 0) {
        text = "Question closed";
        level = CRITICAL;
      }

      setAnnouncement({
        text,
        level,
      });

      if (myHostId && secondsLeft <= 0 && !closingQuestion) {
        setClosingQuestion(true);
        closeQuestion();
      }
    }
  }, [closesAt, closingQuestion]);

  useInterval(() => {
    updateSecondsLeft();
  }, 1000);

  useEffect(() => {
    return () => setAnnouncement(null);
  }, []);

  return null;
};

export default QuestionTimer;
