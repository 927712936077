import React, { useContext } from "react";
import Survey from "../Survey";
import PageHeading from "../PageHeading";
import styled from "styled-components";
import { LIGHT_MUTED_TEXT } from "../../colors";
import SessionDataContext from "../../contexts/SessionDataContext";

const Text = styled.div`
  max-width: 600px;
  color: ${LIGHT_MUTED_TEXT};
  line-height: 150%;
`;

export default ({ quiz }) => {
  const { myHostId } = useContext(SessionDataContext);
  const surveyResponses = quiz.surveyResponses || [];

  const responseCount = surveyResponses.length;
  const playerCount = quiz.players.length;

  if (!myHostId) {
    return <Survey />;
  }

  return (
    <>
      <PageHeading data-cy="page-heading">Survey Results</PageHeading>
      <Text>
        <>
          {responseCount === 0 &&
            `Waiting for first response from ${playerCount} players`}
          {responseCount > 0 &&
            `${responseCount} out of ${playerCount} players responded`}
        </>
      </Text>
    </>
  );
};
