import React, { useContext } from "react";
import FactsScreen from "../../../screens/quiz/09/FactsScreen";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import QuizContext from "../../../../contexts/QuizContext";
import useNextQuestionMutation from "../../../hooks/mutations/useNextQuestionMutation";
import useShowRoundScoresMutation from "../../../hooks/mutations/useShowRoundScoresMutation";
import getAllAudio from "../AudioController/getAllAudio";
import getHostAvatarControllerList from "../AvatarControllers/getHostAvatarControllerList";

const FactsController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const { quiz, round, question } = useContext(QuizContext);
  const [nextQuestionMutation] = useNextQuestionMutation();
  const [showRoundScores] = useShowRoundScoresMutation();

  const handleNextQuestion = async () => await nextQuestionMutation();
  const handleRoundResults = async () => await showRoundScores();

  const moreQuestions = quiz.questionNumber < round.questions.length;

  let next;
  if (moreQuestions) {
    next = "question";
  } else {
    next = "round-results";
  }

  return (
    <FactsScreen
      audio={getAllAudio()}
      isHost={Boolean(myHostId)}
      hostAvatars={getHostAvatarControllerList()}
      facts={question.facts}
      next={next}
      onNextQuestion={handleNextQuestion}
      onRoundResults={handleRoundResults}
      resetButton={resetButton}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
    />
  );
};

export default FactsController;
