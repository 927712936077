import React from "react";
import Droppable from "../../components/dnd/Droppable";
import useAssignToTeamMutation from "../../hooks/mutations/useAssignToTeamMutation";

const DropPlayerOntoTeamController = ({ team, children, ...rest }) => {
  const [assignToTeamMutation] = useAssignToTeamMutation();

  const isPlayerIdInThisTeam = (playerId) =>
    team.players.find((player) => player.id === playerId);

  return (
    <Droppable
      accept={"player"}
      canDrop={(item) => !isPlayerIdInThisTeam(item.id)}
      onDrop={async (item) => {
        await assignToTeamMutation({
          variables: {
            playerId: item.id,
            teamId: team.id,
          },
        });
      }}
      {...rest}
    >
      {children}
    </Droppable>
  );
};

export default DropPlayerOntoTeamController;
