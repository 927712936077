import React, { useContext } from "react";
import QuizContext from "../../../contexts/QuizContext";
import TeamArea from "../../TeamArea";
import { QUIZ_CONVERSATION } from "../../../constants";

export default () => {
  const { started, whatToShow, myTeamId, assistingTeamId } = useContext(
    QuizContext
  );

  if (!started) {
    return null;
  }

  if (whatToShow === QUIZ_CONVERSATION) {
    return null;
  }

  if (!myTeamId && !assistingTeamId) {
    return null;
  }

  return <TeamArea />;
};
