import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
`;

const AllTeamsAndNames = ({ teams = [] }) => {
  return <Wrapper>{teams}</Wrapper>;
};

export default AllTeamsAndNames;
