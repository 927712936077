import styled from "styled-components";
import { PARAGRAPH_LARGE_BOLD } from "../../typography";
import { WHITE } from "../../colors";

const InstructionTitle = styled.div`
  ${PARAGRAPH_LARGE_BOLD}
  color: ${WHITE};
  text-align: center;
`;

export default InstructionTitle;
