import React, { useContext } from "react";
import QuizContext from "../../../../contexts/QuizContext";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import AudioController from "./index";

const getOtherHostsAssistingThisTeam = (team) => {
  const { myHostId } = useContext(SessionDataContext);
  const { hosts } = useContext(QuizContext);

  return hosts
    .filter((host) => host.id !== myHostId)
    .filter((host) => host.assisting && host.assisting.id === team.id)
    .map((host) => <AudioController key={host.id} participantId={host.id} />);
};

export default getOtherHostsAssistingThisTeam;
