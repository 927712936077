import DangerButton from "../buttons/DangerButton";
import React, { useContext, useState } from "react";
import ResetQuizModal from "../modals/ResetQuizModal";
import QuizContext from "../../contexts/QuizContext";
import Button from "../buttons/Button";
import CreateMultipleTeamsModal from "../modals/CreateMultipleTeamsModal";
// import RandomTeamsModal from "../modals/RandomTeamsModal";

const QuizIntroActions = () => {
  const { quiz, canResetQuiz } = useContext(QuizContext);

  const [showCreateTeamsModal, setShowCreateTeamsModal] = useState(false);
  // const [showRandomTeamsModal, setShowRandomTeamsModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState();

  const handleCreateTeamsClick = () => setShowCreateTeamsModal(true);
  const handleCancelCreateTeams = () => setShowCreateTeamsModal(false);
  // const handleRandomTeamsClick = () => setShowRandomTeamsModal(true);
  // const handleCancelRandomTeams = () => setShowRandomTeamsModal(false);

  const handleResetClick = () => {
    setShowResetModal(true);
  };

  const handleCancelReset = () => {
    setShowResetModal(false);
  };

  return (
    <>
      <Button size="large" onClick={handleCreateTeamsClick}>
        Create Teams
      </Button>
      {showCreateTeamsModal && (
        <CreateMultipleTeamsModal onClose={handleCancelCreateTeams} />
      )}
      {/*<Button size="large" onClick={handleRandomTeamsClick}>*/}
      {/*  Random Teams*/}
      {/*</Button>*/}
      {/*{showRandomTeamsModal && (*/}
      {/*  <RandomTeamsModal onClose={handleCancelRandomTeams} />*/}
      {/*)}*/}
      {canResetQuiz && (
        <DangerButton size="large" onClick={handleResetClick}>
          Reset Quiz
        </DangerButton>
      )}
      {showResetModal && (
        <ResetQuizModal
          publicQuizId={quiz.publicId}
          onClose={handleCancelReset}
        />
      )}
    </>
  );
};

export default QuizIntroActions;
