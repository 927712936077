import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import getLogger from "../../util/getLogger";
import _ from "lodash";
import RtcBaseContext from "../../contexts/RtcBaseContext";
// import RtcBase from "@rtcbase/rtcbase";

const Wrapper = styled.div`
  display: none;
`;

const RemoteParticipantListener = ({
  participant,
  setParticipantsWithAudio,
  setParticipantsWithVideo,
}) => {
  const { isRtcBaseEnabled } = useContext(RtcBaseContext);
  const logger = getLogger(`RemoteParticipantListener: ${participant}`);

  useEffect(() => {
    const trackSubscribed = (track) => {
      logger.info(`Received trackSubscribed event for track ${track}`);
      logger.debug(track);
      if (track.kind === "audio") {
        setParticipantsWithAudio((participantsWithAudio) => ({
          ...participantsWithAudio,
          [participant.identity]: participant,
        }));
        logger.info("Published audio track", track);
      } else if (track.kind === "video") {
        setParticipantsWithVideo((participantsWithVideo) => ({
          ...participantsWithVideo,
          [participant.identity]: participant,
        }));
        logger.info("Published video track", track);
      }

      if (isRtcBaseEnabled) {
        // switch (track.kind) {
        //   case "data":
        //     break;
        //   default:
        //     RtcBase.identifyTrack(track.mediaStreamTrack.id, participant.sid);
        // }

        logger.info("RtcBase temporarily removed from code");
      }
    };

    const trackUnsubscribed = (track) => {
      logger.info(`Received trackUnsubscribed event for track ${track}`);
      logger.debug(track);
      if (track.kind === "audio") {
        setParticipantsWithAudio((participantsWithAudio) =>
          _.omit(participantsWithAudio, participant.identity)
        );
        logger.info("Unpublished audio track", track);
      } else if (track.kind === "video") {
        setParticipantsWithVideo((participantsWithVideo) =>
          _.omit(participantsWithVideo, participant.identity)
        );
        logger.info("Unpublished video track", track);
      }
    };

    const addListeners = () => {
      participant.on("trackSubscribed", trackSubscribed);
      participant.on("trackUnsubscribed", trackUnsubscribed);
      logger.info("Added all listeners");
    };

    const removeListeners = () => {
      participant.off("trackSubscribed", trackSubscribed);
      participant.off("trackUnsubscribed", trackUnsubscribed);
      logger.info("Removed all listeners");
    };

    addListeners();

    return () => {
      removeListeners();
    };
  }, []);

  return <Wrapper />;
};

export default RemoteParticipantListener;
