import React, { useContext, useState } from "react";
import styled from "styled-components";
import Button from "./buttons/Button";
import { DEBUG, HIDE_VIDEO, STRESS_VIDEO } from "../flags";
import Participant from "./NewParticipant";
import { useMediaQuery } from "react-responsive/src";
import { LARGE_QUERY, SMALL_QUERY, XLARGE_QUERY } from "./responsive";
import { XLARGE, LARGE, MEDIUM, SMALL } from "../dimensions";
import MainRoomContext from "../contexts/MainRoomContext";
import FeatureFlagContext from "../contexts/FeatureFlagContext";
import { LIGHT_REGULAR_TEXT } from "../colors";
// import { ANSWERS, QUESTION, ROUND_INTRO, ROUND_RESULTS } from "../constants";

const HostWrapper = styled.div`
  display: flex;
  position: relative;
`;

const HostNameAndParticipantWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const DebugWrapper = styled.div`
  font-size: 14px;
  color: ${LIGHT_REGULAR_TEXT};
`;

export default ({
  coHost,
  quiz,
  stressVideo,
  onStressVideo,
  onStopStressVideo,
}) => {
  const {
    localParticipant,
    localVideoTrack,
    remoteParticipants,
    participantsWithAudio,
    participantsWithVideo,
  } = useContext(MainRoomContext);
  const { flags } = useContext(FeatureFlagContext);

  const isXLarge = useMediaQuery(XLARGE_QUERY);
  const isLarge = useMediaQuery(LARGE_QUERY);
  const isSmall = useMediaQuery(SMALL_QUERY);

  const [hideVideo, setHideVideo] = useState(false);
  const [videoOff, setVideoOff] = useState(false);

  const handleHideVideo = () => setHideVideo(true);
  const handleShowVideo = () => setHideVideo(false);

  const host =
    quiz && quiz.hosts && quiz.hosts.find((host) => host.coHost === coHost);

  if (!host) {
    return null;
  }

  let name;
  if (coHost) {
    name = `${host.name} (co-host)`;
  } else {
    name = `${host.name} (host)`;
  }

  let size = MEDIUM;

  if (isXLarge) {
    size = XLARGE;
  } else if (isLarge) {
    size = LARGE;
  } else if (isSmall) {
    size = SMALL;
  }

  // const hide = quiz && [QUESTION].includes(quiz.whatToShow);
  const hide = false;

  let participant = null;
  if (localParticipant && localParticipant.identity === host.id) {
    participant = localParticipant;
  } else {
    participant = remoteParticipants[host.id];
  }

  return (
    <HostWrapper coHost={coHost} data-cy="host">
      <HostNameAndParticipantWrapper>
        {DEBUG && (
          <>
            {STRESS_VIDEO && !stressVideo && (
              <Button onClick={onStressVideo}>Stress Video</Button>
            )}
            {STRESS_VIDEO && stressVideo && (
              <Button onClick={onStopStressVideo}>Stop Stressing</Button>
            )}
            {/*{RESET_VIDEO_BUTTON && (*/}
            {/*  <Button onClick={handleResetVideo}>Reset Video</Button>*/}
            {/*)}*/}
            {HIDE_VIDEO && (
              <>
                {hideVideo && (
                  <Button onClick={handleShowVideo}>Show Video</Button>
                )}
                {!hideVideo && (
                  <Button onClick={handleHideVideo}>Hide Video</Button>
                )}
              </>
            )}
          </>
        )}
        <Participant
          quiz={quiz}
          hide={hide || videoOff}
          participantId={host.id}
          participant={participant}
          localVideoTrack={localVideoTrack}
          name={name}
          fake={host.videoToken === "test"}
          image={host.image}
          size={size}
          videoPriority="high"
          roomType="main"
          setVideoOff={setVideoOff}
        />
        {flags["debug"] && (
          <DebugWrapper>
            <div>
              Remote Participant count:{" "}
              {Object.values(remoteParticipants).length}
            </div>
            <div>
              Participants with audio:{" "}
              {`${Object.values(participantsWithAudio).length}`}
            </div>
            <div>
              Participants with video:{" "}
              {`${Object.values(participantsWithVideo).length}`}
            </div>
          </DebugWrapper>
        )}
      </HostNameAndParticipantWrapper>
    </HostWrapper>
  );
};
