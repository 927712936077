import React, { useContext, useState } from "react";
import Modal from "../Modal";
import DangerButton from "../buttons/DangerButton";
import OurButton from "../buttons/Button";
import QuizContext from "../../contexts/QuizContext";

export default ({ playerId, name, onClose }) => {
  const { removePlayer } = useContext(QuizContext);
  const [submitted, setSubmitted] = useState(false);

  const handleResetQuiz = async () => {
    setSubmitted(true);
    await removePlayer(playerId);
    onClose();
  };

  return (
    <Modal
      title="Remove Player"
      text={`Are you sure you want to remove ${
        name || "the unnamed player"
      } from the quiz?`}
      buttons={
        <>
          <DangerButton
            size="large"
            onClick={handleResetQuiz}
            disabled={submitted}
            data-cy="modal-remove-button"
          >
            {submitted ? "Removing" : "Remove"}
          </DangerButton>
          <OurButton size="large" onClick={onClose}>
            Cancel
          </OurButton>
        </>
      }
    />
  );
};
