import React from "react";
import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const EmojiWrapper = styled.div`
  font-size: 60px;
`;

const FakeParticipantVideo = ({ image, emoji }) => {
  if (image) {
    return <Image src={`data:image/png;base64, ${image}`} />;
  }

  if (emoji) {
    return <EmojiWrapper>{emoji}</EmojiWrapper>;
  }

  return null;
};

export default FakeParticipantVideo;
