import { useEffect, useState } from "react";

const useAudioTrackPublication = (publications) => {
  const [audioTrackPublication, setAudioTrackPublication] = useState();

  useEffect(() => {
    setAudioTrackPublication(publications.find((pub) => pub.kind === "audio"));
  }, [publications]);

  return audioTrackPublication;
};

export default useAudioTrackPublication;
