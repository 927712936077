import React from "react";
import styled from "styled-components";
import PrimaryCard from "../elements/cards/PrimaryCard";
import TeamHeader from "./TeamHeader";
import AnswerRow from "./AnswerRow";
import CrowdLayout from "../layouts/CrowdLayout";
import Emoji from "../elements/emojis/Emoji";
import { ELEMENT_DEFAULT, ELEMENT_LIGHT } from "../../colors";
import LargeMainButton from "../buttons/LargeMainButton";

const Wrapper = styled(PrimaryCard)`
  position: relative;
`;

const UpperArea = styled.div`
  padding: 15px 20px;
  display: flex;
  align-items: center;
`;

const NameAndSelfiesColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;
`;

const TeamHeaderRow = styled.div`
  margin-bottom: 10px;

  ${(props) =>
    props.noBottomMargin &&
    `
    margin-bottom: 0;
  `}
`;

const AvatarColumn = styled.div``;

const DominantAvatarWrapper = styled.div`
  width: 160px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${ELEMENT_LIGHT};
  border-radius: 10px;
`;

const TeamSelfiesWrapper = styled.div`
  flex: 1;
`;

const DropTargetIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: ${ELEMENT_DEFAULT};
`;

const LateJoinColumn = styled(AvatarColumn)``;

const LateJoinButtonWrapper = styled(DominantAvatarWrapper)`
  background: none;
`;

const Team = ({
  emoji,
  teamId,
  name,
  dominantAvatar,
  teamSelfies,
  showAnswer,
  answerText,
  answerGraded,
  answerCorrect,
  inTiebreaker,
  canChangeGrade,
  onChangeGrade,
  dropTarget,
  lateJoin,
  onJoin,
}) => {
  return (
    <Wrapper data-cy={"Team"} data-cy-id={teamId}>
      <UpperArea showAnswer={showAnswer}>
        <NameAndSelfiesColumn>
          {(name || emoji) && (
            <TeamHeaderRow noBottomMargin={teamSelfies.length === 0}>
              <TeamHeader emoji={emoji} name={name} />
            </TeamHeaderRow>
          )}
          <TeamSelfiesWrapper>
            <CrowdLayout individuals={teamSelfies} />
          </TeamSelfiesWrapper>
        </NameAndSelfiesColumn>
        {!lateJoin && (
          <AvatarColumn>
            <DominantAvatarWrapper>
              {dominantAvatar || <Emoji emoji={emoji} size={"xlarge"} />}
            </DominantAvatarWrapper>
          </AvatarColumn>
        )}
        {lateJoin && (
          <LateJoinColumn>
            <LateJoinButtonWrapper>
              <LargeMainButton onClick={onJoin}>Join</LargeMainButton>
            </LateJoinButtonWrapper>
          </LateJoinColumn>
        )}
      </UpperArea>
      {showAnswer && (
        <AnswerRow
          inTiebreaker={inTiebreaker}
          text={answerText}
          graded={answerGraded}
          correct={answerGraded ? answerCorrect : undefined}
          canChangeGrade={canChangeGrade}
          onChangeGrade={onChangeGrade}
        />
      )}
      {dropTarget && <DropTargetIndicator />}
    </Wrapper>
  );
};

export default Team;
