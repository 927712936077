import React, { useState } from "react";
import Modal from "../components/modals/BaseModal";
import ButtonRow from "../components/buttons/ButtonRow";
import LargeOutlineButton from "../components/buttons/LargeOutlineButton";
import SliderInput from "../components/inputs/SliderInput";
import LargeMainButton from "../components/buttons/LargeMainButton";
import styled from "styled-components";
import { PARAGRAPH_LARGE_BOLD, PARAGRAPH_NORMAL } from "../typography";
import { TEXT_DEFAULT, TEXT_LIGHT } from "../colors";

const MAX_TEAMS = 6;
const MAX_TEAM_SIZE = 8;

const TextLine = styled.div`
  ${PARAGRAPH_NORMAL}
  color: ${TEXT_LIGHT};
  text-align: center;
  margin-bottom: 5px;
`;

const LargeTextLine = styled.div`
  ${PARAGRAPH_LARGE_BOLD}
  color: ${TEXT_DEFAULT};
  font-weight: normal;
  text-align: center;
  margin-bottom: 10px;
`;

const SplitIntoTeamsModal = ({ numOfPlayers, onSplitIntoTeams, onCancel }) => {
  const [numTeams, setNumTeams] = useState(3);

  const minTeamSize = Math.floor(numOfPlayers / numTeams);
  const maxTeamSize = Math.ceil(numOfPlayers / numTeams);

  const tooMany = maxTeamSize > MAX_TEAM_SIZE;

  const handleSplitClick = () => {
    onSplitIntoTeams(numTeams);
  };

  return (
    <Modal
      title="Split Into Teams"
      text={
        <>
          <LargeTextLine>
            {numTeams} team{numTeams === 1 ? "" : "s"}
          </LargeTextLine>
          {numOfPlayers > 0 && (
            <>
              <TextLine>
                ({minTeamSize}
                {maxTeamSize > minTeamSize && ` - ${maxTeamSize}`}{" "}
                {`player${minTeamSize === 1 && maxTeamSize === 1 ? "" : "s"}`} /
                team)
              </TextLine>
            </>
          )}

          {numOfPlayers === 0 && <TextLine>0 players / team</TextLine>}
        </>
      }
      body={
        <SliderInput
          value={numTeams}
          minValue={1}
          maxValue={MAX_TEAMS}
          onChange={setNumTeams}
        />
      }
      buttons={
        <ButtonRow>
          <LargeMainButton
            data-cy-name={"Split"}
            onClick={handleSplitClick}
            disabled={tooMany}
          >
            Split
          </LargeMainButton>
          <LargeOutlineButton noSpinner onClick={onCancel}>
            Cancel
          </LargeOutlineButton>
        </ButtonRow>
      }
      onEscapeKey={onCancel}
    />
  );
};

export default SplitIntoTeamsModal;
