import { useEffect } from "react";
import getLogger from "../../../util/getLogger";

const logger = getLogger("useAttachAudioTrackEffect");

const useAttachAudioTrackEffect = (track, el) => {
  useEffect(() => {
    logger.debug("track", track);
    logger.debug("el", el);
    if (track && el) {
      track.attach(el);
      logger.debug("attached track", track, el);

      return () => {
        track.detach(el);
        logger.debug("detached track", track, el);
        el.srcObject = null;
      };
    }
  }, [track, el]);
};

export default useAttachAudioTrackEffect;
