import { gql, useQuery } from "@apollo/client";

const GET_CURRENT_EVENT_FOR_SURVEY = gql`
  query GetCurrentEvent {
    event: getCurrentEvent {
      id
      name
      eventPlayers {
        id
      }
    }
  }
`;

const useGetCurrentEventForSurveyQuery = () =>
  useQuery(GET_CURRENT_EVENT_FOR_SURVEY);

export default useGetCurrentEventForSurveyQuery;
