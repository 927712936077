import React, { useContext } from "react";
import styled from "styled-components";
import Emoji from "../../../../Emoji";
import LinkButton from "../../../../buttons/LinkButton";
import QuizContext from "../../../../../contexts/QuizContext";

const Wrapper = styled.div`
  margin-right: 10px;
  position: relative;
  flex: 0;
`;

const EmojiWrapper = styled.div`
  ${({ editing }) =>
    editing &&
    `
    opacity: 0;
  `}
  ${Wrapper}:hover & {
    opacity: 0;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  display: none;
  top: 10px;
  ${({ editing }) =>
    editing &&
    `
    display: block;
  `}

  ${Wrapper}:hover & {
    display: block;
  }
`;

const TeamEmoji = ({ team, editing, setEditing }) => {
  const { myHostId } = useContext(QuizContext);

  const handleEditClick = () => setEditing(true);
  const handleDoneClick = () => setEditing(false);

  if (!team.emoji) {
    return null;
  }

  if (myHostId) {
    return (
      <Wrapper>
        <EmojiWrapper editing={editing}>
          <Emoji
            emoji={team.emoji}
            data-tip
            data-for={`name-team-${team.id}`}
          />
        </EmojiWrapper>
        <ButtonWrapper editing={editing}>
          {!editing && <LinkButton onClick={handleEditClick}>Edit</LinkButton>}
          {editing && <LinkButton onClick={handleDoneClick}>Done</LinkButton>}
        </ButtonWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Emoji emoji={team.emoji} data-tip data-for={`name-team-${team.id}`} />
    </Wrapper>
  );
};

export default TeamEmoji;
