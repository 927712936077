import React from "react";
import styled from "styled-components";
import Emoji from "../elements/emojis/Emoji";
import {
  PARAGRAPH_LARGE_BOLD,
  PARAGRAPH_NORMAL_BOLD,
  PARAGRAPH_SUBHEADING_BOLD,
} from "../../typography";
import { ELEMENT_OFF_WHITE_LIGHT } from "../../colors";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.centered &&
    `
    justify-content: center;
  `}
`;

const EmojiWrapper = styled.div`
  margin-right: 7px;
`;

const Name = styled.div`
  ${PARAGRAPH_NORMAL_BOLD}
  color: ${ELEMENT_OFF_WHITE_LIGHT};
  ${(props) =>
    props.size === "small" &&
    `
    ${PARAGRAPH_SUBHEADING_BOLD}
  `}
  ${(props) =>
    props.size === "large" &&
    `
    ${PARAGRAPH_LARGE_BOLD}
    font-size: 20px;  // why is this necessary???
  `}
`;

const TeamHeader = ({ emoji, name, size, centered, ...rest }) => (
  <Wrapper centered={centered} {...rest}>
    <EmojiWrapper>
      <Emoji emoji={emoji} size={size} />
    </EmojiWrapper>
    <Name size={size}>{name}</Name>
  </Wrapper>
);

export default TeamHeader;
