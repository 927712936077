import React from "react";
import ContentBox from "../ContentBox";
import styled from "styled-components";
import { MUTED_TEXT } from "../../colors";
import DarkPageHeading from "../DarkPageHeading";

const Text = styled.div`
  max-width: 600px;
  color: ${MUTED_TEXT};
  line-height: 150%;
`;

export default () => {
  return (
    <ContentBox direction="column">
      <DarkPageHeading>Thank You!</DarkPageHeading>
      <Text>
        We are doing our best to improve and your feedback is vital to that, so
        we really appreciate your response.
      </Text>
    </ContentBox>
  );
};
