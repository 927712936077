export const XLARGE = "XLARGE";
export const LARGE = "LARGE";
export const MEDIUM = "MEDIUM";
export const SMALL = "SMALL";

export const XLARGE_WIDTH = 240;
export const XLARGE_HEIGHT = 180;

export const LARGE_WIDTH = 180;
export const LARGE_HEIGHT = 135;

export const MEDIUM_WIDTH = 160;
export const MEDIUM_HEIGHT = 120;

export const SMALL_WIDTH = 120;
export const SMALL_HEIGHT = 90;
