import React, { useContext, useState } from "react";
import MainRoomContext from "../../../contexts/MainRoomContext";
import useCallbackRef from "../../../hooks/useCallbackRef";
import useAttachVideoTrackEffect from "../../../hooks/av/useAttachVideoTrackEffect";
import useIsTrackEnabled from "../../../hooks/av/useIsTrackEnabled";
import QuizContext from "../../../../contexts/QuizContext";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import Avatar from "../../../components/avatars/Avatar";
import Video from "../../../components/av/Video";
import useIsTrackStarted from "../../../hooks/av/useIsTrackStarted";
import { QUIZ_CONVERSATION } from "../../../constants";
import SettingsContext from "../../../contexts/SettingsContext";
import useVideoTrackDimensions from "../../../hooks/av/useVideoTrackDimensions";

const LocalAvatarController = () => {
  const { localVideoTrack, muted, dominantSpeaker } = useContext(
    MainRoomContext
  );
  const { myHost, myPlayer, whatToShow } = useContext(QuizContext);
  const { myHostId, myPlayerId } = useContext(SessionDataContext);
  const { debugAudioVideo } = useContext(SettingsContext);

  const [videoEl, setVideoEl] = useState();
  const videoRef = useCallbackRef(setVideoEl);

  useAttachVideoTrackEffect(localVideoTrack, videoEl);

  const videoDimensions = useVideoTrackDimensions(localVideoTrack);

  const videoIsEnabled = useIsTrackEnabled(localVideoTrack);
  const videoTrackStarted = useIsTrackStarted(localVideoTrack);

  const myId = myHostId || myPlayerId;
  const myName = (myHost && myHost.name) || (myPlayer && myPlayer.name);

  const hideLeader = whatToShow === QUIZ_CONVERSATION;

  const leader = myPlayer && myPlayer.leader && !hideLeader;

  // It doesn't seem like the dominantSpeaker api ever returns the local
  // speaker, need to verify

  return (
    <Avatar
      key={"local"}
      name={myName}
      host={Boolean(myHostId)}
      leader={leader}
      videoOff={!videoIsEnabled || !videoTrackStarted}
      muted={muted}
      talking={dominantSpeaker && dominantSpeaker.identity === myId}
      debugData={
        debugAudioVideo && (
          <div>
            <div>
              Dimensions:{" "}
              {videoDimensions
                ? `${videoDimensions.width}x${videoDimensions.height}`
                : "unknown"}
            </div>
          </div>
        )
      }
    >
      <Video videoRef={videoRef} reversed />
    </Avatar>
  );
};

export default LocalAvatarController;
