import React, { useContext } from "react";
import styled from "styled-components";
import {
  TIEBREAKER,
  TIEBREAKER_ANSWERS,
  TIEBREAKER_RESULTS,
} from "../../constants";
import TeamLeaderInput from "./TeamLeaderInput";
import TeamAnswerView from "./TeamAnswerView";
import AnswerWrapper from "./AnswerWrapper";
import AnswerTextWrapper from "./AnswerTextWrapper";
import TiebreakerTimer from "./TiebreakerTimer";
import JoiningMainRoomTimer from "./JoiningMainRoomTimer";
import QuizContext from "../../contexts/QuizContext";
import { DARK_GREY_4, LIGHT_GREY_2 } from "../../colors";

// const logger = getLogger("Question");

const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QuestionSignPost = styled.div`
  font-size: 14px;
  color: ${DARK_GREY_4};
  margin-bottom: 10px;
`;

const QuestionBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${LIGHT_GREY_2};
  padding: 20px 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  min-height: 135px;
`;

const QuestionText = styled.div`
  font-size: 20px;
  line-height: 1.5;
`;

export default () => {
  const {
    whatToShow,
    tiebreaker,
    myPlayer,
    myHostId,
    myTeamInFirstPlace,
    myTeamTiebreaker,
  } = useContext(QuizContext);

  const hosting = !!myHostId;

  return (
    <QuestionWrapper>
      <QuestionBox>
        <QuestionSignPost>Tiebreaker</QuestionSignPost>
        <QuestionText>{tiebreaker.text}</QuestionText>
        {whatToShow === TIEBREAKER &&
          myTeamInFirstPlace &&
          !myTeamTiebreaker &&
          !hosting && (
            <>
              {myPlayer.leader && <TeamLeaderInput />}
              {!myPlayer.leader && <TeamAnswerView />}
            </>
          )}
        {([TIEBREAKER_RESULTS].includes(whatToShow) || hosting) && (
          <>
            <AnswerWrapper>
              <span>Answer: </span>
              <AnswerTextWrapper>{tiebreaker.answer}</AnswerTextWrapper>
            </AnswerWrapper>
          </>
        )}
      </QuestionBox>
      {whatToShow === TIEBREAKER && <TiebreakerTimer />}
      {whatToShow === TIEBREAKER_ANSWERS && <JoiningMainRoomTimer />}
    </QuestionWrapper>
  );
};
