import React, { useContext, useEffect, useState } from "react";
import QuizConversationScreen from "../../../screens/quiz/15/QuizConversationScreen";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import QuizContext from "../../../../contexts/QuizContext";
import getAvatarControllerList from "../AvatarControllers/getAvatarControllerList";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import getAllAudio from "../AudioController/getAllAudio";
import useEndQuizMutation from "../../../hooks/mutations/useEndQuizMutation";
import { QUIZ_CONVERSATION } from "../../../constants";
import GalleryPageController, {
  useGalleryPageCount,
  useGalleryParticipantCount,
} from "../GalleryPageController";
import useCountdownTimer from "../../../hooks/useCountdownTimer";

const QuizConversationController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const { quizName, otherEventQuizzes, eventQuizzesCloseAt } = useContext(
    QuizContext
  );
  const [pageNum, setPageNum] = useState(1);
  const pageCount = useGalleryPageCount();
  const [endQuizMutation] = useEndQuizMutation();

  const handleEndQuiz = async () => await endQuizMutation();

  const count = useGalleryParticipantCount();

  const inEvent = Boolean(otherEventQuizzes);

  const completedEventQuizzes =
    otherEventQuizzes &&
    otherEventQuizzes.filter((quiz) => quiz.whatToShow === QUIZ_CONVERSATION);
  const numCompletedEventQuizzes =
    completedEventQuizzes && completedEventQuizzes.length;

  const numOtherEventQuizzes = otherEventQuizzes && otherEventQuizzes.length;

  useEffect(() => {
    if (pageNum > pageCount) {
      setPageNum(pageCount);
    }
  }, [pageNum, pageCount]);

  const timeLeftBeforeQuizzesClose = useCountdownTimer(eventQuizzesCloseAt);

  return (
    <QuizConversationScreen
      audio={getAllAudio()}
      isHost={Boolean(myHostId)}
      inEvent={inEvent}
      numCompletedEventQuizzes={numCompletedEventQuizzes}
      numOtherEventQuizzes={numOtherEventQuizzes}
      timeLeftBeforeQuizzesClose={timeLeftBeforeQuizzesClose}
      quizName={quizName}
      count={count}
      pageNum={pageNum}
      pageCount={pageCount}
      galleryPage={<GalleryPageController pageNum={pageNum} />}
      onPrevPage={() => setPageNum((prevPageNum) => prevPageNum - 1)}
      onNextPage={() => setPageNum((prevPageNum) => prevPageNum + 1)}
      onSetPage={(newPageNum) => setPageNum(newPageNum)}
      avatars={getAvatarControllerList()}
      onEndQuiz={handleEndQuiz}
      resetButton={resetButton}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
    />
  );
};

export default QuizConversationController;
