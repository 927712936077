import React from "react";
import {
  Wrapper,
  Title,
  InstructionList,
  Instruction,
  InstructionHeading,
  SubInstructionList,
  SubInstruction,
  SubInstructionHeading,
} from "./sharedComponents";
// import QuizContext from "../../contexts/QuizContext";

export default () => {
  // const { quiz } = useContext(QuizContext);

  // const numRounds = quiz.numRounds;
  // const numQuestions = quiz.rounds[0].numQuestions;

  return (
    <Wrapper>
      <Title>Host Notes</Title>
      <InstructionList>
        {/*<Instruction>*/}
        {/*  <InstructionHeading>*/}
        {/*    The quiz is {numRounds} round{numRounds > 1 && "s"} with{" "}*/}
        {/*    {numQuestions} question{numQuestions > 1 && "s"}{" "}*/}
        {/*    {numRounds > 1 && "each"}*/}
        {/*  </InstructionHeading>*/}
        {/*</Instruction>*/}
        <Instruction>
          <InstructionHeading>
            You have 60 seconds to answer each question.
          </InstructionHeading>
        </Instruction>
        <Instruction>
          <InstructionHeading>
            As a reminder, team rooms are private but the chat is available to
            everyone at all times, so no answers in the chat.
          </InstructionHeading>
        </Instruction>
        <Instruction>
          <InstructionHeading>No Googling, obviously.</InstructionHeading>
          <SubInstructionList type="a">
            <SubInstruction>
              <SubInstructionHeading>
                If you are here to cheat at trivia, aim higher in life!
              </SubInstructionHeading>
            </SubInstruction>
          </SubInstructionList>
        </Instruction>
      </InstructionList>
    </Wrapper>
  );
};
