import styled from "styled-components";
import BaseButton from "./BaseButton";
import { HIGHLIGHT_PRIMARY, HIGHLIGHT_PRIMARY_LIGHT } from "../../colors";

const MainButton = styled(BaseButton)`
  background-color: ${HIGHLIGHT_PRIMARY};

  &:hover:enabled,
  &.hover {
    background-color: ${HIGHLIGHT_PRIMARY_LIGHT};
  }

  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${HIGHLIGHT_PRIMARY};
  `}
`;

export default MainButton;
