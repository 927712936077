import QuizActionButton from "../buttons/QuizActionButton";
import DangerButton from "../buttons/DangerButton";
import React, { useContext, useState } from "react";
import ResetQuizModal from "../modals/ResetQuizModal";
import QuizContext from "../../contexts/QuizContext";
import _ from "lodash";

export default () => {
  const { quiz, showTotalScores, canResetQuiz } = useContext(QuizContext);

  if (!quiz) {
    return null;
  }

  const round = _.get(quiz, ["rounds", quiz.roundNumber - 1]);

  const moreRounds = quiz.roundNumber < quiz.rounds.length;
  const moreQuestions = quiz.questionNumber < round.questions.length;

  const [showResetModal, setShowResetModal] = useState();

  const handleResetClick = () => {
    setShowResetModal(true);
  };

  const handleCancelReset = () => {
    setShowResetModal(false);
  };

  return (
    <>
      {!moreQuestions && moreRounds && (
        <QuizActionButton
          size="large"
          label="Show Total Scores"
          clickedLabel="Showing Total Scores"
          onClick={showTotalScores}
        />
      )}
      {!moreQuestions && !moreRounds && (
        <QuizActionButton
          size="large"
          label="Show Final Scores"
          clickedLabel="Showing Final Scores"
          onClick={showTotalScores}
        />
      )}

      {canResetQuiz && (
        <DangerButton size="large" onClick={handleResetClick}>
          Reset Quiz
        </DangerButton>
      )}
      {showResetModal && (
        <ResetQuizModal
          publicQuizId={quiz.publicId}
          onClose={handleCancelReset}
        />
      )}
    </>
  );
};
