import React from "react";
import styled from "styled-components";
import Input from "../../Input";
import {
  DARK_OVERLAY_2,
  DARK_OVERLAY_3,
  LIGHT_MUTED_TEXT,
  LIGHT_REGULAR_TEXT,
} from "../../../colors";

const NarrowInput = styled(Input)`
  min-width: auto;
  font-size: 16px;
  line-height: 1.5em;
  width: 100%;
  border-radius: 40px;
  padding: 11px 50px 11px 20px;
  background-color: ${DARK_OVERLAY_2};
  color: ${LIGHT_REGULAR_TEXT};

  &::placeholder {
    font-weight: normal;
    color: ${LIGHT_MUTED_TEXT};
  }

  :focus {
    box-shadow: none;
    background-color: ${DARK_OVERLAY_3};
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
  `}
`;

const TextInput = ({ value, canSend, sending, onSend, onChange, onFocus }) => {
  const handleKeyDown = async (e) => {
    if (e.keyCode === 13 && canSend) {
      onSend();
    }
  };

  return (
    <NarrowInput
      value={value}
      disabled={sending}
      onKeyDown={handleKeyDown}
      onChange={onChange}
      onFocus={onFocus}
      placeholder="Send to all"
    />
  );
};

export default TextInput;
