import React, { useContext } from "react";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import LocalAvatarController from "./LocalAvatarController";
import RemoteAvatarController from "./RemoteAvatarController";

const AvatarController = ({ host, player }) => {
  const { myHostId, myPlayerId } = useContext(SessionDataContext);

  const key = myHostId || myPlayerId;

  if ((host && host.id === myHostId) || (player && player.id === myPlayerId)) {
    return <LocalAvatarController key={"local"} host={host} player={player} />;
  } else {
    return <RemoteAvatarController key={key} host={host} player={player} />;
  }
};

export default AvatarController;
