import React, { useContext } from "react";
import styled from "styled-components";
import QuizContext from "../../contexts/QuizContext";
import Emoji from "../Emoji";
import VideoColumn from "../VideoColumn";
import { DEBUG } from "../../flags";
import TeamRoomContext from "../../contexts/TeamRoomContext";
import { useMediaQuery } from "react-responsive/src";
import { LARGE_QUERY, SMALL_QUERY, XLARGE_QUERY } from "../responsive";
import {
  LARGE,
  LARGE_WIDTH,
  MEDIUM,
  SMALL,
  SMALL_WIDTH,
  XLARGE,
  XLARGE_WIDTH,
} from "../../dimensions";
import TeamRoomAudio from "../TeamRoomAudio";
import { LIGHT_MUTED_TEXT, LIGHT_REGULAR_TEXT } from "../../colors";

const Wrapper = styled.div`
  padding: 0 20px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-grow: 0;
  max-width: 120px;

  ${({ size }) =>
    size === XLARGE &&
    `
    max-width: ${XLARGE_WIDTH}px;
  `}
  ${({ size }) =>
    size === LARGE &&
    `
    max-width: ${LARGE_WIDTH}px;
  `}
  ${({ size }) =>
    size === SMALL &&
    `
    max-width: ${SMALL_WIDTH}px;
  `}

`;

const VideoColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 0;
  flex: 1 1 auto;
  overflow: auto;
  height: 1px;
  width: 100%;
`;

const Scroller = styled.div`
  height: 100%;
  overflow: scroll;
  width: 100%;
`;

const EmojiWrapper = styled.div`
  margin-right: 10px;
`;

const TeamName = styled.div`
  color: ${LIGHT_REGULAR_TEXT};
  ${({ muted }) =>
    muted &&
    `
    color: ${LIGHT_MUTED_TEXT};
  `}
`;

const DebugWrapper = styled.div`
  color: ${LIGHT_REGULAR_TEXT};
  font-size: 14px;
`;

export default () => {
  const {
    quiz,
    inTeamRoom,
    myTeamId,
    assistingTeamId,
    // assistants,
  } = useContext(QuizContext);
  const { roomName, remoteParticipants } = useContext(TeamRoomContext);

  const isXLarge = useMediaQuery(XLARGE_QUERY);
  const isLarge = useMediaQuery(LARGE_QUERY);
  const isSmall = useMediaQuery(SMALL_QUERY);

  const teamId = myTeamId || assistingTeamId;

  if (!teamId) {
    return null;
  }

  let size = MEDIUM;
  if (isXLarge) {
    size = XLARGE;
  } else if (isLarge) {
    size = LARGE;
  } else if (isSmall) {
    size = SMALL;
  }

  const myTeam = quiz.teams.find((team) => team.id === teamId);

  if (!myTeam) {
    return null;
  }

  if (!inTeamRoom) {
    return null;
  }

  return (
    <Wrapper>
      {/*{assistants.length > 0 && (*/}
      {/*  <VideoColumn players={assistants} size={size} />*/}
      {/*)}*/}

      <Heading size={size}>
        {myTeam.emoji && (
          <EmojiWrapper>
            <Emoji
              size="small"
              emoji={myTeam.emoji}
              data-tip
              data-for={`name-team-${myTeam.id}`}
            />
          </EmojiWrapper>
        )}

        <TeamName muted={!myTeam.name}>
          {myTeam.name || "unnamed team"}
        </TeamName>
      </Heading>
      <VideoColumnWrapper>
        <Scroller>
          <VideoColumn players={myTeam.players} size={size} />
        </Scroller>
      </VideoColumnWrapper>
      <TeamRoomAudio />
      {DEBUG && (
        <DebugWrapper>
          <div style={{ fontSize: 14 }}>Room: {roomName}</div>
          <div style={{ fontSize: 14 }}>
            Remote Participant count: {Object.values(remoteParticipants).length}
          </div>
          {/*<div style={{ fontSize: 14 }}>Remote Participants:</div>*/}
          {/*{Object.values(remoteParticipants).map((participant) => (*/}
          {/*  <div key={participant.identity} style={{ fontSize: 12 }}>*/}
          {/*    {participant.identity}*/}
          {/*  </div>*/}
          {/*))}*/}
        </DebugWrapper>
      )}
    </Wrapper>
  );
};
