import React, { useContext, useEffect, useState } from "react";
import Video, {
  createLocalAudioTrack,
  createLocalVideoTrack,
} from "twilio-video";
import QuizContext from "./QuizContext";
import SessionDataContext from "./SessionDataContext";
import getLogger from "../util/getLogger";
import _ from "lodash";
import getTimeStamp from "../util/getTimeStamp";
import DeviceContext from "./DeviceContext";
import Room from "../components/Room";
import {
  defaultConnectOptions,
  defaultAudioOptions,
  defaultVideoOptions,
} from "../twilioOptions";
import RtcBaseContext from "./RtcBaseContext";
import inTesting from "../util/inTesting";
// import RtcBase from "@rtcbase/rtcbase";

export default (RoomContext, contextName) => {
  return ({ roomName, token, children }) => {
    const logger = getLogger(`${contextName}Context: ${roomName}`);

    const { isRtcBaseEnabled } = useContext(RtcBaseContext);

    let hasUserIdContext =
      useContext(QuizContext) || useContext(SessionDataContext);
    const myHostId =
      hasUserIdContext.myHostId || hasUserIdContext.myEventHostId;
    const myPlayerId = hasUserIdContext.myPlayerId || hasUserIdContext.myHostId;

    const { audioDeviceId, videoDeviceId } = useContext(DeviceContext);

    const [room, setRoom] = useState(null);
    const [connectedAt, setConnectedAt] = useState(null);
    const [reconnectedAt, setReconnectedAt] = useState(null);
    const [participants, setParticipants] = useState({});
    const [localAudioTrack, setLocalAudioTrack] = useState(null);
    const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const [localSpeaking, setLocalSpeaking] = useState(false);
    const [participantsWithAudio, setParticipantsWithAudio] = useState({});
    const [participantsWithVideo, setParticipantsWithVideo] = useState({});
    const [dominantSpeaker, setDominantSpeaker] = useState(null);
    const [muted, setMuted] = useState(false);
    const [videoStopped, setVideoStopped] = useState(false);
    const [portalNodes, setPortalNodes] = useState({});
    const [visiblePlayers, setVisiblePlayers] = useState([]);

    useEffect(() => {
      logger.debug("Mounting");

      return () => logger.debug("Unmounting");
    }, []);

    useEffect(() => {
      logger.debug("in main effect");

      if (!roomName) {
        logger.debug("roomName is not set");
        return;
      }

      if (!token) {
        logger.debug("token is not set");
        return;
      }

      if (token === "test") {
        logger.debug(
          "We are running in test mode, so skipping any connection attempts to twilio"
        );
        return;
      }

      const initLocalTracks = async () => {
        const myId = myHostId || myPlayerId;

        const audioOptions = {
          ...defaultAudioOptions,
          name: `${contextName}-audio-${myId}`,
        };
        if (audioDeviceId) {
          audioOptions.deviceId = { exact: audioDeviceId };
        }

        logger.debug("createLocalAudioTrack options", audioOptions);

        const audioTrack = await createLocalAudioTrack(audioOptions);

        setLocalAudioTrack(audioTrack);
        logger.info("Created local audio track");

        const videoOptions = {
          ...defaultVideoOptions,
          name: `${contextName}-video-${myId}`,
        };
        if (videoDeviceId) {
          videoOptions.deviceId = { exact: videoDeviceId };
        }

        logger.debug("createLocalVideoTrack options", videoOptions);

        const videoTrack = await createLocalVideoTrack(videoOptions);
        setLocalVideoTrack(videoTrack);
        logger.info("Created local video track");

        return [audioTrack, videoTrack];
      };

      const connect = async () => {
        logger.info(`Connecting to room ${roomName} with token ${token}`);

        const connectOptions = {
          ...defaultConnectOptions,
          name: roomName,
        };

        // if (
        //   contextName === "TeamRoom" ||
        //   (contextName === "MainRoom" && myHostId)
        // ) {
        //   connectOptions.tracks = await initLocalTracks();
        //   setMuted(false);
        //   setVideoStopped(false);
        // } else {
        //   await initLocalTracks();
        // }

        await initLocalTracks();

        logger.debug("Connect options", connectOptions);

        const newRoom = await Video.connect(token, connectOptions);

        logger.info(
          `Successfully connected to room ${roomName}`,
          connectOptions
        );
        logger.debug(`Room SID: ${newRoom.sid}`, newRoom);
        setRoom(newRoom);
        setConnectedAt(getTimeStamp());

        if (isRtcBaseEnabled) {
          // RtcBase.identify(newRoom.localParticipant.sid, {
          //   userId: newRoom.localParticipant.sid,
          //   conferenceId: newRoom.sid,
          // });
          logger.info("RtcBase temporarily removed from code");
        }
      };

      if (!inTesting()) {
        connect();
      }

      return () => {
        setRoom(null);
        logger.debug("returning from main effect after setting room to null");
      };
    }, [roomName, token, reconnectedAt]);

    const disconnect = () => {
      setRoom(null);
      logger.debug("explicitly set room to null");
    };

    const addPortalNode = (id, portalNode) => {
      logger.info(`Adding portal node with id ${id}`);
      setPortalNodes({ ...portalNodes, [id]: portalNode });
    };

    const removePortalNode = (id) => {
      logger.info(`Removing portal node with id ${id}`);
      setPortalNodes(_.omit(portalNodes, id));
    };

    const addVisiblePlayer = (id) => {
      logger.info(`Adding visible player with id ${id}`);
      setVisiblePlayers([...visiblePlayers, id]);
    };

    const removeVisiblePlayer = (id) => {
      logger.info(`Removing visible player with id ${id}`);
      setVisiblePlayers(visiblePlayers.filter((playerId) => playerId !== id));
    };

    const handleReconnect = () => {
      setReconnectedAt(getTimeStamp());
    };

    useEffect(() => {});

    return (
      <RoomContext.Provider
        value={{
          roomName,
          connectedAt,
          participants,
          localParticipant: room && room.localParticipant,
          localAudioTrack,
          localVideoTrack,
          localSpeaking,
          remoteParticipants: participants,
          participantsWithAudio,
          participantsWithVideo,
          dominantSpeaker,
          muted,
          videoStopped,
          setMuted,
          setVideoStopped,
          portalNodes,
          visiblePlayers,
          addPortalNode: addPortalNode,
          removePortalNode: removePortalNode,
          addVisiblePlayer: addVisiblePlayer,
          removeVisiblePlayer: removeVisiblePlayer,
          disconnect,
          reconnect: handleReconnect,
        }}
      >
        <>
          {room && (
            <Room
              contextName={contextName}
              roomName={roomName}
              room={room}
              participants={participants}
              setParticipants={setParticipants}
              setParticipantsWithAudio={setParticipantsWithAudio}
              setParticipantsWithVideo={setParticipantsWithVideo}
              setDominantSpeaker={setDominantSpeaker}
              localAudioTrack={localAudioTrack}
              localVideoTrack={localVideoTrack}
              muted={muted}
              setMuted={setMuted}
              setLocalSpeaking={setLocalSpeaking}
              videoStopped={videoStopped}
              setVideoStopped={setVideoStopped}
            />
          )}
          {children}
        </>
      </RoomContext.Provider>
    );
  };
};
