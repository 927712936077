import { useEffect, useState } from "react";

const useVideoTrackDimensions = (videoTrack) => {
  const [dimensions, setDimensions] = useState();

  useEffect(() => {
    if (videoTrack) {
      const handleDimensionsChanged = () => {
        setDimensions(videoTrack.dimensions);
      };

      videoTrack.on("dimensionsChanged", handleDimensionsChanged);

      handleDimensionsChanged();

      return () => {
        videoTrack.off("dimensionsChanged", handleDimensionsChanged);
      };
    }
  }, [videoTrack]);

  return dimensions;
};

export default useVideoTrackDimensions;
