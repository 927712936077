import React, { useEffect } from "react";
import Header from "./Header";
import UpperSection from "./UpperSection";
import LowerSection from "./LowerSection";
import ActionSection from "./ActionSection";
import styled from "styled-components";
import TeamSection from "./TeamSection";
import getLogger from "../../util/getLogger";
import ChatSection from "./ChatSection";
import Announcement from "../Announcement";
import Background from "../Background";
import ProgressSection from "./ProgressSection";

const logger = getLogger("QuizLayout");

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const NeedsName = styled.div`
  display: flex;
  max-width: 100%;
  flex: 1;
`;

const Payload = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: scroll;
  position: relative;
`;

export default () => {
  useEffect(() => {
    logger.debug("mounting");

    return () => logger.debug("unmounting");
  }, []);

  return (
    <Wrapper>
      <Background />
      <Header />
      <NeedsName>
        <Payload>
          <Announcement />
          <ProgressSection />
          <UpperSection />
          <LowerSection />
        </Payload>
        <TeamSection />
        <ChatSection />
      </NeedsName>
      <ActionSection />
    </Wrapper>
  );
};
