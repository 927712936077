import React, { useContext } from "react";
import MeetTeamsScreen from "../../../screens/quiz/04/MeetTeamsScreen";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import QuizContext from "../../../../contexts/QuizContext";
import useNextRoundMutation from "../../../hooks/mutations/useNextRoundMutation";
import TeamController from "../TeamController";
import getAllAudio from "../AudioController/getAllAudio";
import getHostAvatarControllerList from "../AvatarControllers/getHostAvatarControllerList";

const MeetTeamsController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const { quizName, teams } = useContext(QuizContext);
  const [nextRoundMutation] = useNextRoundMutation();

  const handleFirstRound = async () => {
    await nextRoundMutation();
  };

  return (
    <MeetTeamsScreen
      audio={getAllAudio()}
      isHost={Boolean(myHostId)}
      hostAvatars={getHostAvatarControllerList()}
      quizName={quizName}
      teams={
        teams &&
        teams.map((team) => <TeamController key={team.id} team={team} />)
      }
      onFirstRound={handleFirstRound}
      resetButton={resetButton}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
    />
  );
};

export default MeetTeamsController;
