import React from "react";
import _ from "lodash";
import PageHeading from "../PageHeading";

export default ({ quiz }) => {
  const round = _.get(quiz, ["rounds", quiz.roundNumber - 1]);

  return (
    <PageHeading data-cy="page-heading">
      Round {quiz.roundNumber}: {round.title}
    </PageHeading>
  );
};
