import React, { useContext } from "react";
import LateJoinScreen from "../../screens/quiz/LateJoinScreen";
import QuizContext from "../../../contexts/QuizContext";
import TeamController from "./TeamController";
import QuizNavigationController from "./QuizNavigationController";
import QuizChatController from "./QuizChatController";

const LateJoinController = () => {
  const { quizName, teams } = useContext(QuizContext);

  return (
    <LateJoinScreen
      quizName={quizName}
      teams={
        teams &&
        teams.map((team) => (
          <TeamController key={team.id} team={team} lateJoin />
        ))
      }
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
    />
  );
};

export default LateJoinController;
