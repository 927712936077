import React from "react";
import styled from "styled-components";
import PrimaryCard from "../elements/cards/PrimaryCard";
import GalleryLayout from "../layouts/GalleryLayout";
import TeamHeader from "./TeamHeader";

const StyledCard = styled(PrimaryCard)`
  height: 100%;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: ${(props) => props.showTeamRow && "min-content"} 1fr;
  box-sizing: border-box;
`;

const TeamHeaderWrapper = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const GalleryLayoutWrapper = styled.div`
  box-sizing: border-box;
`;

const TeamBreakout = ({ emoji, teamName, avatars }) => {
  return (
    <StyledCard showTeamRow={emoji && teamName} data-cy={"TeamBreakout"}>
      {emoji && teamName && (
        <TeamHeaderWrapper>
          <TeamHeader emoji={emoji} name={teamName} size={"large"} centered />
        </TeamHeaderWrapper>
      )}
      <GalleryLayoutWrapper>
        <GalleryLayout participants={avatars} aspectRatio={4 / 3} />{" "}
      </GalleryLayoutWrapper>
    </StyledCard>
  );
};

export default TeamBreakout;
