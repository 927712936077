import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import useIsTrackEnabled from "./useIsTrackEnabled";

const useParticipantsWithVideo = (room, localVideoTrack) => {
  const [participantsWithVideo, setParticipantsWithVideo] = useState({});

  const isLocalVideoTrackEnabled = useIsTrackEnabled(localVideoTrack);

  const addParticipant = useCallback((participant) => {
    setParticipantsWithVideo((currentParticipants) => ({
      ...currentParticipants,
      [participant.identity]: participant,
    }));
  }, []);

  const removeParticipant = useCallback((participant) => {
    setParticipantsWithVideo((currentParticipants) =>
      _.omit(currentParticipants, participant.identity)
    );
  }, []);

  useEffect(() => {
    if (room && isLocalVideoTrackEnabled) {
      const localParticipant = room.localParticipant;

      addParticipant(localParticipant);

      return () => {
        removeParticipant(localParticipant);
      };
    }
  }, [room, isLocalVideoTrackEnabled]);

  useEffect(() => {
    if (room) {
      const isParticipantVideoEnabled = (participant) => {
        const videoTrackPublications = participant.videoTracks;

        let enabled = false;
        videoTrackPublications.forEach((pub) => {
          if (pub.isTrackEnabled) {
            enabled = true;
          }
        });

        return enabled;
      };

      const handleTrackEnabled = (publication, participant) => {
        if (publication.kind === "video") {
          addParticipant(participant);
        }
      };

      const handleTrackDisabled = (publication, participant) => {
        if (publication.kind === "video") {
          removeParticipant(participant);
        }
      };

      const handleTrackUnpublished = (publication, participant) => {
        if (publication.kind === "video") {
          removeParticipant(participant);
        }
      };

      const handleParticipantConnected = (participant) => {
        if (isParticipantVideoEnabled(participant)) {
          addParticipant(participant);
        }
      };

      const handleParticipantDisconnected = (participant) => {
        removeParticipant(participant);
      };

      room.on("trackEnabled", handleTrackEnabled);
      room.on("trackDisabled", handleTrackDisabled);
      room.on("trackUnpublished", handleTrackUnpublished);
      room.on("participantConnected", handleParticipantConnected);
      room.on("participantDisconnected", handleParticipantDisconnected);

      room.participants.forEach((participant) => {
        if (isParticipantVideoEnabled(participant)) {
          addParticipant(participant);
        }
      });

      return () => {
        room.off("trackEnabled", handleTrackEnabled);
        room.off("trackDisabled", handleTrackDisabled);
        room.off("trackUnpublished", handleTrackUnpublished);
        room.off("participantConnected", handleParticipantConnected);
        room.off("participantDisconnected", handleParticipantDisconnected);
      };
    }
  }, [room]);

  useEffect(() => {}, [localVideoTrack]);

  return participantsWithVideo;
};

export default useParticipantsWithVideo;
