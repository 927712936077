import React, { useContext, useState } from "react";
import SurveyResults from "./SurveyResults";
import SurveyQuestions from "./SurveyQuestions";
import SurveyThankYou from "./SurveyThankYou";
import SessionDataContext from "../../contexts/SessionDataContext";
import SurveyResultsHeader from "./SurveyResultsHeader";
import { SurveyContextProvider } from "../../contexts/SurveyContext";

const Survey = () => {
  const { loaded, myHostId, myEventHostId, myEventGroupHostId } = useContext(
    SessionDataContext
  );
  const [submission, setSubmission] = useState(null);

  if (!loaded) {
    return null;
  }

  const showResults = myHostId || myEventHostId || myEventGroupHostId;

  if (showResults) {
    return (
      <SurveyContextProvider>
        <SurveyResultsHeader dark={true} />
        <SurveyResults dark={true} />
      </SurveyContextProvider>
    );
  } else if (!submission) {
    return <SurveyQuestions onSubmit={setSubmission} />;
  } else {
    return <SurveyThankYou />;
  }
};

export default Survey;
