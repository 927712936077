import React, { useContext, useState } from "react";
import ContentBox from "../ContentBox";
import { Formik } from "formik";
import Form from "../Form";
import FormField from "../FormField";
import Label from "../Label";
import FormError from "../FormError";
import ButtonRow from "../ButtonRow";
import PrimaryButton from "../buttons/PrimaryButton";
import NPSInput from "../forms/NPSInput";
import AvQualityInput from "../forms/AvQualityInput";
import Textarea from "../Textarea";
import getLogger from "../../util/getLogger";
import Bugsnag from "@bugsnag/js";
import DarkPageHeading from "../DarkPageHeading";
import useRespondToSurveyMutation from "../../hooks/mutations/useRespondToSurveyMutation";
import SessionDataContext from "../../contexts/SessionDataContext";

const logger = getLogger("SurveyQuestions");

export default ({ onSubmit }) => {
  const { myEventPlayerId } = useContext(SessionDataContext);
  const [respondToSurveyMutation] = useRespondToSurveyMutation();
  const [submitted, setSubmitted] = useState(false);

  // if (myPlayerId && !myTeamId) {
  //   return (
  //     <ContentBox direction="column">
  //       <DarkPageHeading data-cy="page-heading">Quiz has ended</DarkPageHeading>
  //     </ContentBox>
  //   );
  // }

  const validate = () => {
    return {};
  };

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    const { avQuality, npsScore, improvementAdvice } = values;

    try {
      await respondToSurveyMutation({
        variables: {
          avQuality,
          npsScore,
          improvementAdvice,
        },
      });
      setSubmitted(true);
      onSubmit({
        avQuality,
        npsScore,
      });
    } catch (error) {
      Bugsnag.notify(error);

      logger.error("Got error when trying to submit");
      logger.error(error);
      setStatus("Unknown error, try again");
      setSubmitting(false);
    }
  };

  return (
    <ContentBox direction="column">
      <DarkPageHeading data-cy="page-heading">
        End of {myEventPlayerId ? "Event" : "Quiz"} Survey
      </DarkPageHeading>

      <Formik
        initialValues={{
          avQuality: null,
          npsScore: null,
          improvementAdvice: "",
        }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          status,
          handleChange,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormField>
              <Label>
                How happy were you with the audio and video quality?
              </Label>
              <AvQualityInput
                value={values.avQuality}
                onChange={(value) => setFieldValue("avQuality", value)}
              />
            </FormField>
            <FormField>
              <Label>
                How likely are you to recommend us to someone you know?
              </Label>
              <NPSInput
                name="npsScore"
                value={values.npsScore}
                onChange={(value) => setFieldValue("npsScore", value)}
              />
            </FormField>
            <FormField>
              <Label>Can you think of anything we can do to improve?</Label>
              <Textarea
                name="improvementAdvice"
                type="text"
                value={values.improvementAdvice}
                autoComplete="off"
                autoCapitalize="none"
                autoCorrect="off"
                onChange={handleChange}
              />
              {errors.improvementAdvice && (
                <FormError>{errors.improvementAdvice}</FormError>
              )}
              {status && <FormError>{status}</FormError>}
            </FormField>
            <ButtonRow>
              <PrimaryButton
                type="submit"
                size="large"
                disabled={isSubmitting || submitted}
                data-cy="join-button"
              >
                {isSubmitting || submitted ? "Submitting" : "Submit"}
              </PrimaryButton>
            </ButtonRow>
          </Form>
        )}
      </Formik>
    </ContentBox>
  );
};
