import React, { useContext, useState } from "react";
import styled from "styled-components";
import TeamNameRow from "./TeamNameRow";
import TeamPlayersRow from "./TeamPlayersRow";
import TeamAnswerRow from "./TeamAnswerRow";
import {
  ANSWERS,
  NAME_TEAMS,
  MEET_TEAMS,
  QUESTION,
  QUESTION_RESULTS,
  QUIZ_RESULTS,
  ROUND_RESULTS,
  TIEBREAKER,
  TIEBREAKER_ANSWERS,
  TIEBREAKER_RESULTS,
} from "../../../../constants";
import QuizContext from "../../../../contexts/QuizContext";

const WrapperWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TeamInfo = ({ team }) => {
  const {
    quiz,
    myHostId,
    whatToShow,
    myTeamAnswer,
    getTeamAnswer,
    isNonTeamPlayer,
    myTeamInFirstPlace,
    myTeamTiebreaker,
  } = useContext(QuizContext);

  const [editing, setEditing] = useState(false);

  const showPlayers =
    !quiz.started ||
    isNonTeamPlayer ||
    [NAME_TEAMS, MEET_TEAMS, ROUND_RESULTS, QUIZ_RESULTS].includes(
      quiz.whatToShow
    ) ||
    editing;

  let showAnswer = [
    QUESTION,
    ANSWERS,
    QUESTION_RESULTS,
    TIEBREAKER,
    TIEBREAKER_ANSWERS,
    TIEBREAKER_RESULTS,
  ].includes(whatToShow);
  if (whatToShow === QUESTION && !myHostId && !myTeamAnswer) {
    showAnswer = false;
  }
  if (whatToShow === TIEBREAKER && myTeamInFirstPlace && !myTeamTiebreaker) {
    showAnswer = false;
  }
  if (isNonTeamPlayer) {
    showAnswer = false;
  }
  if (editing) {
    showAnswer = false;
  }

  const teamAnswer = getTeamAnswer(team);
  const correct =
    (whatToShow === QUESTION_RESULTS && teamAnswer.correct === true) ||
    (myHostId && whatToShow === ANSWERS && teamAnswer.correct === true);
  const incorrect =
    (whatToShow === QUESTION_RESULTS && teamAnswer.correct === false) ||
    (myHostId && whatToShow === ANSWERS && teamAnswer.correct === false);

  return (
    <WrapperWrapper>
      <Wrapper>
        <TeamNameRow
          team={team}
          correct={correct}
          incorrect={incorrect}
          editing={editing}
          setEditing={setEditing}
        />
        {showPlayers && <TeamPlayersRow team={team} editing={editing} />}
        {showAnswer && (
          <TeamAnswerRow team={team} correct={correct} incorrect={incorrect} />
        )}
      </Wrapper>
    </WrapperWrapper>
  );
};

export default TeamInfo;
