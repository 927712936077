import getRoomContextProvider from "./getRoomContextProviderFunc";
import { createContext } from "react";

const MainRoomContext = createContext(null);

export const MainRoomContextProvider = getRoomContextProvider(
  MainRoomContext,
  "MainRoom"
);

export default MainRoomContext;
