import React, { useState } from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";

const Wrapper = styled.div`
  ${(props) =>
    props.dropHighlight &&
    `
    opacity: 0.5;
  `}
`;

const Droppable = ({
  accept = "unknown",
  canDrop,
  onDrop,
  children,
  ...rest
}) => {
  const [isDropping, setIsDropping] = useState(false);

  const [{ isOver, dropAllowed }, drop] = useDrop({
    accept,
    drop: async (item, monitor) => {
      await setIsDropping(true);
      await onDrop(item, monitor);
      await setIsDropping(false);
    },
    canDrop,
    collect: (monitor) => ({
      isOver: Boolean(monitor.isOver()),
      dropAllowed: Boolean(monitor.canDrop()),
    }),
  });

  return (
    <Wrapper
      ref={drop}
      dropHighlight={(isOver && dropAllowed) || isDropping}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

export default Droppable;
