import React, { useContext, useState } from "react";
import QuizActionButton from "../buttons/QuizActionButton";
import Button from "../buttons/Button";
import DangerButton from "../buttons/DangerButton";
import CreateTeamModal from "../modals/CreateTeamModal";
import GetLinkModal from "../modals/GetLinkModal";
import ResetQuizModal from "../modals/ResetQuizModal";
import QuizContext from "../../contexts/QuizContext";
import { NEW_WAITING_ROOM } from "../../flags";

export default () => {
  const { quiz, startQuiz, canResetQuiz } = useContext(QuizContext);

  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [showGetLinkModal, setShowGetLinkModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const handleCloseCreateTeam = () => setShowCreateTeamModal(false);

  const handleGetLinkClick = () => setShowGetLinkModal(true);
  const handleCloseGetLink = () => setShowGetLinkModal(false);

  const handleResetClick = () => setShowResetModal(true);
  const handleCancelReset = () => setShowResetModal(false);

  if (!quiz) {
    return null;
  }

  const nonTeamPlayers = quiz.players.filter((player) => player.team === null);

  return (
    <>
      <QuizActionButton
        size="large"
        label="Start"
        clickedLabel="Starting"
        disabled={nonTeamPlayers.length > 0 && !NEW_WAITING_ROOM}
        onClick={startQuiz}
      />
      <Button size="large" onClick={handleGetLinkClick}>
        Invite
      </Button>
      {canResetQuiz && (
        <DangerButton size="large" onClick={handleResetClick}>
          Reset Quiz
        </DangerButton>
      )}
      {showCreateTeamModal && (
        <CreateTeamModal
          onCreate={handleCloseCreateTeam}
          onCancel={handleCloseCreateTeam}
        />
      )}
      {showGetLinkModal && (
        <GetLinkModal
          publicQuizId={quiz.publicId}
          onClose={handleCloseGetLink}
        />
      )}
      {showResetModal && (
        <ResetQuizModal
          publicQuizId={quiz.publicId}
          onClose={handleCancelReset}
        />
      )}
    </>
  );
};
