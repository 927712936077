import React, { useContext } from "react";
import MainRoomContext from "../../contexts/MainRoomContext";
import QuizContext from "../../contexts/QuizContext";
import styled from "styled-components";
import Participant from "../NewParticipant";
import getLogger from "../../util/getLogger";
import AdditionalPlayers from "./AdditionalPlayers";

const logger = getLogger("PlayerGallery");

const MAX_VISIBLE_PLAYERS = 3;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const ParticipantWrapper = styled.div`
  width: 120px;
  height: 90px;
  margin: 0 3px;
`;

const PlayerGallery = () => {
  const {
    participantsWithVideo,
    localParticipant,
    videoStopped,
    localVideoTrack,
    remoteParticipants,
  } = useContext(MainRoomContext);
  const { players, myPlayerId, myPlayer, fakePlayersWithVideo } = useContext(
    QuizContext
  );

  let playersWithVideo;
  if (fakePlayersWithVideo) {
    playersWithVideo = fakePlayersWithVideo;
  } else {
    playersWithVideo = players.filter(
      (player) => participantsWithVideo[player.id]
    );
  }

  const myPlayerVisible = !videoStopped && myPlayerId;
  const numVisibleRemotePlayers = myPlayerVisible
    ? MAX_VISIBLE_PLAYERS - 1
    : MAX_VISIBLE_PLAYERS;
  const visibleRemotePlayers = playersWithVideo.slice(
    0,
    numVisibleRemotePlayers
  );

  const nonVisiblePlayers = players.filter((player) => {
    if (player.id === myPlayerId && myPlayerVisible) {
      return false;
    } else if (
      visibleRemotePlayers.find(
        (visiblePlayer) => visiblePlayer.id === player.id
      )
    ) {
      return false;
    }
    return true;
  });

  logger.debug("visible players", visibleRemotePlayers);

  return (
    <Wrapper data-cy="player-gallery">
      {!videoStopped && myPlayerId && (
        <ParticipantWrapper key={myPlayerId}>
          <Participant
            participant={localParticipant}
            localVideoTrack={localVideoTrack}
            name={myPlayer.name}
          />
        </ParticipantWrapper>
      )}
      {visibleRemotePlayers.map((player) => (
        <ParticipantWrapper key={player.id}>
          <Participant
            size="regular"
            participantId={player.id}
            participant={remoteParticipants[player.id]}
            name={player.name}
            fake={player.videoToken === "test"}
            image={player.image}
          />
        </ParticipantWrapper>
      ))}
      <AdditionalPlayers nonVisiblePlayers={nonVisiblePlayers} />
    </Wrapper>
  );
};

export default PlayerGallery;
