import React, { useState } from "react";
import LoadingScreen from "../LoadingScreen";
import SubtleButton from "./SubtleButton";

export default ({ disabled, onClick, children, ...props }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = async () => {
    setClicked(true);
    await onClick();

    // Let's just assume we are going to be destroyed, so don't bother to
    // reset the clicked state and cause a confusing flash for user
    // setClicked(false);
  };

  return (
    <>
      <SubtleButton
        disabled={clicked || disabled}
        onClick={handleClick}
        {...props}
      >
        {children}
      </SubtleButton>
      {clicked && <LoadingScreen />}
    </>
  );
};
