import React from "react";
import styled from "styled-components";
import { ELEMENT_DEFAULT } from "../../../colors";
import EditIcon from "../../../icons/EditIcon";
import Emoji from "./Emoji";

const Wrapper = styled.div`
  position: relative;
`;

const EditIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${ELEMENT_DEFAULT};
  border-radius: 100px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  align-items: center;
  justify-content: center;
  display: none;

  ${Wrapper}:hover &, 
  ${Wrapper}.hover & {
    display: flex;
    cursor: pointer;
  }
`;

const EditableEmoji = ({ size, onClick, className, ...rest }) => {
  let iconSize;
  let iconWrapperSize;

  if (size === "small") {
    iconSize = 12;
    iconWrapperSize = 20;
  } else if (size === "normal" || !size) {
    iconSize = 15;
    iconWrapperSize = 25;
  } else if (size === "large") {
    iconSize = 18;
    iconWrapperSize = 30;
  } else if (size === "xlarge") {
    iconSize = 27;
    iconWrapperSize = 45;
  }

  return (
    <Wrapper className={className}>
      <Emoji size={size} {...rest} />
      <EditIconWrapper size={iconWrapperSize} onClick={onClick}>
        <EditIcon size={iconSize} />
      </EditIconWrapper>
    </Wrapper>
  );
};

export default EditableEmoji;
