import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content min-content;
  column-gap: 10px;
  row-gap: 10px;
  margin-bottom: 15px;
`;

const TeamWrapper = styled.div`
  grid-column-start: auto;
`;

const TeamGridLayout = ({ teams }) => {
  if (!teams || teams.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {teams.map((team) => (
        <TeamWrapper key={team.key}>{team}</TeamWrapper>
      ))}
    </Wrapper>
  );
};

export default TeamGridLayout;
