import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import getLogger from "../../../util/getLogger";
import NetworkQualityStatsLoggerContext from "../../../contexts/NetworkQualityStatsLoggerContext";

const Wrapper = styled.div`
  display: none;
`;

const NetworkQualityMonitor = ({ participant }) => {
  const { logNetworkQualityStats } = useContext(
    NetworkQualityStatsLoggerContext
  );

  useEffect(() => {
    if (participant) {
      const logger = getLogger(
        `NetworkQualityMonitor: ${participant.identity}`
      );

      const handleNetworkQualityLevelChanged = (
        networkQualityLevel,
        networkQualityStats
      ) => {
        logger.debug(
          {
            1: "▃",
            2: "▃▄",
            3: "▃▄▅",
            4: "▃▄▅▆",
            5: "▃▄▅▆▇",
          }[networkQualityLevel] || ""
        );

        if (networkQualityLevel === 0) {
          const networkBrokenStats = { level: 0 };
          logger.debug("Network Quality statistics", networkBrokenStats);
          logNetworkQualityStats(participant.identity, networkBrokenStats);
        } else if (networkQualityStats) {
          // Print in console the networkQualityStats, which is non-null only if Network Quality
          // verbosity is 2 (moderate) or greater
          logger.debug("Network Quality statistics:", networkQualityStats);
          logNetworkQualityStats(participant.identity, networkQualityStats);
        }
      };

      participant.on(
        "networkQualityLevelChanged",
        handleNetworkQualityLevelChanged
      );

      return () =>
        participant.off(
          "networkQualityLevelChanged",
          handleNetworkQualityLevelChanged
        );
    }
  }, [participant]);

  return <Wrapper />;
};

export default NetworkQualityMonitor;
