import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";

export const LoadingBackground = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
`;

const Centered = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default () => (
  <LoadingBackground data-cy="loading-screen">
    <Centered>
      <Spinner />
    </Centered>
  </LoadingBackground>
);
