import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import styled from "styled-components";
import TeamGridLayout from "../../../components/layouts/TeamGridLayout";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import CompactQuestion from "../../../components/questions/CompactQuestion";
import GridVerticalScrollLayout from "../../../components/layouts/GridVerticalScrollLayout";

const QuestionWrapper = styled.div`
  margin-bottom: 10px;
`;

const AnswersScreen = ({
  audio,
  isHost,
  hostAvatars,
  questionText,
  answerText,
  teams,
  onShowQuestionResults,
  resetButton,
  navigation,
  chat,
  modal,
}) => {
  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        hostAvatars={hostAvatars}
        navigation={navigation}
        chat={chat}
        payload={
          <GridVerticalScrollLayout
            header={
              <QuestionWrapper>
                <CompactQuestion
                  text={questionText}
                  answerText={isHost && answerText}
                  answerCorrect
                />
              </QuestionWrapper>
            }
            content={<TeamGridLayout teams={teams} />}
          />
        }
        actionBar={
          isHost && (
            <ActionBar>
              <LargeDarkButton onClick={onShowQuestionResults}>
                Question Results
              </LargeDarkButton>
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default AnswersScreen;
