import React, { useContext, useEffect } from "react";
import MainRoomContext from "../../contexts/MainRoomContext";
import TeamRoomContext from "../../contexts/TeamRoomContext";
import DarkButton from "../buttons/DarkButton";
import UnmutedIcon from "../icons/UnmutedIcon";
import MutedIcon from "../icons/MutedIcon";
import VideoStartedIcon from "../icons/VideoStartedIcon";
import VideoStoppedIcon from "../icons/VideoStoppedIcon";
import LabelWrapper from "./LabelWrapper";
import QuizContext from "../../contexts/QuizContext";
import getLogger from "../../util/getLogger";
import { MAX_AUTO_UNMUTE } from "../../constants";
import DeviceContext from "../../contexts/DeviceContext";
import MuteSpeakingMonitor from "./MuteSpeakingMonitor";
import { navigate } from "gatsby";
import { LIGHT_GREY_1 } from "../../colors";

const logger = getLogger("AVControls");

const AVControls = () => {
  const {
    muted,
    videoStopped,
    setMuted,
    setVideoStopped,
    explicitlyMuted,
    explicitlyStoppedVideo,
    setExplicitlyMuted,
    setExplicitlyStoppedVideo,
  } = useContext(DeviceContext);

  const {
    setMuted: mainRoomSetMuted,
    setVideoStopped: mainRoomSetVideoStopped,
  } = useContext(MainRoomContext);

  const {
    setMuted: teamRoomSetMuted,
    setVideoStopped: teamRoomSetVideoStopped,
  } = useContext(TeamRoomContext);

  const {
    started,
    myHostId,
    assistingTeamId,
    myPlayerId,
    myTeamId,
    players,
    ended,
    playerJoinRank,
    leaderJoinRank,
    inTeamRoom,
    inMainRoom,
  } = useContext(QuizContext);

  const teamId = myTeamId || assistingTeamId;

  useEffect(() => {
    logger.debug("in main effect");

    const inSmallGame = players.length <= MAX_AUTO_UNMUTE;

    if (myHostId && !assistingTeamId) {
      // turn on audio and video
      mainRoomSetMuted(explicitlyMuted || false);
      mainRoomSetVideoStopped(explicitlyStoppedVideo || false);

      setMuted(explicitlyMuted || false);
      setVideoStopped(explicitlyStoppedVideo || false);

      logger.info("Turned on audio and video for host in main room");
    } else {
      if (!started) {
        setVideoStopped(explicitlyStoppedVideo || false);
        logger.debug("playerJoinRank", playerJoinRank);
        if (playerJoinRank && playerJoinRank <= MAX_AUTO_UNMUTE) {
          mainRoomSetMuted(explicitlyMuted || false);
          mainRoomSetVideoStopped(explicitlyStoppedVideo || false);

          setMuted(explicitlyMuted || false);
          setVideoStopped(explicitlyStoppedVideo || false);

          logger.info("Auto unmuted / started video for player in main room");
        } else {
          mainRoomSetMuted(true);
          setMuted(true);
          logger.info("Leaving player muted");
        }
      } else if (inTeamRoom) {
        // if (
        //   !(
        //     inSmallGame ||
        //     (leaderJoinRank && leaderJoinRank <= MAX_AUTO_UNMUTE)
        //   )
        // ) {
        //   mainRoomSetMuted(true);
        //   mainRoomSetVideoStopped(true);
        //   logger.info("Auto muted / stopped video for player in main room");
        // }

        // Stop publishing tracks to main room to reduce everyone's network
        mainRoomSetMuted(true);
        mainRoomSetVideoStopped(true);
        logger.info("Auto muted / stopped video for player in main room");

        // if (myTeamId) {
        teamRoomSetMuted(explicitlyMuted || false);
        teamRoomSetVideoStopped(explicitlyStoppedVideo || false);

        setMuted(explicitlyMuted || false);
        setVideoStopped(explicitlyStoppedVideo || false);
        // trigger notification

        logger.info("Auto unmuted / started video for player in team room");

        // } else {
        //   setMuted(true);
        //   setVideoStopped(true);
        //   // trigger notification
        // }
      } else if (inMainRoom) {
        logger.debug("inSmallGame", inSmallGame);

        logger.debug("leaderJoinRank", leaderJoinRank);

        // Stop publishing tracks to main room to reduce everyone's network
        if (teamId) {
          teamRoomSetMuted(true);
          teamRoomSetVideoStopped(true);
          logger.info("Auto muted / stopped video for player in team room");
        }

        if (
          inSmallGame ||
          (leaderJoinRank && leaderJoinRank <= MAX_AUTO_UNMUTE)
        ) {
          mainRoomSetMuted(explicitlyMuted || false);
          mainRoomSetVideoStopped(explicitlyStoppedVideo || false);

          setMuted(explicitlyMuted || false);
          setVideoStopped(explicitlyStoppedVideo || false);
          // trigger notification
          logger.info("Auto unmuted / started video for player in main room");
        } else {
          setMuted(true);
          // trigger notification

          logger.info("Auto muted / stopped video for player in main room");
        }
      }
    }
  }, [started, inMainRoom, inTeamRoom, teamId]);

  const handleMute = () => {
    setExplicitlyMuted(true);
    if (myHostId) {
      mainRoomSetMuted(true);
    } else if (!started || inMainRoom) {
      mainRoomSetMuted(true);
      // mainRoomSetVideoStopped(true);  // let's stop doing this for the time being and see if we get a big penalty IRL
    } else if (teamId) {
      teamRoomSetMuted(true);
    }
    setMuted(true);
    logger.debug("muted");
  };

  const handleUnmute = () => {
    setExplicitlyMuted(false);
    if (myHostId) {
      mainRoomSetMuted(false);
    } else if (!started || inMainRoom) {
      mainRoomSetMuted(false);
      mainRoomSetVideoStopped(explicitlyStoppedVideo || false);
    } else if (teamId) {
      teamRoomSetMuted(false);
    }
    setMuted(false);
    logger.debug("unmuted");
  };

  const handleStopVideo = () => {
    setExplicitlyStoppedVideo(true);
    if (!started || myHostId || inMainRoom) {
      mainRoomSetVideoStopped(true);
    }
    if (teamId) {
      teamRoomSetVideoStopped(true);
    }
    setVideoStopped(true);
    logger.debug("stopped video");
  };

  const handleStartVideo = () => {
    setExplicitlyStoppedVideo(false);
    if (!started || myHostId || inMainRoom) {
      mainRoomSetVideoStopped(false);
    }
    if (teamId) {
      teamRoomSetVideoStopped(false);
    }
    setVideoStopped(false);
    logger.debug("started video");
  };

  const handleAVProblems = async () => {
    await navigate("/preview-av/");
  };

  if (myPlayerId && ended) {
    return null;
  }

  return (
    <>
      {!muted && (
        <DarkButton onClick={handleMute}>
          <LabelWrapper>
            <UnmutedIcon color={LIGHT_GREY_1} /> Mute
          </LabelWrapper>
        </DarkButton>
      )}
      {muted && (
        <DarkButton onClick={handleUnmute}>
          <LabelWrapper>
            <MutedIcon color={LIGHT_GREY_1} /> Unmute
          </LabelWrapper>
        </DarkButton>
      )}
      {!videoStopped && (
        <DarkButton onClick={handleStopVideo}>
          <LabelWrapper>
            <VideoStartedIcon color={LIGHT_GREY_1} /> Stop Video
          </LabelWrapper>
        </DarkButton>
      )}
      {videoStopped && (
        <DarkButton onClick={handleStartVideo}>
          <LabelWrapper>
            <VideoStoppedIcon color={LIGHT_GREY_1} /> Start Video
          </LabelWrapper>
        </DarkButton>
      )}
      <DarkButton onClick={handleAVProblems}>
        <LabelWrapper>A/V Problems?</LabelWrapper>
      </DarkButton>
      <MuteSpeakingMonitor />
    </>
  );
};

export default AVControls;
