import React, { useContext, useEffect, useState } from "react";
import getLogger from "../../util/getLogger";
import styled from "styled-components";
import QuizContext from "../../contexts/QuizContext";
import AnswerWrapper from "./AnswerWrapper";
import AnswerTextWrapper from "./AnswerTextWrapper";
import Pusher from "pusher-js";
import getPusherAppKey from "../../util/getPusherAppKey";
import inTesting from "../../util/inTesting";
import { DARK_GREY_4 } from "../../colors";

const logger = getLogger("TeamInputView");

const AnswerTextWaiting = styled.span`
  color: ${DARK_GREY_4};
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
`;

export default () => {
  const { myTeam } = useContext(QuizContext);

  const [partialAnswer, setPartialAnswer] = useState("");

  // useEffect(() => {
  //   let eventSource;
  //
  //   if (window.ReconnectingEventSource) {
  //     const eventId = myTeam.id.replace(/-/g, "");
  //     const url = `${getEventSourcePrefix()}/events/${eventId}/`;
  //     eventSource = new ReconnectingEventSource(url, {
  //       withCredentials: true,
  //     });
  //
  //     eventSource.onmessage = async (event) => {
  //       const message = JSON.parse(event.data);
  //       setPartialAnswer(message.text);
  //     };
  //   } else {
  //     logger.error("browser does not support server sent events");
  //     // Result to xhr polling :(
  //   }
  //
  //   return () => eventSource && eventSource.close();
  // }, []);

  useEffect(() => {
    if (inTesting()) {
      logger.debug(
        "We are running in test mode, so skipping any connection attempts to pusher"
      );
      return;
    }

    const pusher = new Pusher(getPusherAppKey(), {
      cluster: "us3",
    });

    const eventId = myTeam.id.replace(/-/g, "");
    const channel = pusher.subscribe(eventId);

    channel.bind_global(async (eventName, data) => {
      logger.info(`received pusher event: ${eventName} with data ${data}`);
      logger.debug(data);

      if (eventName === "message") {
        setPartialAnswer(data.text);
      }
    });

    return () => {
      channel.unbind();
      pusher.disconnect();
    };
  }, []);

  return (
    <AnswerWrapper>
      {partialAnswer && (
        <>
          <span>Your Answer: </span>
          <AnswerTextWrapper>{partialAnswer}</AnswerTextWrapper>
        </>
      )}
      {!partialAnswer && (
        <AnswerTextWaiting>
          Waiting for your team leader to give an answer...
        </AnswerTextWaiting>
      )}
    </AnswerWrapper>
  );
};
