import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation FinishAssistingTeam {
      finishAssistingTeam {
        ok
      }
    }
  `);
