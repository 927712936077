import React from "react";
import styled from "styled-components";
import { OVERLAY_1 } from "../../../../colors";

const CircularContainer = styled.div`
  background-color: ${OVERLAY_1};
  border-radius: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 20px;
  width: 36px;
  height: 36px;
`;

const Grid = styled.div`
  position: relative;
`;

const EmojiWrapper = styled.span`
  display: inline-block;
  font-size: 4em;
  transform: scale(0.25) translateY(1.1em);
  margin: -1em -0.333em;
`;

const Avatar = ({ message }) => {
  let emoji;

  if (message.fromHost) {
    emoji = "🎤";
  } else if (message.fromPlayer) {
    const player = message.fromPlayer;
    const team = player.team;
    if (team) {
      emoji = team.emoji;
    }
  }

  return (
    <CircularContainer size="small">
      <Grid>
        <EmojiWrapper>{emoji}</EmojiWrapper>
      </Grid>
    </CircularContainer>
  );
};

export default Avatar;
