import React from "react";
import RealParticipantVideo from "./RealParticipantVideo";
import FakeParticipantVideo from "./FakeParticipantVideo";
import getEmoji from "../../../util/getEmoji";

const ParticipantVideo = ({ fake, participantId, teamId, ...restOfProps }) => {
  if (fake) {
    return (
      <FakeParticipantVideo emoji={getEmoji(participantId + (teamId || 0))} />
    );
  }
  return <RealParticipantVideo {...restOfProps} />;
};

export default ParticipantVideo;
