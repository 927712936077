import React, { useContext, useState } from "react";
import Modal from "../Modal";
import DangerButton from "../buttons/DangerButton";
import OurButton from "../buttons/Button";
import QuizContext from "../../contexts/QuizContext";

export default ({ onCancel }) => {
  const {
    quizConversation,
    tieExists,
    tiebreaker,
    showTiebreaker,
  } = useContext(QuizContext);
  const [submitted, setSubmitted] = useState(false);

  const handleEndQuizEarly = async () => {
    setSubmitted(true);
    await quizConversation();
  };

  const handleEarlyTiebreaker = async () => {
    setSubmitted(true);
    await showTiebreaker();
  };

  const tiebreakerNext = tieExists && tiebreaker;

  return (
    <Modal
      title="End Quiz Early"
      text={`There are more rounds. ${
        tiebreakerNext ? "Also, there is a tie for first place. " : ""
      }Are you sure you want to end the quiz early?`}
      buttons={
        <>
          {!tiebreakerNext && (
            <DangerButton
              size="large"
              onClick={handleEndQuizEarly}
              disabled={submitted}
              data-cy="end-quiz-button"
            >
              {submitted ? "Ending Quiz" : "End Quiz"}
            </DangerButton>
          )}
          {tiebreakerNext && (
            <DangerButton
              size="large"
              onClick={handleEarlyTiebreaker}
              disabled={submitted}
              data-cy="tiebreaker-button"
            >
              {submitted ? "Tiebreaker..." : "Tiebreaker"}
            </DangerButton>
          )}
          <OurButton size="large" onClick={onCancel}>
            Cancel
          </OurButton>
        </>
      }
    />
  );
};
