import React from "react";
import styled from "styled-components";
import Button from "../../buttons/Button";
import SendIcon from "../../icons/SendIcon";
import { ACCENT_PRIMARY, WHITE } from "../../../colors";

const RoundAccentedButton = styled(Button)`
  height: 36px;
  width: 36px;
  border-radius: 100px;
  color: ${WHITE};
  padding: 0;
  background-color: ${ACCENT_PRIMARY};
  border-color: ${ACCENT_PRIMARY};
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    box-shadow: 0 0 0 2px ${ACCENT_PRIMARY};
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: default;
    
    :hover {
      box-shadow: none;
    }
  `}

  & svg {
    stroke: ${WHITE};
    width: 16px;
  }
`;

const SendButton = ({ canSend, onSend, sending }) => {
  return (
    <RoundAccentedButton disabled={!canSend || sending} onClick={onSend}>
      <SendIcon size="medium" />
    </RoundAccentedButton>
  );
};

export default SendButton;
