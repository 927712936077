import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import isOverflowing from "../util/isOverflowing";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

const Wrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextWithTooltipOnOverflow = ({ text, "data-cy": dataCy }) => {
  const [overflowing, setOverflowing] = useState(false);
  const [tooltipId, setTooltipId] = useState(null);
  const ref = useRef();

  useEffect(() => {
    if (isOverflowing(ref.current)) {
      setOverflowing(isOverflowing(ref.current));
      setTooltipId(uuidv4());
    }
  }, []);

  return (
    <>
      <Wrapper
        data-tip={overflowing}
        data-for={tooltipId}
        data-cy={dataCy}
        ref={ref}
      >
        {text}
      </Wrapper>
      {overflowing && (
        <ReactTooltip id={tooltipId} effect="solid" delayShow={500}>
          {text}
        </ReactTooltip>
      )}
    </>
  );
};

export default TextWithTooltipOnOverflow;
