import React, { useContext } from "react";
import TiebreakerResultsScreen from "../../../screens/quiz/14/TiebreakerResultsScreen";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import getAllAudio from "../AudioController/getAllAudio";
import QuizContext from "../../../../contexts/QuizContext";
import TeamController from "../TeamController";
import useShowTotalScoresMutation from "../../../hooks/mutations/useShowTotalScoresMutation";
import getHostAvatarControllerList from "../AvatarControllers/getHostAvatarControllerList";

const TiebreakerResultsController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const { tiebreaker, teamsInFirstPlace } = useContext(QuizContext);

  const [showTotalScoresMutation] = useShowTotalScoresMutation();

  const handleShowQuizScores = async () => await showTotalScoresMutation();

  return (
    <TiebreakerResultsScreen
      audio={getAllAudio()}
      isHost={Boolean(myHostId)}
      hostAvatars={getHostAvatarControllerList()}
      tiebreakerText={tiebreaker.text}
      tiebreakerAnswerText={tiebreaker.answer}
      teamsInTiebreaker={
        teamsInFirstPlace &&
        teamsInFirstPlace.map((team) => (
          <TeamController
            key={team.id}
            team={team}
            inTiebreaker
            showAnswer
            answerGraded
          />
        ))
      }
      onShowQuizScores={handleShowQuizScores}
      resetButton={resetButton}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
    />
  );
};

export default TiebreakerResultsController;
