import React from "react";
import Modal from "../components/modals/BaseModal";
import ButtonRow from "../components/buttons/ButtonRow";
import LargeOutlineButton from "../components/buttons/LargeOutlineButton";
import LargeDangerButton from "../components/buttons/LargeDangerButton";

const SkipRemainingRoundsModal = ({
  tieExists,
  onSkip,
  onTiebreaker,
  onCancel,
}) => {
  return (
    <Modal
      title={"Skip Remaining Rounds"}
      text={
        <>
          {tieExists && (
            <>
              Are you sure you want skip the remaining rounds? Also, there is a
              tie for first place. After the tiebreaker, you will still have a
              chance to talk in Quiz Conversation before sending them to the
              survey.
            </>
          )}
          {!tieExists && (
            <>
              Are you sure you want skip the remaining rounds? You will still
              have a chance to talk in Quiz Conversation before sending them to
              the survey.
            </>
          )}
        </>
      }
      buttons={
        <ButtonRow>
          {tieExists && (
            <LargeDangerButton
              onClick={onTiebreaker}
              data-cy-name={"Tiebreaker"}
            >
              Tiebreaker
            </LargeDangerButton>
          )}
          {!tieExists && (
            <LargeDangerButton onClick={onSkip} data-cy-name={"Skip"}>
              Skip
            </LargeDangerButton>
          )}
          <LargeOutlineButton noSpinner onClick={onCancel}>
            Cancel
          </LargeOutlineButton>
        </ButtonRow>
      }
      onEscapeKey={onCancel}
    />
  );
};

export default SkipRemainingRoundsModal;
