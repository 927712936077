import React from "react";
import styled from "styled-components";
import GradientCard from "../elements/cards/GradientCard";
import { PARAGRAPH_LARGE_BOLD, PARAGRAPH_NORMAL_BOLD } from "../../typography";
import {
  ELEMENT_OFF_WHITE,
  ELEMENT_OFF_WHITE_DARK,
  TEXT_DEFAULT,
  TEXT_LIGHT,
  WHITE,
} from "../../colors";
import MainInputWithButton from "../inputs/MainInputWithButton";
import SpinnerIcon from "../../icons/SpinnerIcon";
import LargeMainButton from "../buttons/LargeMainButton";

const StyledCard = styled(GradientCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 0;
`;

const QuestionNumber = styled.div`
  color: ${WHITE};
  ${PARAGRAPH_NORMAL_BOLD}
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
`;

const QuestionText = styled.div`
  color: ${WHITE};
  ${PARAGRAPH_LARGE_BOLD}
  text-align: center;
  padding: 0 30px;
  max-width: 800px;
`;

const HostAnswerText = styled.div`
  ${PARAGRAPH_LARGE_BOLD}
  color: ${ELEMENT_OFF_WHITE_DARK};
  margin: 15px 0 0;
  padding: 0 30px;
  max-width: 800px;
`;

const MainInputWrapper = styled.div`
  width: 100%;
  max-width: 660px;
  padding: 40px 30px;
  box-sizing: border-box;
`;

const DismissButtonWrapper = styled.div`
  padding: 40px 0;
`;

const WaitingForAnswerLine = styled.div`
  margin-top: 35px;
  ${PARAGRAPH_LARGE_BOLD}
  color: ${TEXT_LIGHT};
  background-color: ${ELEMENT_OFF_WHITE};
  width: 100%;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

const AnswerViewLine = styled(WaitingForAnswerLine)`
  color: ${TEXT_DEFAULT};
`;

const AnswerView = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SpinnerIconWrapper = styled.div`
  position: absolute;
  right: -30px;
  display: flex;
  align-items: center;
`;

const Question = ({
  number,
  isTiebreaker,
  text,
  value,
  answerText,
  isHost,
  isLeader,
  leaderName,
  onChange,
  onSubmit,
  submitting,
  onDismiss,
}) => {
  return (
    <StyledCard data-cy={"Question"}>
      <QuestionNumber>
        {isTiebreaker ? "Tiebreaker" : `Question ${number}`}
      </QuestionNumber>
      <QuestionText>{text}</QuestionText>
      {isHost && (
        <>
          <HostAnswerText>{answerText}</HostAnswerText>
          <DismissButtonWrapper>
            <LargeMainButton onClick={onDismiss}>
              Dismiss and Show Teams
            </LargeMainButton>
          </DismissButtonWrapper>
        </>
      )}
      {!isHost && (
        <>
          {isLeader && (
            <MainInputWrapper>
              <MainInputWithButton
                inputName={"answer-input"}
                buttonName={"submit-button"}
                placeholder={"Type your answer"}
                politeAutofocus
                value={value}
                onChange={onChange}
                onSubmit={onSubmit}
                buttonText={"Submit"}
                submitting={submitting}
              />
            </MainInputWrapper>
          )}
          {!isLeader && !value && (
            <WaitingForAnswerLine>
              Waiting for {leaderName} to type an answer
            </WaitingForAnswerLine>
          )}
          {!isLeader && value && (
            <AnswerViewLine>
              <AnswerView>
                {value}
                {submitting && (
                  <SpinnerIconWrapper>
                    <SpinnerIcon size={20} color={TEXT_DEFAULT} />
                  </SpinnerIconWrapper>
                )}
              </AnswerView>
            </AnswerViewLine>
          )}
        </>
      )}
    </StyledCard>
  );
};

export default Question;
