import styled from "styled-components";
import { ACCENT_TERTIARY } from "../../../../colors";

const HostSignifier = styled.div`
  color: ${ACCENT_TERTIARY};
  font-size: 14px;
  font-weight: normal;
`;

export default HostSignifier;
