import React, { useContext, useEffect } from "react";
import UserContext from "../../contexts/UserContext";
import { WARNING } from "../Announcement";
import styled from "styled-components";
import LocalParticipant from "./LocalParticipant";
import getLogger from "../../util/getLogger";
import RemoteParticipantsListener from "./RemoteParticipantsListener";

const Wrapper = styled.div`
  display: none;
`;

const Room = ({
  roomName,
  room,
  participants,
  setParticipants,
  setParticipantsWithAudio,
  setParticipantsWithVideo,
  setDominantSpeaker,
  localAudioTrack,
  localVideoTrack,
  muted,
  videoStopped,
  setLocalSpeaking,
}) => {
  const logger = getLogger(`Room: ${roomName}: ${room}`);

  const { setAnnouncement } = useContext(UserContext);

  useEffect(() => {
    logger.debug("in main effect");

    const handleDominantSpeakerChanged = (participant) => {
      logger.debug("in handleDominantSpeakerChanged");
      logger.debug("new dominant speaker", participant);
      setDominantSpeaker(participant);
    };

    const handleReconnecting = () => {
      logger.debug("in handleReconnecting");
      setAnnouncement({
        type: "lostConnection",
        level: WARNING,
      });
    };

    const handleReconnected = () => {
      logger.debug("in handleReconnected");
      setAnnouncement(null);
    };

    const addRoomListeners = () => {
      room.on("dominantSpeakerChanged", handleDominantSpeakerChanged);
      room.on("reconnecting", handleReconnecting);
      room.on("reconnected", handleReconnected);
      logger.debug("Added all room event listeners");
    };

    const removeRoomListeners = () => {
      room.off("dominantSpeakerChanged", handleDominantSpeakerChanged);
      room.off("reconnecting", handleReconnecting);
      room.off("reconnected", handleReconnected);
      logger.debug("Removed all room event listeners");
    };

    const stopLocalTracks = async () => {
      if (localAudioTrack) {
        await localAudioTrack.stop();
        logger.info(`Stopped track ${localAudioTrack}`);
        logger.debug(localAudioTrack);
      }

      if (localVideoTrack) {
        await localVideoTrack.stop();
        logger.info(`Stopped track ${localVideoTrack}`);
        logger.debug(localVideoTrack);
        localVideoTrack = null;
      }
    };

    const disconnect = async () => {
      logger.info("Disconnecting from room");
      logger.debug(
        "Disconnecting room.localParticipant",
        room.localParticipant
      );
      if (
        room.localParticipant &&
        room.localParticipant.state === "connected"
      ) {
        logger.debug("disconnecting got past the if conditions");
        try {
          await stopLocalTracks();
          await room.disconnect();
        } catch (err) {
          logger.info("trying to skip this error");
        }
        logger.info("Successfully disconnected from room");
      } else {
        logger.info(
          "Local Participant not in connected state, so nothing to do"
        );
      }
    };

    window.addEventListener("beforeunload", disconnect);

    addRoomListeners();

    return () => {
      window.removeEventListener("beforeunload", disconnect);
      removeRoomListeners();
      disconnect();
    };
  }, []);

  return (
    <Wrapper>
      <LocalParticipant
        localParticipant={room.localParticipant}
        localAudioTrack={localAudioTrack}
        localVideoTrack={localVideoTrack}
        muted={muted}
        videoStopped={videoStopped}
        setLocalSpeaking={setLocalSpeaking}
      />
      <RemoteParticipantsListener
        room={room}
        participants={participants}
        setParticipants={setParticipants}
        setParticipantsWithAudio={setParticipantsWithAudio}
        setParticipantsWithVideo={setParticipantsWithVideo}
      />
    </Wrapper>
  );
};

export default Room;
