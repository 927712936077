import React, { useContext } from "react";
import RoundIntroScreen from "../../../screens/quiz/05/RoundIntroScreen";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import useStartRoundMutation from "../../../hooks/mutations/useStartRoundMutation";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import QuizContext from "../../../../contexts/QuizContext";
import getHostsAudio from "../AudioController/getHostsAudio";
import getHostAvatarControllerList from "../AvatarControllers/getHostAvatarControllerList";

const RoundIntroController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const { round } = useContext(QuizContext);

  const [startRoundMutation] = useStartRoundMutation();

  const handleStartRound = async () => {
    await startRoundMutation();
  };

  return (
    <RoundIntroScreen
      audio={getHostsAudio()}
      isHost={Boolean(myHostId)}
      hostAvatars={getHostAvatarControllerList()}
      roundNumber={round.number}
      roundTitle={round.title}
      onStartRound={handleStartRound}
      resetButton={resetButton}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
    />
  );
};

export default RoundIntroController;
