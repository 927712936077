import React from "react";
import styled, { keyframes } from "styled-components";
import Sender from "./Sender";
import Body from "./Body";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 0 15px;
  animation: ${fadeIn} 0.2s linear;
`;

const Message = ({ message }) => {
  return (
    <Wrapper>
      <Sender message={message} />
      <Body message={message} />
    </Wrapper>
  );
};

export default Message;
