import React from "react";
import styled from "styled-components";
import { LIGHT_REGULAR_TEXT, OVERLAY_1 } from "../../../colors";

const Wrapper = styled.div`
  font-size: 16px;
  color: ${LIGHT_REGULAR_TEXT};
  background-color: ${OVERLAY_1};
  padding: 10px 12px;
  border-radius: 0 15px 15px;
  word-break: break-word;
`;

const Body = ({ message }) => {
  return <Wrapper dangerouslySetInnerHTML={{ __html: message.body }} />;
};

export default Body;
