import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation TeamLeadTypingAnswer($text: String!, $emoji: String) {
      teamLeadTypingAnswer(text: $text, emoji: $emoji) {
        ok
      }
    }
  `);
