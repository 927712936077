import React from "react";
import styled from "styled-components";
import { BRAND_LIGHT, OVERLAY_1 } from "../colors";

const CircularContainer = styled.div`
  font-size: 26px;
  background-color: ${({ nobackground }) =>
    nobackground ? "none" : BRAND_LIGHT};
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  ${({ dark }) =>
    dark &&
    `
    background-color: ${OVERLAY_1};
  `}
  ${({ size }) =>
    size === "large" &&
    `
    font-size: 40px;
    width: 60px;
    height: 60px;
  `}
  ${({ size }) =>
    size === "small" &&
    `
    font-size: 20px;
    width: 26px;
    height: 26px;
  `}
  ${({ size }) =>
    size === "tiny" &&
    `
    font-size: 16px;
    width: 20px;
    height: 20px;
  `}
`;

const Grid = styled.div`
  position: relative;
`;

const EmojiWrapper = styled.span`
  display: inline-block;
  font-size: 4em;
  transform: scale(0.25) translateY(1.1em);
  margin: -1em -0.333em;
`;

export default ({ emoji, ...props }) => (
  <CircularContainer {...props}>
    <Grid>
      <EmojiWrapper>{emoji}</EmojiWrapper>
    </Grid>
  </CircularContainer>
);
