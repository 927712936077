import styled from "styled-components";
import { DARK_GREY_4 } from "../../colors";

export default styled.div`
  margin-top: 15px;
  font-size: 20px;
  font-weight: bold;
  color: ${DARK_GREY_4};
  line-height: 120%;
`;
