import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import QuizIntro from "../../../components/intros/QuizIntro";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import IntroLayout from "../../../components/layouts/IntroLayout";
import HostsOnIntroScreens from "../../../components/hosts/HostsOnIntroScreens";
import TeamFormationInstructions from "../../../components/instructions/01/TeamFormationInstructions";

const QuizIntroScreen = ({
  audio,
  isHost,
  hostAvatars,
  title,
  onShowSplitIntoTeamsModal,
  resetButton,
  navigation,
  chat,
  modal,
}) => {
  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        navigation={navigation}
        chat={chat}
        payload={
          <IntroLayout
            intro={<QuizIntro title={title} />}
            instructions={isHost && <TeamFormationInstructions />}
            hosts={<HostsOnIntroScreens hostAvatars={hostAvatars} />}
          />
        }
        actionBar={
          isHost && (
            <ActionBar>
              <LargeDarkButton noSpinner onClick={onShowSplitIntoTeamsModal}>
                Split Into Teams
              </LargeDarkButton>
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default QuizIntroScreen;
