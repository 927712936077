import { useCallback, useContext, useEffect, useState } from "react";
import QuizContext from "../../contexts/QuizContext";
import useInterval from "../../hooks/useInterval";
import ServerTimeContext from "../../contexts/ServerTimeContext";
import getLogger from "../../util/getLogger";
import UserContext from "../../contexts/UserContext";
import { CRITICAL, OK, WARNING } from "../Announcement";

const logger = getLogger("NamingTimer");

const pluralize = (value, text) => `${text}${value !== 1 ? "s" : ""}`;

const getText = (secondsLeft) => {
  const minutesLeft = Math.floor(secondsLeft / 60);
  if (minutesLeft >= 1) {
    const remainder = secondsLeft % 60;
    const minutesText = `${minutesLeft} ${pluralize(minutesLeft, "minute")}`;
    const secondsText =
      remainder > 0 ? ` ${remainder} ${pluralize(remainder, "second")}` : "";
    return `${minutesText}${secondsText} left`;
  }

  return `${secondsLeft} ${pluralize(secondsLeft, "second")} left`;
};

const NamingTimer = () => {
  const { setAnnouncement } = useContext(UserContext);
  const { quiz, myHostId, meetTeams } = useContext(QuizContext);
  const { getSecondsLeft } = useContext(ServerTimeContext);
  const [closingNaming, setClosingNaming] = useState(false);

  const namingClosesAt = quiz && quiz.namingClosesAt;

  const updateSecondsLeft = useCallback(() => {
    if (namingClosesAt) {
      const secondsLeft = getSecondsLeft(namingClosesAt);
      logger.debug("seconds left", secondsLeft);

      let text = getText(secondsLeft);
      let level = OK;

      if (secondsLeft <= 10) {
        level = WARNING;
      }

      if (secondsLeft <= 0) {
        text = "Naming closed";
        level = CRITICAL;
      }

      setAnnouncement({
        text,
        level,
      });

      if (myHostId && secondsLeft <= 0 && !closingNaming) {
        setClosingNaming(true);
        meetTeams();
      }
    }
  }, [namingClosesAt, closingNaming]);

  useInterval(() => {
    updateSecondsLeft();
  }, 1000);

  useEffect(() => {
    return () => setAnnouncement(null);
  }, []);

  return null;
};

export default NamingTimer;
