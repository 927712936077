import React from "react";
import styled from "styled-components";
import {
  ELEMENT_OFF_WHITE,
  PLATFORM_ERROR_LIGHT,
  PLATFORM_SUCCESS,
} from "../../../colors";
import AnswerIcon, { CORRECT, INCORRECT } from "../../../icons/AnswerIcon";

const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${ELEMENT_OFF_WHITE};
`;

const StyledAnswerIcon = styled(AnswerIcon)``;

const Answer = ({ correct }) => (
  <Wrapper correct={correct}>
    <StyledAnswerIcon
      state={correct ? CORRECT : INCORRECT}
      color={correct ? PLATFORM_SUCCESS : PLATFORM_ERROR_LIGHT}
    />
  </Wrapper>
);

export default Answer;
