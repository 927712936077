import React, { useContext } from "react";
import QuizContext from "../../contexts/QuizContext";
import NameTeamsHost from "./NameTeamsHost";
import NameTeamMyTeamNamed from "./NameTeamMyTeamNamed";
import NameTeamLeader from "./NameTeamLeader";
import NameTeamPlayer from "./NameTeamPlayer";
import NameTeamJoinOrCreate from "./NameTeamJoinOrCreate";
import styled from "styled-components";
import NamingTimer from "./NamingTimer";

const Wrapper = styled.div``;

const NameTeams = () => {
  const { myHostId, myTeamId, myPlayer, myTeamNamed } = useContext(QuizContext);

  const renderPayload = () => {
    if (myHostId) {
      return <NameTeamsHost />;
    }

    if (!myTeamId) {
      return <NameTeamJoinOrCreate />;
    }

    if (myTeamNamed) {
      return <NameTeamMyTeamNamed />;
    }

    if (myPlayer.leader) {
      return <NameTeamLeader />;
    } else {
      return <NameTeamPlayer />;
    }
  };

  return (
    <Wrapper>
      {renderPayload()}
      <NamingTimer />
    </Wrapper>
  );
};

export default NameTeams;
