import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 0;
  font-size: 24px;
  margin-top: 10px;
`;

const CORRECT_SYMBOL = "✅";
const INCORRECT_SYMBOL = "❌";

const TeamResult = ({ correct, incorrect }) => {
  return (
    <Wrapper>
      {correct && CORRECT_SYMBOL}
      {incorrect && INCORRECT_SYMBOL}
    </Wrapper>
  );
};

export default TeamResult;
