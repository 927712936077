import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation ResetQuiz($publicQuizId: String!) {
      resetQuiz(publicQuizId: $publicQuizId) {
        quiz {
          id
          started
          whatToShow
        }
      }
    }
  `);
