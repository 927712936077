import React from "react";
import { ELEMENT_DARK } from "../colors";

export const CORRECT = "correct";
export const INCORRECT = "incorrect";

const AnswerIcon = ({ state = CORRECT, color = ELEMENT_DARK }) => {
  if (state === CORRECT) {
    return (
      <svg
        width="11"
        height="11"
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1666 2.25L5.37492 10.0417L1.83325 6.5"
          stroke={color}
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (state === INCORRECT) {
    return (
      <svg
        width="9"
        height="9"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6339 2.13388C11.122 1.64573 11.122 0.854272 10.6339 0.366117C10.1457 -0.122039 9.35427 -0.122039 8.86612 0.366117L5.5 3.73223L2.13388 0.366117C1.64573 -0.122039 0.854272 -0.122039 0.366117 0.366117C-0.122039 0.854272 -0.122039 1.64573 0.366117 2.13388L3.73223 5.5L0.366117 8.86612C-0.122039 9.35427 -0.122039 10.1457 0.366117 10.6339C0.854272 11.122 1.64573 11.122 2.13388 10.6339L5.5 7.26777L8.86612 10.6339C9.35427 11.122 10.1457 11.122 10.6339 10.6339C11.122 10.1457 11.122 9.35427 10.6339 8.86612L7.26777 5.5L10.6339 2.13388Z"
          fill={color}
        />
      </svg>
    );
  }
};

export default AnswerIcon;
