import React, { useState } from "react";
import Button from "./Button";
import LoadingScreen from "../LoadingScreen";

export default ({ label, clickedLabel, disabled, onClick, ...props }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = async () => {
    setClicked(true);
    await onClick();

    // Let's just assume we are going to be destroyed, so don't bother to
    // reset the clicked state and cause a confusing flash for user
    // setClicked(false);
  };

  return (
    <>
      <Button disabled={clicked || disabled} onClick={handleClick} {...props}>
        {clicked ? clickedLabel : label}
      </Button>
      {clicked && <LoadingScreen />}
    </>
  );
};
