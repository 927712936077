import { useEffect, useState } from "react";

const useIsTrackSwitchedOff = (track) => {
  const [isSwitchedOff, setIsSwitchedOff] = useState(
    Boolean(track && track.isSwitchedOff)
  );

  useEffect(() => {
    setIsSwitchedOff(Boolean(track && track.isSwitchedOff));

    if (track) {
      const handleSwitchedOff = () => setIsSwitchedOff(true);
      const handleSwitchedOn = () => setIsSwitchedOff(false);

      track.on("switchedOff", handleSwitchedOff);
      track.on("switchedOn", handleSwitchedOn);

      return () => {
        track.off("switchedOff", handleSwitchedOff);
        track.off("switchedOn", handleSwitchedOn);
      };
    }
  }, [track]);

  return isSwitchedOff;
};

export default useIsTrackSwitchedOff;
