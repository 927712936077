import styled from "styled-components";

const LabelWrapper = styled.div`
  > svg {
    vertical-align: top;
    margin-right: 2px;
  }
`;

export default LabelWrapper;
