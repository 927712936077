import React, { useState } from "react";

const QuizNavigationContext = React.createContext();

export const QuizNavigationContextProvider = ({ children }) => {
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);

  return (
    <QuizNavigationContext.Provider
      value={{
        showSettingsModal,
        setShowSettingsModal,
        showLeaveModal,
        setShowLeaveModal,
      }}
    >
      {children}
    </QuizNavigationContext.Provider>
  );
};

export default QuizNavigationContext;
