import React, { useContext } from "react";
import ResetQuizModal from "../../../modals/ResetQuizModal";
import QuizContext from "../../../../contexts/QuizContext";
import useResetQuizMutation from "../../../hooks/mutations/useResetQuizMutation";
import { navigate } from "gatsby";

const ResetQuizController = ({ onCancel }) => {
  const { publicQuizId } = useContext(QuizContext);

  const [resetQuizMutation] = useResetQuizMutation();

  const handleReset = async () => {
    await resetQuizMutation({
      variables: {
        publicQuizId,
      },
    });
    await navigate("/");
  };

  return <ResetQuizModal onReset={handleReset} onCancel={onCancel} />;
};

export default ResetQuizController;
