import React, { useContext, useState } from "react";
import QuizChatContext from "../../../contexts/QuizChatContext";
import styled from "styled-components";
import ChatWarningModal, { showWarning } from "../../modals/ChatWarningModal";
import FeatureFlagContext from "../../../contexts/FeatureFlagContext";
import TextInput from "./TextInput";
import SendButton from "./SendButton";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SendButtonWrapper = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
`;

export default () => {
  const { flags } = useContext(FeatureFlagContext);
  const { sendChatMessage } = useContext(QuizChatContext);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [value, setValue] = useState("");
  const [sending, setSending] = useState(false);

  const valueToSend = value.trim();
  const canSend = valueToSend.length > 0;

  const handleFocus = () => {
    if (flags["show-chat-warning"]) {
      if (showWarning()) {
        setShowWarningModal(true);
      }
    }
  };

  const handleClose = () => setShowWarningModal(false);

  const handleChange = async (e) => {
    setValue(e.target.value);
  };

  const handleSend = async () => {
    setSending(true);
    await sendChatMessage(valueToSend);
    setValue("");
    setSending(false);
  };

  return (
    <Wrapper>
      <TextInput
        value={value}
        canSend={canSend}
        sending={sending}
        onChange={handleChange}
        onFocus={handleFocus}
        onSend={handleSend}
      />
      <SendButtonWrapper>
        <SendButton canSend={canSend} onSend={handleSend} sending={sending} />
      </SendButtonWrapper>
      {showWarningModal && <ChatWarningModal onClose={handleClose} />}
    </Wrapper>
  );
};
