import React, { useContext } from "react";
import QuizContext from "../../../contexts/QuizContext";
import { QUIZ_CONVERSATION } from "../../../constants";
import styled from "styled-components";
import Progress from "../../../v2/components/progress/Progress";

const Wrapper = styled.section`
  width: 100%;
  margin-top: 20px;
`;

const ProgressWrapper = styled.div`
  padding: 0 30px;
`;

const ProgressSection = () => {
  const { whatToShow, otherEventQuizzes } = useContext(QuizContext);

  if (whatToShow === QUIZ_CONVERSATION && otherEventQuizzes) {
    const completedQuizzes = otherEventQuizzes.filter(
      (quiz) => quiz.whatToShow === QUIZ_CONVERSATION
    );

    const progress = (completedQuizzes.length / otherEventQuizzes.length) * 100;
    return (
      <Wrapper>
        <ProgressWrapper>
          <Progress
            leftMessage={`${completedQuizzes.length} of ${otherEventQuizzes.length} quizzes completed`}
            progress={progress}
          />
        </ProgressWrapper>
      </Wrapper>
    );
  }

  return null;
};

export default ProgressSection;
