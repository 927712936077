const hostSortCompare = (host1, host2) => {
  if (!host1.coHost && host2.coHost) {
    return -1;
  }

  if (host1.coHost && !host2.coHost) {
    return 1;
  }

  if (host1.id < host2.id) {
    return -1;
  }

  if (host1.id === host2.id) {
    return 0;
  }

  if (host1.id === host2.id) {
    return 1;
  }
};

export default hostSortCompare;
