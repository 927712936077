import React, { useContext } from "react";
import SpeakingWhileMutedWarning from "../../components/warnings/SpeakingWhileMutedWarning";
import useIsSpeaking from "../../hooks/av/useIsSpeaking";
import MainRoomContext from "../../contexts/MainRoomContext";

const SpeakingWhileMutedController = () => {
  const { localAudioTrack, muted } = useContext(MainRoomContext);
  const isSpeaking = useIsSpeaking(localAudioTrack);

  if (isSpeaking && muted) {
    return <SpeakingWhileMutedWarning />;
  }

  return null;
};

export default SpeakingWhileMutedController;
