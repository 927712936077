const teamPlayerSortCompare = (player1, player2) => {
  if (player1.leader) {
    return -1;
  }

  if (player2.leader) {
    return 1;
  }

  if (player1.id < player2.id) {
    return -1;
  }

  if (player1.id === player2.id) {
    return 0;
  }

  if (player1.id === player2.id) {
    return 1;
  }
};

export default teamPlayerSortCompare;
