import QuizActionButton from "../buttons/QuizActionButton";
import DangerButton from "../buttons/DangerButton";
import React, { useContext, useState } from "react";
import ResetQuizModal from "../modals/ResetQuizModal";
import QuizContext from "../../contexts/QuizContext";
import EndQuizEarlyModal from "../modals/EndQuizEarlyModal";

export default () => {
  const {
    quiz,
    nextRound,
    tiebreakerNext,
    tiebreakerPlayed,
    showTiebreaker,
    canResetQuiz,
    quizConversation,
  } = useContext(QuizContext);
  const moreRounds = quiz.roundNumber < quiz.numRounds;

  const [showEndQuizEarlyModal, setShowEndQuizEarlyModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const handleEndQuizEarlyClick = () => setShowEndQuizEarlyModal(true);
  const handleCancelEndQuizEarly = () => setShowEndQuizEarlyModal(false);

  const handleResetClick = () => setShowResetModal(true);
  const handleCancelReset = () => setShowResetModal(false);

  return (
    <>
      {moreRounds && !tiebreakerPlayed && (
        <QuizActionButton
          size="large"
          label="Next Round"
          clickedLabel="Next Round..."
          onClick={nextRound}
        />
      )}

      {moreRounds && !tiebreakerPlayed && (
        <DangerButton size="large" onClick={handleEndQuizEarlyClick}>
          End Quiz Early
        </DangerButton>
      )}

      {((!moreRounds && !tiebreakerNext) || tiebreakerPlayed) && (
        <QuizActionButton
          size="large"
          label="Quiz Conversation"
          clickedLabel="Quiz Conversation..."
          onClick={quizConversation}
        />
      )}

      {!moreRounds && tiebreakerNext && (
        <QuizActionButton
          size="large"
          label="Tiebreaker"
          clickedLabel="Tiebreaker..."
          onClick={showTiebreaker}
        />
      )}

      {showEndQuizEarlyModal && (
        <EndQuizEarlyModal onCancel={handleCancelEndQuizEarly} />
      )}
      {canResetQuiz && (
        <DangerButton size="large" onClick={handleResetClick}>
          Reset Quiz
        </DangerButton>
      )}
      {showResetModal && (
        <ResetQuizModal
          publicQuizId={quiz.publicId}
          onClose={handleCancelReset}
        />
      )}
    </>
  );
};
