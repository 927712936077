import { useEffect, useState } from "react";

const useDominantSpeaker = (room) => {
  const [dominantSpeaker, setDominantSpeaker] = useState(null);

  useEffect(() => {
    if (room) {
      const handleDominantSpeakerChanged = (newDominantSpeaker) => {
        if (newDominantSpeaker !== null) {
          setDominantSpeaker(newDominantSpeaker);
        }
      };

      const handleParticipantDisconnected = (oldParticipant) => {
        setDominantSpeaker((prevDominantSpeaker) => {
          return prevDominantSpeaker === oldParticipant
            ? null
            : prevDominantSpeaker;
        });
      };

      room.on("dominantSpeakerChanged", handleDominantSpeakerChanged);
      room.on("participantDisconnected", handleParticipantDisconnected);

      return () => {
        room.off("dominantSpeakerChanged", handleDominantSpeakerChanged);
        room.off("participantDisconnected", handleParticipantDisconnected);
      };
    }
  }, [room]);

  return dominantSpeaker;
};

export default useDominantSpeaker;
