import React from "react";
import styled from "styled-components";
import ActionBar from "../../ActionBar";

const Wrapper = styled.section`
  flex: 0;
`;

const ActionBarContainer = styled.div``;

export default () => (
  <Wrapper>
    <ActionBarContainer>
      <ActionBar />
    </ActionBarContainer>
  </Wrapper>
);
