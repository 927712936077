import teamPlayerSortCompare from "../../../util/teamPlayerSortCompare";
import SelfieController from "../SelfieController";
import React from "react";

const getTeamSelfies = (team, size) =>
  team.players &&
  team.players
    .sort(teamPlayerSortCompare)
    .map((player) => (
      <SelfieController
        key={player.id}
        id={player.id}
        name={player.name + (player.leader ? " (leader)" : "")}
        size={size}
      />
    ));

export default getTeamSelfies;
