import React, { useContext } from "react";
import QuizActionButton from "../buttons/QuizActionButton";
import QuizContext from "../../contexts/QuizContext";

export default () => {
  const { finishAssistingTeam } = useContext(QuizContext);

  const handleFinishClick = async () => {
    await finishAssistingTeam();
  };

  return (
    <>
      <QuizActionButton
        size="large"
        label="Finish Assisting"
        clickedLabel="Finishing Assisting"
        onClick={handleFinishClick}
      />
    </>
  );
};
