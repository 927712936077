import React, { useContext } from "react";
import QuizContext from "../../../../contexts/QuizContext";
import AudioController from "./index";

const getHostsNotAssistingTeamsAudio = () => {
  const { hosts } = useContext(QuizContext);

  return hosts
    .filter((host) => !host.assisting)
    .map((host) => <AudioController key={host.id} participantId={host.id} />);
};

export default getHostsNotAssistingTeamsAudio;
