import React from "react";
import styled from "styled-components";
import GradientCard from "../elements/cards/GradientCard";
import { PARAGRAPH_NORMAL_BOLD, TITLE_HERO } from "../../typography";
import { WHITE } from "../../colors";

const StyledCard = styled(GradientCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px;
`;

const RoundNumber = styled.div`
  color: ${WHITE};
  ${PARAGRAPH_NORMAL_BOLD}
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
`;

const Title = styled.div`
  ${TITLE_HERO};
  color: ${WHITE};
  text-align: center;
`;

const RoundIntro = ({ number, title }) => {
  return (
    <StyledCard>
      <RoundNumber>Round {number}</RoundNumber>
      <Title>{title}</Title>
    </StyledCard>
  );
};

export default RoundIntro;
