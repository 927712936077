import React from "react";

const AssistIcon = () => (
  <svg
    width="24"
    height="15"
    viewBox="0 0 24 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.8375 7.7545C24.0628 7.44589 24.053 7.02461 23.813 6.72579C20.1831 2.26316 16.2642 0 12.1592 0C5.19827 0 0.363346 6.48576 0.162503 6.76008C-0.0628327 7.06869 -0.0530355 7.48997 0.186996 7.78879C3.81197 12.2563 7.73085 14.5195 11.8359 14.5195C18.7968 14.5195 23.6317 8.03372 23.8375 7.7545ZM11.8359 12.8393C8.41666 12.8393 5.0856 10.9582 1.9211 7.25484C3.16045 5.79016 7.09893 1.68022 12.1592 1.68022C15.5784 1.68022 18.9095 3.56129 22.074 7.26464C20.8346 8.72932 16.8961 12.8393 11.8359 12.8393Z"
      fill="currentColor"
    />
    <path
      d="M11.9976 2.99316C9.64631 2.99316 7.73096 4.90852 7.73096 7.25985C7.73096 9.61118 9.64631 11.5265 11.9976 11.5265C14.349 11.5265 16.2643 9.61118 16.2643 7.25985C16.2643 4.90852 14.349 2.99316 11.9976 2.99316ZM11.9976 9.84632C10.5721 9.84632 9.41118 8.68535 9.41118 7.25985C9.41118 5.83436 10.5721 4.67339 11.9976 4.67339C13.4231 4.67339 14.5841 5.83436 14.5841 7.25985C14.5841 8.68535 13.4231 9.84632 11.9976 9.84632Z"
      fill="currentColor"
    />
  </svg>
);

export default AssistIcon;
