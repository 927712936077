import styled from "styled-components";
import React, { useContext } from "react";
import _ from "lodash";
import QuizContext from "../../contexts/QuizContext";
import { DEBUG } from "../../flags";
import Participant from "../NewParticipant";
import TeamRoomContext from "../../contexts/TeamRoomContext";
import HostControls from "./HostControls";
import { LIGHT_REGULAR_TEXT } from "../../colors";

const VideoColumnWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
`;

const ParticipantWrapper = styled.div`
  margin: 3px 0;
  position: relative;
`;

const HostControlsWrapper = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;

  ${ParticipantWrapper}:hover & {
    display: block;
  }
`;

const DebugWrapper = styled.div`
  color: ${LIGHT_REGULAR_TEXT};
  font-size: 14px;
`;

export default ({ players, size }) => {
  const { myHostId, myPlayerId, videoToken } = useContext(QuizContext);
  const { localParticipant, localVideoTrack, remoteParticipants } = useContext(
    TeamRoomContext
  );

  const getParticipant = (participantId) => {
    if (localParticipant && localParticipant.identity === participantId) {
      return localParticipant;
    } else {
      return remoteParticipants[participantId];
    }
  };

  const sortedPlayers = _.sortBy(players, [
    (player) => (player.leader ? 0 : 1),
    (player) => (player.id === myPlayerId ? 0 : 1),
    (player) => (player.name === "" ? "zzzzzzzzzzzz" : player.name),
  ]);

  return (
    <VideoColumnWrapper data-cy="video-column">
      {sortedPlayers.map((player) => (
        <ParticipantWrapper key={player.id}>
          {myHostId && !player.assisting && (
            <HostControlsWrapper>
              <HostControls player={player} />
            </HostControlsWrapper>
          )}
          <Participant
            participantId={player.id}
            participant={getParticipant(player.id)}
            localVideoTrack={localVideoTrack}
            name={player.name}
            videoPriority="high"
            fake={videoToken === "test"}
            image={player.image}
            size={size}
          />
        </ParticipantWrapper>
      ))}
      {DEBUG && (
        <DebugWrapper>
          <div style={{ fontSize: 14 }}>Player count: {players.length}</div>
          <div style={{ fontSize: 14 }}>Players:</div>
          {sortedPlayers.map((player) => (
            <div key={player.id} style={{ fontSize: 12 }}>
              {player.name} {player.id}
            </div>
          ))}
        </DebugWrapper>
      )}
    </VideoColumnWrapper>
  );
};
