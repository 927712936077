import React, { useContext, useState } from "react";
import Avatar from "../../../components/avatars/Avatar";
import useTrackPublications from "../../../hooks/av/useTrackPublications";
import useVideoTrackPublication from "../../../hooks/av/useVideoTrackPublication";
import useTrack from "../../../hooks/av/useTrack";
import useCallbackRef from "../../../hooks/useCallbackRef";
import useAttachVideoTrackEffect from "../../../hooks/av/useAttachVideoTrackEffect";
import Video from "../../../components/av/Video";
import useIsTrackEnabled from "../../../hooks/av/useIsTrackEnabled";
import MainRoomContext from "../../../contexts/MainRoomContext";
import useAudioTrackPublication from "../../../hooks/av/useAudioTrackPublications";
import useIsTrackStarted from "../../../hooks/av/useIsTrackStarted";
import { QUIZ_CONVERSATION } from "../../../constants";
import QuizContext from "../../../../contexts/QuizContext";
import SettingsContext from "../../../contexts/SettingsContext";
import useVideoTrackDimensions from "../../../hooks/av/useVideoTrackDimensions";
import useIsTrackSwitchedOff from "../../../hooks/av/useIsTrackSwitchedOff";
import useTrackPublicationPriority from "../../../hooks/av/useTrackPublicationPriority";
import useNetworkQualityLevel from "../../../hooks/av/useNetworkQualityLevel";
import { get } from "lodash";

const RemoteAvatarController = ({ host, player }) => {
  const { participants } = useContext(MainRoomContext);
  const { dominantSpeaker } = useContext(MainRoomContext);
  const { whatToShow } = useContext(QuizContext);
  const { debugAudioVideo } = useContext(SettingsContext);

  const participantId = (host && host.id) || (player && player.id);
  const participant = participants[participantId];

  const trackPublications = useTrackPublications(participant);
  const videoTrackPublication = useVideoTrackPublication(trackPublications);
  const videoTrack = useTrack(videoTrackPublication);
  const videoTrackEnabled = useIsTrackEnabled(videoTrack);
  const videoTrackStarted = useIsTrackStarted(videoTrack);

  const [videoEl, setVideoEl] = useState();
  const videoRef = useCallbackRef(setVideoEl);

  useAttachVideoTrackEffect(videoTrack, videoEl);

  const videoDimensions = useVideoTrackDimensions(videoTrack);
  const isVideoTrackSwitchedOff = useIsTrackSwitchedOff(videoTrack);
  const videoPublicationPriority = useTrackPublicationPriority(
    videoTrackPublication
  );
  const [networkQualityLevel, networkQualityStats] = useNetworkQualityLevel(
    participant
  );

  const audioTrackPublication = useAudioTrackPublication(trackPublications);
  const audioTrack = useTrack(audioTrackPublication);
  const audioTrackEnabled = useIsTrackEnabled(audioTrack);
  const audioTrackStarted = useIsTrackStarted(audioTrack);

  const isHost = Boolean(host);
  const name = (host && host.name) || (player && player.name);

  const hideLeader = whatToShow === QUIZ_CONVERSATION;

  const leader = player && player.leader && !hideLeader;

  return (
    <Avatar
      name={name}
      host={isHost}
      leader={leader}
      videoOff={!videoTrackEnabled || !videoTrackStarted}
      level={networkQualityLevel}
      muted={!audioTrackEnabled || !audioTrackStarted}
      talking={dominantSpeaker && dominantSpeaker.identity === participantId}
      debugData={
        debugAudioVideo && (
          <div>
            <div>
              Participant: {participant ? participant.identity : "none"}
            </div>
            {participant && (
              <>
                <div>
                  Track Switched Off:{" "}
                  {isVideoTrackSwitchedOff ? "true" : "false"}
                </div>
                <div>Priority: {videoPublicationPriority}</div>
                <div>
                  Dimensions:{" "}
                  {videoDimensions
                    ? `${videoDimensions.width}x${videoDimensions.height}`
                    : "unknown"}
                </div>
                <div>
                  Network Quality Level:{" "}
                  {networkQualityLevel || networkQualityLevel === 0
                    ? `${networkQualityLevel}/5`
                    : "unknown"}
                </div>
                {networkQualityStats && (
                  <>
                    <div>
                      Send Quality:{" "}
                      {get(networkQualityStats, "video.send", "?") || "?"}/5
                    </div>
                    <div>
                      Receive Quality:{" "}
                      {get(networkQualityStats, "video.recv", "?") || "?"}/5
                    </div>
                    {/*<div>*/}
                    {/*  Recv Bandwidth:{" "}*/}
                    {/*  {get(*/}
                    {/*    networkQualityStats,*/}
                    {/*    "video.recvStats.bandwidth.actual",*/}
                    {/*    "?"*/}
                    {/*  )}*/}
                    {/*  /*/}
                    {/*  {get(*/}
                    {/*    networkQualityStats,*/}
                    {/*    "video.recvStats.bandwidth.available",*/}
                    {/*    "?"*/}
                    {/*  )}*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*  Recv Fraction Lost:{" "}*/}
                    {/*  {get(*/}
                    {/*    networkQualityStats,*/}
                    {/*    "video.recvStats.fractionLost.fractionLost"*/}
                    {/*  ) || "none"}*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*  Recv Jitter:{" "}*/}
                    {/*  {get(networkQualityStats, "video.recvStats.latency.jitter") ||*/}
                    {/*    "none"}*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*  Send Bandwidth:{" "}*/}
                    {/*  {get(*/}
                    {/*    networkQualityStats,*/}
                    {/*    "video.sendStats.bandwidth.actual",*/}
                    {/*    "?"*/}
                    {/*  ) || "?"}*/}
                    {/*  /*/}
                    {/*  {get(*/}
                    {/*    networkQualityStats,*/}
                    {/*    "video.sendStats.bandwidth.available"*/}
                    {/*  ) || "?"}*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*  Send Fraction Lost:{" "}*/}
                    {/*  {get(*/}
                    {/*    networkQualityStats,*/}
                    {/*    "video.sendStats.fractionLost.fractionLost"*/}
                    {/*  ) || "none"}*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*  Send Jitter:{" "}*/}
                    {/*  {get(networkQualityStats, "video.sendStats.latency.jitter") ||*/}
                    {/*    "none"}*/}
                    {/*</div>*/}
                  </>
                )}
              </>
            )}
          </div>
        )
      }
    >
      <Video videoRef={videoRef} />
    </Avatar>
  );
};

export default RemoteAvatarController;
