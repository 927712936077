import React, { useContext } from "react";
import PageHeading from "../PageHeading";
import QuizContext from "../../contexts/QuizContext";

const NameTeamMyTeamNamed = () => {
  const { teams } = useContext(QuizContext);

  const teamsAreStillNaming = !!teams.find((team) => team.name === null);

  return (
    <PageHeading>
      {teamsAreStillNaming ? "Waiting for Team Names" : "Waiting to Start"}
    </PageHeading>
  );
};

export default NameTeamMyTeamNamed;
