import usePollAudioLevel from "./usePollAudioLevel";

const MIN_AUDIO_LEVEL = 7;

const useIsSpeaking = (audioTrack) => {
  const audioLevel = usePollAudioLevel(audioTrack);

  return audioLevel >= MIN_AUDIO_LEVEL;
};

export default useIsSpeaking;
