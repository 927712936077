import DangerButton from "../buttons/DangerButton";
import React, { useContext, useState } from "react";
import ResetQuizModal from "../modals/ResetQuizModal";
import QuizContext from "../../contexts/QuizContext";

export default () => {
  const { quiz, canResetQuiz } = useContext(QuizContext);

  const [showResetModal, setShowResetModal] = useState(false);

  const handleResetClick = () => setShowResetModal(true);
  const handleCancelReset = () => setShowResetModal(false);

  return (
    <>
      {canResetQuiz && (
        <DangerButton size="large" onClick={handleResetClick}>
          Reset Quiz
        </DangerButton>
      )}
      {showResetModal && (
        <ResetQuizModal
          publicQuizId={quiz.publicId}
          onClose={handleCancelReset}
        />
      )}
    </>
  );
};
