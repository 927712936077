import React, { useContext } from "react";
import styled from "styled-components";
import Host from "../../Host";
import QuizContext from "../../../contexts/QuizContext";
import { useMediaQuery } from "react-responsive/src";
import { LARGE_QUERY, SMALL_QUERY, XLARGE_QUERY } from "../../responsive";
import {
  XLARGE,
  LARGE,
  MEDIUM,
  XLARGE_WIDTH,
  LARGE_WIDTH,
  MEDIUM_WIDTH,
  SMALL,
  SMALL_WIDTH,
} from "../../../dimensions";

const Wrapper = styled.div`
  width: ${MEDIUM_WIDTH}px;
  margin: 20px 30px;
  display: flex;
  ${({ size }) =>
    size === XLARGE &&
    `
    width: ${XLARGE_WIDTH}px;
  `}
  ${({ size }) =>
    size === LARGE &&
    `
    width: ${LARGE_WIDTH}px;
  `}
  ${({ size }) =>
    size === SMALL &&
    `
    width: ${SMALL_WIDTH}px;
  `}
  ${({ hide }) =>
    hide &&
    `
    display: none;
  `}
`;

export default ({ coHost, hide }) => {
  const { quiz } = useContext(QuizContext);
  const isXLarge = useMediaQuery(XLARGE_QUERY);
  const isLarge = useMediaQuery(LARGE_QUERY);
  const isSmall = useMediaQuery(SMALL_QUERY);

  let size = MEDIUM;

  if (isXLarge) {
    size = XLARGE;
  } else if (isLarge) {
    size = LARGE;
  } else if (isSmall) {
    size = SMALL;
  }

  return (
    <Wrapper hide={hide} size={size}>
      <Host coHost={coHost} quiz={quiz} size={size} />
    </Wrapper>
  );
};
