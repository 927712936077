import React, { useContext } from "react";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import TiebreakerAnswersScreen from "../../../screens/quiz/13/TiebreakerAnswersScreen";
import getAllAudio from "../AudioController/getAllAudio";
import QuizContext from "../../../../contexts/QuizContext";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import useShowTiebreakerResultsMutation from "../../../hooks/mutations/useShowTiebreakerResultsMutation";
import TeamController from "../TeamController";
import getHostAvatarControllerList from "../AvatarControllers/getHostAvatarControllerList";

const TiebreakerAnswersController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const { tiebreaker, teamsInFirstPlace, tiebreakerGraded } = useContext(
    QuizContext
  );

  const [showTiebreakerResultsMutation] = useShowTiebreakerResultsMutation();

  const handleShowTiebreakerResults = async () =>
    await showTiebreakerResultsMutation();

  return (
    <TiebreakerAnswersScreen
      audio={getAllAudio()}
      isHost={Boolean(myHostId)}
      hostAvatars={getHostAvatarControllerList()}
      tiebreakerText={tiebreaker.text}
      tiebreakerAnswerText={tiebreaker.answer}
      teamsInTiebreaker={
        teamsInFirstPlace &&
        teamsInFirstPlace.map((team) => (
          <TeamController key={team.id} team={team} inTiebreaker showAnswer />
        ))
      }
      onShowTiebreakerResults={handleShowTiebreakerResults}
      tiebreakerGraded={tiebreakerGraded}
      resetButton={resetButton}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
    />
  );
};

export default TiebreakerAnswersController;
