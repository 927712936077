import React, { useContext, useRef, useState } from "react";
import Modal from "../Modal";
import Button from "../buttons/Button";
import PrimaryButton from "../buttons/PrimaryButton";
import { Formik } from "formik";
import Form from "../Form";
import FormField from "../FormField";
import Label from "../Label";
import Input from "../Input";
import FormError from "../FormError";
import Emoji from "../Emoji";
import useOnClickOutside from "use-onclickoutside";
import styled from "styled-components";
import QuizContext from "../../contexts/QuizContext";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

const EmojiFieldWrapper = styled.div`
  min-width: 300px;
  max-width: 400px;
`;

const NoneSelected = styled.div`
  font-size: 16px;
  color: #aaa;
  text-decoration: italic;
  height: 60px;
  display: flex;
  align-items: center;
`;

const PickerWrapper = styled.div`
  position: absolute;
`;

const ChooseWrapper = styled.div`
  margin-top: 10px;
  font-size: 16px;

  a {
    color: #424fc1;
  }

  a:hover {
    cursor: pointer;
  }
`;

export default ({ onCreate, onCancel }) => {
  const { createTeam } = useContext(QuizContext);

  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef();

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "You must enter a team name";
    }
    if (!values.emoji) {
      errors.emoji = "You must pick an emoji";
    }
    return errors;
  };

  const handleChooseClick = () => {
    setShowPicker(true);
  };

  const handleClickOutside = () => {
    setShowPicker(false);
  };

  useOnClickOutside(pickerRef, handleClickOutside);

  const handleCreate = async (values) => {
    await createTeam(values.name, values.emoji);
    // await setVideoStopped(false);
    // await setMuted(false);
    onCreate({ name: values.name, emoji: values.emoji });
  };

  return (
    <Formik
      initialValues={{ name: "", emoji: "" }}
      validate={validate}
      onSubmit={handleCreate}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Modal
            title="Create a New Team"
            body={
              <>
                <FormField>
                  <Label>Team Name</Label>
                  <Input
                    name="name"
                    autoFocus
                    autoComplete="off"
                    value={values.name}
                    onChange={handleChange}
                  />
                  {errors.name && touched.name && (
                    <FormError>{errors.name}</FormError>
                  )}
                </FormField>
                <FormField>
                  <Label>Team Emoji</Label>
                  <EmojiFieldWrapper>
                    {values.emoji ? (
                      <Emoji size="large" nobackground emoji={values.emoji} />
                    ) : (
                      <NoneSelected>none selected</NoneSelected>
                    )}

                    <ChooseWrapper>
                      <a onClick={handleChooseClick}>
                        {values.emoji ? (
                          <span>Choose a different emoji</span>
                        ) : (
                          <span>Choose an emoji</span>
                        )}
                      </a>
                    </ChooseWrapper>
                    {showPicker && (
                      <PickerWrapper ref={pickerRef}>
                        <Picker
                          title="Pick a Good One!"
                          sheetSize={64}
                          // style={{ position: "absolute", bottom: "26px" }}
                          onSelect={(emoji) => {
                            setFieldValue("emoji", emoji.native);
                            setShowPicker(false);
                          }}
                        />
                      </PickerWrapper>
                    )}
                  </EmojiFieldWrapper>
                  {errors.emoji && touched.emoji && (
                    <FormError>{errors.emoji}</FormError>
                  )}
                </FormField>
              </>
            }
            buttons={
              <>
                <PrimaryButton
                  type="submit"
                  size="large"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Creating" : "Create Team"}
                </PrimaryButton>
                <Button size="large" onClick={onCancel}>
                  Cancel
                </Button>
              </>
            }
          />
        </Form>
      )}
    </Formik>
  );
};
