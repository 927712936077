import React from "react";
import PageHeading from "../PageHeading";
import styled from "styled-components";
import Emoji from "../Emoji";
import InstructionText from "./InstructionText";
import { LIGHT_REGULAR_TEXT } from "../../colors";

const TeamExampleList = styled.div`
  margin: 20px 0;
`;

const TeamExample = styled.div`
  margin: 10px 0 10px;
  font-size: 16px;
  font-weight: normal;
  display: flex;
  align-items: center;
  color: ${LIGHT_REGULAR_TEXT};
`;

const EmojiWrapper = styled.div`
  margin-right: 10px;
`;

const NameTeamPlayer = () => {
  return (
    <>
      <PageHeading>Help Choose a Team Name</PageHeading>

      <>
        <InstructionText>
          You are player on this team, so you and your fellow teammates need to
          come up with a good name. Some examples for inspiration:
        </InstructionText>
        <TeamExampleList>
          <TeamExample>
            <EmojiWrapper>
              <Emoji size="small" emoji="💃" />
            </EmojiWrapper>
            Trivia Newton John
          </TeamExample>
          <TeamExample>
            <EmojiWrapper>
              <Emoji size="small" emoji="🔨" />
            </EmojiWrapper>
            E=MC Hammer
          </TeamExample>
          <TeamExample>
            <EmojiWrapper>
              <Emoji size="small" emoji="👶" />
            </EmojiWrapper>
            Quiz Me Baby One More Time
          </TeamExample>
        </TeamExampleList>
        <InstructionText>
          Your team leader will enter the name and emoji.
        </InstructionText>
      </>
    </>
  );
};

export default NameTeamPlayer;
