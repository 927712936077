import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import getLogger from "../../../util/getLogger";
import EventLoggerContext from "../../../contexts/EventLoggerContext";
import { LOG_EVENTS } from "../../../flags";

const Video = styled.video`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ hide }) =>
    hide &&
    `
    display: none;
  `}
`;

const eventList = [
  "abort",
  "canplay",
  "canplaythrough",
  "durationchange",
  "emptied",
  "ended",
  "error",
  "interruptbegin",
  "interruptend",
  "loadeddata",
  "loadedmetadata",
  "loadstart",
  "mozaudioavailable",
  "pause",
  "play",
  "playing",
  // "progress",
  "ratechange",
  "seeked",
  "seeking",
  "stalled",
  "suspend",
  // "timeupdate",
  "volumechange",
  "waiting",
];

const MonitoredVideo = React.forwardRef((props, ref) => {
  const { getStreamId, logEvent } = useContext(EventLoggerContext);
  const [streamId, setStreamId] = useState();

  const logger = getLogger(`MonitoredVideo: ${props.identity}`);

  useEffect(() => {
    setStreamId(getStreamId());
  }, []);

  useEffect(() => {
    if (LOG_EVENTS) {
      logger.info("Video created");

      const eventLogger = (event) => {
        logEvent(streamId, event);
      };

      const vid = ref.current;

      eventList.forEach((eventName) =>
        vid.addEventListener(eventName, eventLogger)
      );

      return () => {
        const quality = ref.current.getVideoPlaybackQuality();
        logger.info("quality", quality);

        eventList.forEach((eventName) =>
          vid.removeEventListener(eventName, eventLogger)
        );

        logger.info("Video destroyed");
      };
    }
  }, [streamId]);

  return <Video ref={ref} {...props} />;
});

export default MonitoredVideo;
