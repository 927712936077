import React, { useEffect } from "react";
import styled from "styled-components";
import getLogger from "../../util/getLogger";
import pollAudioLevel from "../../util/pollAudioLevel";
import { useThrottle } from "@react-hook/throttle";

const logger = getLogger("LocalParticipant");

const MIN_AUDIO_LEVEL = 7;

const Wrapper = styled.div`
  display: none;
`;

const LocalParticipant = ({
  localParticipant,
  localAudioTrack,
  localVideoTrack,
  muted,
  videoStopped,
  setLocalSpeaking,
}) => {
  const [audioLevel, setAudioLevel] = useThrottle(0, 5);

  useEffect(() => {
    const doAction = () => {
      try {
        if (localAudioTrack) {
          if (muted) {
            localParticipant.unpublishTrack(localAudioTrack);
            logger.info("Unpublished audio track");
            logger.debug(localAudioTrack);
          } else {
            localParticipant.publishTrack(localAudioTrack);
            logger.info("Published audio track");
            logger.debug(localAudioTrack);
          }
        }
      } catch (err) {
        logger.debug("Error", err);
        logger.debug("Error message", err.message);
        if (err.message === "LocalParticipant disconnected") {
          logger.debug("LocalParticpant is disconnected, we can ignore");
        } else {
          throw err;
        }
      }
    };

    doAction();
  }, [localAudioTrack, muted]);

  useEffect(() => {
    const doAction = async () => {
      try {
        if (localVideoTrack) {
          if (videoStopped) {
            await localVideoTrack.disable();
            logger.info("Disabled video track");
            await localParticipant.unpublishTrack(localVideoTrack);
            logger.info("Unpublished video track");
            logger.debug(localVideoTrack);
          } else {
            await localVideoTrack.enable();
            logger.info("Enabled video track");
            await localParticipant.publishTrack(localVideoTrack);
            logger.info("Published video track");
            logger.debug(localVideoTrack);
          }
        }
      } catch (err) {
        logger.debug("Error", err);
        logger.debug("Error message", err.message);
        if (err.message === "LocalParticipant disconnected") {
          logger.debug("LocalParticpant is disconnected, we can ignore");
        } else {
          throw err;
        }
      }
    };

    doAction();
  }, [localVideoTrack, videoStopped]);

  useEffect(() => {
    async function monitorAudioLevel() {
      await pollAudioLevel(localAudioTrack, (level) => {
        setAudioLevel(level);
      });
    }

    if (localAudioTrack) {
      monitorAudioLevel();
    }
  }, [localAudioTrack]);

  useEffect(() => {
    if (audioLevel >= MIN_AUDIO_LEVEL) {
      setLocalSpeaking(true);
    } else {
      setLocalSpeaking(false);
    }
  }, [audioLevel]);

  return <Wrapper />;
};

export default LocalParticipant;
