import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MainRoomContext from "../../../../contexts/MainRoomContext";
import Participant from "../../../NewParticipant";
import { SMALL, SMALL_HEIGHT, SMALL_WIDTH } from "../../../../dimensions";
import { NAME_TEAMS, QUESTION, TIEBREAKER } from "../../../../constants";
import QuizContext from "../../../../contexts/QuizContext";
import getLogger from "../../../../util/getLogger";
import { LIGHT_GREY_4, REGULAR_TEXT } from "../../../../colors";

const Wrapper = styled.div`
  width: ${SMALL_WIDTH}px;
  height: ${SMALL_HEIGHT + 20}px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  ${({ hide }) =>
    hide &&
    `
    display: none;
  `}
`;

const Placeholder = styled.div`
  width: ${SMALL_WIDTH}px;
  height: ${SMALL_HEIGHT + 20}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${LIGHT_GREY_4};
  border-radius: 10px;
  color: ${REGULAR_TEXT};
`;

const PlaceholderEmoji = styled.div`
  font-size: 48px;
`;

const ParticipantVideoWrapper = styled.div`
  width: ${SMALL_WIDTH}px;
  display: none;
  ${({ show }) =>
    show &&
    `
    display: block;
  `}
`;

const TeamSpeaker = ({ team }) => {
  const logger = getLogger(`TeamSpeaker: ${team.name}`);

  const { whatToShow, myPlayerId, delayingMainRoomJoin } = useContext(
    QuizContext
  );
  const {
    participantsWithVideo,
    dominantSpeaker,
    localParticipant,
    localVideoTrack,
    localSpeaking,
    muted,
  } = useContext(MainRoomContext);

  const [lastDominantSpeakerOnTeam, setLastDominantSpeakerOnTeam] = useState(
    null
  );

  const dominantSpeakerIdentity = dominantSpeaker && dominantSpeaker.identity;

  const lookupTeamPlayer = (identity) =>
    team.players.find((player) => player.id === identity);

  const teamLeader = team.players.find((player) => player.leader);
  const teamLeaderId = teamLeader && teamLeader.id;

  const participantsToRender = Object.values(
    participantsWithVideo
  ).filter((participant) => lookupTeamPlayer(participant.identity));
  const lookupSelf = lookupTeamPlayer(myPlayerId);
  if (lookupSelf) {
    if (localParticipant) {
      participantsToRender.push(localParticipant);
    }
  }

  useEffect(() => {
    if (lookupTeamPlayer(myPlayerId)) {
      setLastDominantSpeakerOnTeam(myPlayerId);
    } else {
      setLastDominantSpeakerOnTeam(teamLeaderId);
    }
  }, []);

  useEffect(() => {
    if (lookupTeamPlayer(dominantSpeakerIdentity)) {
      setLastDominantSpeakerOnTeam(dominantSpeakerIdentity);
      logger.debug("dominant speaker:", dominantSpeakerIdentity);
    }
  }, [dominantSpeakerIdentity]);

  useEffect(() => {
    if (lookupTeamPlayer(myPlayerId)) {
      if (localSpeaking && !muted) {
        setLastDominantSpeakerOnTeam(myPlayerId);
        logger.debug("dominant speaker: localAudio");
      }
    }
  }, [muted, localSpeaking]);

  const hide =
    [NAME_TEAMS, QUESTION, TIEBREAKER].includes(whatToShow) ||
    delayingMainRoomJoin;

  const visibleParticipants = () => {
    if (
      participantsToRender.find(
        (participant) => participant.identity === lastDominantSpeakerOnTeam
      )
    ) {
      return true;
    }

    return false;
  };

  return (
    <Wrapper hide={hide}>
      {!visibleParticipants() && (
        <Placeholder>
          <PlaceholderEmoji>{team.emoji}</PlaceholderEmoji>
        </Placeholder>
      )}
      {participantsToRender.map((participant) => {
        const player = lookupTeamPlayer(participant.identity);
        return (
          <ParticipantVideoWrapper
            key={participant.identity}
            show={participant.identity === lastDominantSpeakerOnTeam}
          >
            <Participant
              participant={participant}
              localVideoTrack={localVideoTrack}
              name={player.name}
              size={SMALL}
            />
          </ParticipantVideoWrapper>
        );
      })}
    </Wrapper>
  );
};

export default TeamSpeaker;
