import React, { useContext } from "react";
import Quiz from "../components/Quiz";
import { SessionDataContextProvider } from "../contexts/SessionDataContext";
import { QuizContextProvider } from "../contexts/QuizContext";
import { EventLoggerContextProvider } from "../contexts/EventLoggerContext";
import { NetworkQualityStatsLoggerContextProvider } from "../contexts/NetworkQualityStatsLoggerContext";
import FeatureFlagContext from "../contexts/FeatureFlagContext";
import QuizController from "../v2/controllers/QuizController";
import { QuizChatContextProvider } from "../contexts/QuizChatContext";
import inTesting from "../util/inTesting";

export default function PlayQuiz({ location }) {
  const { flags, flagsReady } = useContext(FeatureFlagContext);

  if (!flagsReady && !inTesting()) {
    return null;
  }

  const v2 = flags["v2"];

  return (
    <SessionDataContextProvider>
      <QuizContextProvider location={location}>
        <QuizChatContextProvider>
          <EventLoggerContextProvider>
            <NetworkQualityStatsLoggerContextProvider>
              {v2 && <QuizController />}
              {!v2 && <Quiz />}
            </NetworkQualityStatsLoggerContextProvider>
          </EventLoggerContextProvider>
        </QuizChatContextProvider>
      </QuizContextProvider>
    </SessionDataContextProvider>
  );
}
