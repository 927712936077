import React from "react";
import styled from "styled-components";
import { OVERLAY_1 } from "../../../colors";
import HeaderArea from "../../HeaderArea";

const Wrapper = styled.header`
  flex-shrink: 0;
  min-height: 60px;
  width: 100%;
  background-color: ${OVERLAY_1};
  display: flex;
  align-items: center;
`;

export default () => {
  return (
    <Wrapper>
      <HeaderArea />
    </Wrapper>
  );
};
