import { useContext } from "react";
import QuizContext from "../../../../contexts/QuizContext";
import getAssistantsAudio from "./getAssistantsAudio";

const getHostsAssistingMyTeamAudio = () => {
  const { assistants } = useContext(QuizContext);

  if (assistants) {
    return getAssistantsAudio(assistants);
  }

  return [];
};

export default getHostsAssistingMyTeamAudio;
