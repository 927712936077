import QuizActionButton from "../buttons/QuizActionButton";
import DangerButton from "../buttons/DangerButton";
import React, { useContext, useState } from "react";
import ResetQuizModal from "../modals/ResetQuizModal";
import QuizContext from "../../contexts/QuizContext";

export default () => {
  const { quiz, closeTiebreaker, canResetQuiz } = useContext(QuizContext);

  const [showResetModal, setShowResetModal] = useState();

  const handleResetClick = () => {
    setShowResetModal(true);
  };

  const handleCancelReset = () => {
    setShowResetModal(false);
  };

  return (
    <>
      <QuizActionButton
        size="large"
        label="Close Tiebreaker"
        clickedLabel="Closing Tiebreaker"
        onClick={closeTiebreaker}
      />
      {canResetQuiz && (
        <DangerButton size="large" onClick={handleResetClick}>
          Reset Quiz
        </DangerButton>
      )}
      {showResetModal && (
        <ResetQuizModal
          publicQuizId={quiz.publicId}
          onClose={handleCancelReset}
        />
      )}
    </>
  );
};
