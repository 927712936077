import getRoomContextProvider from "./getRoomContextProviderFunc";
import { createContext } from "react";

const TeamRoomContext = createContext(null);

export const TeamRoomContextProvider = getRoomContextProvider(
  TeamRoomContext,
  "TeamRoom"
);

export default TeamRoomContext;
