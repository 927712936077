import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import TeamGridLayout from "../../../components/layouts/TeamGridLayout";
import styled from "styled-components";
import DisabledButtonTooltip from "../../../components/elements/tooltips/DisabledButtonTooltip";
import GridVerticalScrollLayout from "../../../components/layouts/GridVerticalScrollLayout";
import CompactQuestion from "../../../components/questions/CompactQuestion";

const QuestionWrapper = styled.div`
  margin-bottom: 10px;
`;

const TiebreakerAnswersScreen = ({
  audio,
  isHost,
  hostAvatars,
  tiebreakerText,
  tiebreakerAnswerText,
  teamsInTiebreaker,
  tiebreakerGraded,
  onShowTiebreakerResults,
  resetButton,
  navigation,
  chat,
  modal,
}) => {
  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        hostAvatars={hostAvatars}
        navigation={navigation}
        chat={chat}
        payload={
          <GridVerticalScrollLayout
            header={
              <QuestionWrapper>
                <CompactQuestion
                  text={tiebreakerText}
                  answerText={isHost && tiebreakerAnswerText}
                  answerCorrect
                />
              </QuestionWrapper>
            }
            content={<TeamGridLayout teams={teamsInTiebreaker} />}
          />
        }
        actionBar={
          isHost && (
            <ActionBar>
              <DisabledButtonTooltip
                disabled={!tiebreakerGraded}
                content={"You must select the closest answer first"}
              >
                <LargeDarkButton
                  disabled={!tiebreakerGraded}
                  onClick={onShowTiebreakerResults}
                  data-cy-name={"Tiebreaker Results"}
                >
                  Tiebreaker Results
                </LargeDarkButton>
              </DisabledButtonTooltip>
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default TiebreakerAnswersScreen;
