import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import styled from "styled-components";
import Progress from "../../../components/progress/Progress";
import NameTeamCard from "../../../components/naming/NameTeamCard";
import AllTeamsAndNames from "../../../components/naming/AllTeamsAndNames";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import getTimeLeftText from "../../../util/getTimeLeftText";
import ScreenTitle from "../../../components/titles/ScreenTitle";

const NAME_TEAMS_TIME = 180;

const PayloadWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content min-content min-content 1fr;
  padding: 0 15px 0 10px;
`;

const ProgressWrapper = styled.div`
  margin-bottom: 10px;
`;

const NameTeamCardWrapper = styled.div`
  margin-bottom: 20px;
`;

const AllTeamsAndNamesWrapper = styled.div`
  margin-bottom: 20px;
`;

const TeamBreakoutWrapper = styled.div``;

const NameTeamsScreen = ({
  audio,
  isHost,
  quizName,
  leader,
  leaderName,
  emoji,
  teamName,
  thisTeamNamed,
  allTeams,
  timeLeft,
  teamBreakout,
  emojiPicker,
  onShowEmojiPicker,
  onTeamNameChange,
  onSubmitNameAndEmoji,
  submitting,
  onMeetTeams,
  resetButton,
  modal,
  navigation,
  chat,
}) => {
  const progress = ((NAME_TEAMS_TIME + 1 - timeLeft) / NAME_TEAMS_TIME) * 100;

  let urgency = "normal";
  if (timeLeft <= 30) {
    urgency = "warning";
  }
  if (timeLeft <= 10) {
    urgency = "danger";
  }

  let progressMessage = "Choose a team name";
  if (thisTeamNamed) {
    progressMessage = "Waiting for other teams";
  } else if (isHost) {
    progressMessage = "Waiting for teams";
  }

  let timeLeftMessage = getTimeLeftText(timeLeft);
  if (timeLeft <= 0) {
    timeLeftMessage = "Naming closed";
  }

  let location;
  if (isHost) {
    location = "Waiting for Team Names";
  } else if (thisTeamNamed) {
    location = "Waiting for Other Team Names";
  } else if (leader) {
    location = "Name Your Team";
  } else {
    location = "Name Your Team";
  }

  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        navigation={navigation}
        chat={chat}
        payload={
          <PayloadWrapper>
            <ScreenTitle quizName={quizName} location={location} />

            <ProgressWrapper>
              <Progress
                leftMessage={progressMessage}
                rightMessage={
                  ![null, undefined].includes(timeLeft) && timeLeftMessage
                }
                progress={progress}
                urgency={urgency}
              />
            </ProgressWrapper>
            {!thisTeamNamed && !isHost && (
              <NameTeamCardWrapper>
                <NameTeamCard
                  leader={leader}
                  leaderName={leaderName}
                  emoji={emoji}
                  teamName={teamName}
                  onTeamNameChange={onTeamNameChange}
                  onShowEmojiPicker={onShowEmojiPicker}
                  emojiPicker={emojiPicker}
                  onSubmitNameAndEmoji={onSubmitNameAndEmoji}
                  submitting={submitting}
                />
              </NameTeamCardWrapper>
            )}
            {(thisTeamNamed || isHost) && (
              <AllTeamsAndNamesWrapper>
                <AllTeamsAndNames teams={allTeams} />
              </AllTeamsAndNamesWrapper>
            )}
            {teamBreakout && (
              <TeamBreakoutWrapper>{teamBreakout}</TeamBreakoutWrapper>
            )}
          </PayloadWrapper>
        }
        actionBar={
          isHost && (
            <ActionBar>
              <LargeDarkButton
                data-cy={"meet-teams-button"}
                onClick={onMeetTeams}
              >
                Meet Teams
              </LargeDarkButton>
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default NameTeamsScreen;
