import React, { useContext, useRef, useState } from "react";
import PageHeading from "../PageHeading";
import styled from "styled-components";
import QuizContext from "../../contexts/QuizContext";
import useOnClickOutside from "use-onclickoutside";
import { Formik } from "formik";
import Form from "../Form";
import FormField from "../FormField";
import Input from "../Input";
import FormError from "../FormError";
import Emoji from "../Emoji";
import { Picker } from "emoji-mart";
import InstructionText from "./InstructionText";
import { ACCENT_3, LIGHT_REGULAR_TEXT } from "../../colors";
import LightLabel from "../LightLabel";
import DarkButton from "../buttons/DarkButton";

const TeamExampleList = styled.div`
  margin: 20px 0;
`;

const TeamExample = styled.div`
  margin: 10px 0 10px;
  font-size: 16px;
  font-weight: normal;
  display: flex;
  align-items: center;
  color: ${LIGHT_REGULAR_TEXT};
`;

const EmojiWrapper = styled.div`
  margin-right: 10px;
`;

const EmojiFieldWrapper = styled.div`
  min-width: 300px;
  max-width: 400px;
`;

// const NoneSelected = styled.div`
//   font-size: 16px;
//   color: ${LIGHT_MUTED_TEXT};
//   text-decoration: italic;
//   height: 60px;
//   display: flex;
//   align-items: center;
// `;

const PickerWrapper = styled.div`
  position: absolute;
`;

const ChooseWrapper = styled.div`
  margin-top: 10px;
  font-size: 16px;

  a {
    color: ${ACCENT_3};
  }

  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const NameTeamLeader = () => {
  const { nameTeam } = useContext(QuizContext);

  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef();

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "You must enter a team name";
    }
    if (!values.emoji) {
      errors.emoji = "You must pick an emoji";
    }
    return errors;
  };

  const handleChooseClick = () => {
    setShowPicker(true);
  };

  const handleClickOutside = () => {
    setShowPicker(false);
  };

  useOnClickOutside(pickerRef, handleClickOutside);

  const handleName = async (values) => {
    await nameTeam(values.name, values.emoji);
  };

  return (
    <>
      <PageHeading>Choose a Team Name</PageHeading>

      <>
        <InstructionText>
          You are the leader of this team, so you are in charge of entering the
          name and selecting an emoji. Some examples for inspiration:
        </InstructionText>
        <TeamExampleList>
          <TeamExample>
            <EmojiWrapper>
              <Emoji size="small" dark emoji="💃" />
            </EmojiWrapper>
            Trivia Newton John
          </TeamExample>
          <TeamExample>
            <EmojiWrapper>
              <Emoji size="small" dark emoji="🔨" />
            </EmojiWrapper>
            E=MC Hammer
          </TeamExample>
          <TeamExample>
            <EmojiWrapper>
              <Emoji size="small" dark emoji="👶" />
            </EmojiWrapper>
            Quiz Me Baby One More Time
          </TeamExample>
        </TeamExampleList>
      </>

      <Formik
        initialValues={{ name: "", emoji: "" }}
        validate={validate}
        onSubmit={handleName}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <>
              <FormField>
                <LightLabel>Team Name</LightLabel>
                <Input
                  name="name"
                  autoFocus
                  autoComplete="off"
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name && touched.name && (
                  <FormError>{errors.name}</FormError>
                )}
              </FormField>
              <FormField>
                <LightLabel>Team Emoji</LightLabel>
                <EmojiFieldWrapper>
                  {values.emoji && (
                    <Emoji size="large" nobackground emoji={values.emoji} />
                  )}

                  <ChooseWrapper>
                    <a onClick={handleChooseClick}>
                      {values.emoji ? (
                        <span>Choose an emoji</span>
                      ) : (
                        <span>Choose an emoji</span>
                      )}
                    </a>
                  </ChooseWrapper>
                  {showPicker && (
                    <PickerWrapper ref={pickerRef}>
                      <Picker
                        title="Pick a Good One!"
                        sheetSize={64}
                        style={{ position: "absolute", left: 130, top: -213 }}
                        onSelect={(emoji) => {
                          setFieldValue("emoji", emoji.native);
                          setShowPicker(false);
                        }}
                      />
                    </PickerWrapper>
                  )}
                </EmojiFieldWrapper>
                {errors.emoji && touched.emoji && (
                  <FormError>{errors.emoji}</FormError>
                )}
              </FormField>
              <DarkButton
                id="name-team-button"
                type="submit"
                size="large"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting" : "Submit"}
              </DarkButton>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NameTeamLeader;
