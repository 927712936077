import React from "react";
import {
  Wrapper,
  Title,
  InstructionList,
  Instruction,
  InstructionHeading,
  SubInstructionList,
  SubInstruction,
  SubInstructionHeading,
} from "./sharedComponents";

export default () => {
  return (
    <Wrapper>
      <Title>Host Notes</Title>
      <InstructionList>
        <Instruction>
          <InstructionHeading>
            You will be split up into teams.
          </InstructionHeading>
          <SubInstructionList type="a">
            <SubInstruction>
              <SubInstructionHeading>
                Your first task will be to come up with a team name and choose
                an emoji.
              </SubInstructionHeading>
            </SubInstruction>
            <SubInstruction>
              <SubInstructionHeading>
                Team leads will be those at the top of the team column and will
                submit each answer starting with the team name.
              </SubInstructionHeading>
            </SubInstruction>
            <SubInstruction>
              <SubInstructionHeading>
                You will have 3 minutes to come up with one. If you don&apos;t
                click the Submit button before that,{" "}
                <b>the game will choose one for you</b>.
              </SubInstructionHeading>
            </SubInstruction>
          </SubInstructionList>
        </Instruction>
        <Instruction>
          <InstructionHeading>
            Team rooms are private but the chat is available to everyone at all
            times.
          </InstructionHeading>
        </Instruction>
        <Instruction>
          <InstructionHeading>
            I’ll pop in briefly to each team to make sure there are no issues.
          </InstructionHeading>
        </Instruction>
      </InstructionList>
    </Wrapper>
  );
};
