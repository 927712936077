import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import Progress from "../../../components/progress/Progress";
import styled from "styled-components";
import getTimeLeftText from "../../../util/getTimeLeftText";
import Question from "../../../components/questions/Question";
import AllTeamsAndNames from "../../../components/naming/AllTeamsAndNames";
import { ELEMENT_OFF_WHITE_DARK } from "../../../colors";

const PayloadWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content min-content 1fr;
  padding: 0 15px 0 10px;
`;

const QuestionWrapper = styled.div`
  margin-bottom: 20px;
`;

const AllTeamsAndNamesWrapper = styled.div`
  margin-bottom: 20px;
`;

const ProgressWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const ProgressAnswerWrapper = styled.span`
  margin-left: 7px;
  color: ${ELEMENT_OFF_WHITE_DARK};
`;

const TeamBreakoutWrapper = styled.div``;

const TiebreakerScreen = ({
  audio,
  isHost,
  questionText,
  answerText,
  answerValue,
  isLeader,
  leaderName,
  thisTeamInTiebreaker,
  thisTeamAnswered,
  teamBreakout,
  allTeamsInTiebreaker,
  timeLeft,
  onAnswerChange,
  onSubmitAnswer,
  onDismiss,
  dismissed,
  onCloseTiebreaker,
  resetButton,
  navigation,
  chat,
  modal,
}) => {
  let showTiebreaker = true;
  let showAllTeams = false;
  if (isHost) {
    if (timeLeft <= 50 || dismissed) {
      showTiebreaker = false;
      showAllTeams = true;
    }
  } else if (!thisTeamInTiebreaker) {
    showTiebreaker = false;
    showAllTeams = true;
  } else {
    if (thisTeamAnswered) {
      showTiebreaker = false;
      showAllTeams = true;
    }
  }

  const progress = ((60 + 1 - timeLeft) / 60) * 100;

  let urgency = "normal";
  if (timeLeft <= 10) {
    urgency = "warning";
  }
  if (timeLeft <= 4) {
    urgency = "danger";
  }

  let progressMessage = "";
  if (showAllTeams) {
    progressMessage = questionText;
  }
  if (isHost) {
    progressMessage = (
      <>
        {questionText}
        <ProgressAnswerWrapper>({answerText})</ProgressAnswerWrapper>
      </>
    );
  }

  let timeLeftMessage = getTimeLeftText(timeLeft);
  if (timeLeft <= 0) {
    timeLeftMessage = "Tiebreaker closed";
  }

  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        navigation={navigation}
        chat={chat}
        payload={
          <PayloadWrapper>
            <ProgressWrapper>
              <Progress
                progress={progress}
                leftMessage={progressMessage}
                rightMessage={
                  ![null, undefined].includes(timeLeft) && timeLeftMessage
                }
                urgency={urgency}
              />
            </ProgressWrapper>
            {showTiebreaker && (
              <QuestionWrapper>
                <Question
                  isTiebreaker
                  text={questionText}
                  answerText={answerText}
                  value={answerValue}
                  isHost={isHost}
                  isLeader={isLeader}
                  leaderName={leaderName}
                  onChange={onAnswerChange}
                  onSubmit={onSubmitAnswer}
                  onDismiss={onDismiss}
                />
              </QuestionWrapper>
            )}
            {showAllTeams && (
              <AllTeamsAndNamesWrapper>
                <AllTeamsAndNames teams={allTeamsInTiebreaker} />
              </AllTeamsAndNamesWrapper>
            )}
            {teamBreakout && (
              <TeamBreakoutWrapper>{teamBreakout}</TeamBreakoutWrapper>
            )}
          </PayloadWrapper>
        }
        actionBar={
          isHost && (
            <ActionBar>
              <LargeDarkButton onClick={onCloseTiebreaker}>
                Close Tiebreaker
              </LargeDarkButton>
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default TiebreakerScreen;
