import React, { useContext } from "react";
import QuizContext from "../../contexts/QuizContext";
import MainRoomContext from "../../contexts/MainRoomContext";
import AudioParticipant from "../AudioParticipant";
import { QUIZ_INTRO, NAME_TEAMS, ROUND_INTRO, QUESTION } from "../../constants";

const MainRoomAudio = () => {
  const { hosts, players, whatToShow, delayingMainRoomJoin } = useContext(
    QuizContext
  );
  const { remoteParticipants } = useContext(MainRoomContext);

  if (!hosts || !players) {
    return null;
  }

  const mutePlayers =
    [QUIZ_INTRO, NAME_TEAMS, ROUND_INTRO, QUESTION].includes(whatToShow) ||
    delayingMainRoomJoin;

  return (
    <>
      <div data-cy="host-audio">
        {hosts.map((host) => (
          <AudioParticipant
            key={host.id}
            participant={remoteParticipants[host.id]}
          />
        ))}
      </div>
      <div data-cy="player-audio">
        {players.map((player) => (
          <AudioParticipant
            key={player.id}
            participant={remoteParticipants[player.id]}
            mute={mutePlayers}
          />
        ))}
      </div>
    </>
  );
};

export default MainRoomAudio;
