import React, { useContext, useState } from "react";
import NameTeamsScreen from "../../../screens/quiz/03/NameTeamsScreen";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import QuizContext from "../../../../contexts/QuizContext";
import useMeetTeamsMutation from "../../../hooks/mutations/useMeetTeamsMutation";
import getTeamBreakoutAudio from "../AudioController/getTeamBreakoutAudio";
import EmojiPicker from "../../../components/elements/emojis/EmojiPicker";
import useNameTeamMutation from "../../../hooks/mutations/useNameTeamMutation";
import useCountdownTimer from "../../../hooks/useCountdownTimer";
import TeamBreakoutController from "../TeamBreakoutController";
import TeamController from "../TeamController";
import useTeamLeadTypingAnswerMutation from "../../../hooks/mutations/useTeamLeadTypingAnswerMutation";
import usePusherChannel from "../../../hooks/pusher/usePusherChannel";

const NameTeamsController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const {
    quiz,
    quizName,
    teams,
    myPlayer,
    myLeaderName,
    myTeam,
    myTeamNamed,
    assistingTeam,
  } = useContext(QuizContext);
  const [meetTeamsMutation] = useMeetTeamsMutation();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emoji, setEmoji] = useState();
  const [teamName, setTeamName] = useState("");
  const [teamLeadTypingAnswerMutation] = useTeamLeadTypingAnswerMutation();
  const [nameTeamMutation] = useNameTeamMutation();
  const [submitting, setSubmitting] = useState(false);

  const timeLeft = useCountdownTimer(
    quiz && quiz.namingClosesAt,
    async () => await meetTeamsMutation()
  );

  const handleSelectEmoji = (emoji) => {
    setEmoji(emoji.native);
    setShowEmojiPicker(false);
    handleNameAndEmojiChange(teamName, emoji.native);
  };

  const handleTeamNameChange = (value) => {
    setTeamName(value);
    handleNameAndEmojiChange(value, emoji);
  };

  const handleNameAndEmojiChange = async (newTeamName, newEmoji) => {
    if (myPlayer && myPlayer.leader) {
      await teamLeadTypingAnswerMutation({
        variables: {
          text: newTeamName,
          emoji: newEmoji,
        },
      });
    }
  };

  const pusherChannel =
    myPlayer && !myPlayer.leader && myTeam && myTeam.id.replace(/-/g, "");

  const handlePusherEvent = async (eventName, data) => {
    if (eventName === "message") {
      setTeamName(data.text);
      setEmoji(data.emoji);
    }
  };

  usePusherChannel(pusherChannel, handlePusherEvent);

  const handleSubmitNameAndEmoji = () => {
    setSubmitting(true);
    nameTeamMutation({
      variables: {
        name: teamName,
        emoji,
      },
    });
  };

  const handleMeetTeams = async () => {
    await meetTeamsMutation();
  };

  const isHost = Boolean(myHostId);

  return (
    <NameTeamsScreen
      audio={getTeamBreakoutAudio()}
      timeLeft={timeLeft}
      isHost={isHost}
      isAssisting={Boolean(assistingTeam)}
      quizName={quizName}
      leader={myPlayer && myPlayer.leader}
      leaderName={myLeaderName}
      thisTeamNamed={myTeamNamed}
      emoji={emoji}
      teamName={teamName}
      submitting={submitting}
      teamBreakout={
        (Boolean(assistingTeam) || !isHost) && <TeamBreakoutController />
      }
      allTeams={
        teams &&
        teams.map((team) => (
          <TeamController key={team.id} team={team} compact naming />
        ))
      }
      onMeetTeams={handleMeetTeams}
      resetButton={resetButton}
      onShowEmojiPicker={() => setShowEmojiPicker(true)}
      onTeamNameChange={handleTeamNameChange}
      onSubmitNameAndEmoji={handleSubmitNameAndEmoji}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
      emojiPicker={
        showEmojiPicker && (
          <EmojiPicker
            onSelect={handleSelectEmoji}
            onClose={() => setShowEmojiPicker(false)}
          />
        )
      }
    />
  );
};

export default NameTeamsController;
