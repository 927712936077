import AudioController from "./index";
import React, { useContext } from "react";
import QuizContext from "../../../../contexts/QuizContext";

const getAllAudio = () => {
  const { hosts, players } = useContext(QuizContext);

  return [...hosts, ...players].map((participant) => (
    <AudioController key={participant.id} participantId={participant.id} />
  ));
};

export default getAllAudio;
