import React, { useContext, useState } from "react";
import TiebreakerScreen from "../../../screens/quiz/12/TiebreakerScreen";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import getTeamBreakoutAudio from "../AudioController/getTeamBreakoutAudio";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import useCountdownTimer from "../../../hooks/useCountdownTimer";
import QuizContext from "../../../../contexts/QuizContext";
import useTeamLeadTypingAnswerMutation from "../../../hooks/mutations/useTeamLeadTypingAnswerMutation";
import useCloseTiebreakerMutation from "../../../hooks/mutations/useCloseTiebreakerMutation";
import useTeamAnswerTiebreakerMutation from "../../../hooks/mutations/useTeamAnswerTiebreakerMutation";
import usePusherChannel from "../../../hooks/pusher/usePusherChannel";
import AvatarController from "../AvatarControllers/AvatarController";
import teamPlayerSortCompare from "../../../util/teamPlayerSortCompare";
import TeamController from "../TeamController";
import TeamBreakoutController from "../TeamBreakoutController";

const TiebreakerController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const {
    tiebreaker,
    myPlayer,
    myTeam,
    myLeaderName,
    myTeamInFirstPlace,
    teamsInFirstPlace,
    myTeamAnsweredTiebreaker,
    assistingTeam,
  } = useContext(QuizContext);
  const [answerValue, setAnswerValue] = useState("");
  const [teamLeadTypingAnswerMutation] = useTeamLeadTypingAnswerMutation();
  const [teamAnswerTiebreakerMutation] = useTeamAnswerTiebreakerMutation();
  const [tiebreakerDismissed, setTiebreakerDismissed] = useState(false);
  const [closeTiebreakerMutation] = useCloseTiebreakerMutation();

  const handleCloseTiebreaker = async () => await closeTiebreakerMutation();

  const handleAnswerChange = (event) => {
    setAnswerValue(event.target.value);
    if (myPlayer && myPlayer.leader) {
      teamLeadTypingAnswerMutation({
        variables: {
          text: event.target.value,
        },
      });
    }
  };

  const handleSubmitAnswer = async () => {
    await teamAnswerTiebreakerMutation({
      variables: {
        answerText: answerValue,
      },
    });
  };

  const timeLeft = useCountdownTimer(
    tiebreaker.closesAt,
    async () => await closeTiebreakerMutation()
  );

  const pusherChannel =
    myTeamInFirstPlace &&
    myPlayer &&
    !myPlayer.leader &&
    myTeam &&
    myTeam.id.replace(/-/g, "");

  const handlePusherEvent = async (eventName, data) => {
    if (eventName === "message") {
      setAnswerValue(data.text);
    }
  };

  usePusherChannel(pusherChannel, handlePusherEvent);

  const isHost = Boolean(myHostId);

  return (
    <TiebreakerScreen
      audio={getTeamBreakoutAudio()}
      isHost={isHost}
      isLeader={myPlayer && myPlayer.leader}
      leaderName={myLeaderName}
      thisTeamInTiebreaker={myTeamInFirstPlace}
      thisTeamAnswered={myTeamAnsweredTiebreaker}
      emoji={myTeam && myTeam.emoji}
      teamName={myTeam && myTeam.name}
      teamAvatars={
        myTeam &&
        myTeam.players
          .sort(teamPlayerSortCompare)
          .map((player) => <AvatarController key={player.id} player={player} />)
      }
      teamBreakout={
        (Boolean(assistingTeam) || !isHost) && <TeamBreakoutController />
      }
      allTeamsInTiebreaker={
        teamsInFirstPlace &&
        teamsInFirstPlace.map((team) => (
          <TeamController key={team.id} team={team} inTiebreaker compact />
        ))
      }
      questionText={tiebreaker.text}
      answerText={tiebreaker.answer}
      answerValue={answerValue}
      onAnswerChange={handleAnswerChange}
      onSubmitAnswer={handleSubmitAnswer}
      onDismiss={() => setTiebreakerDismissed(true)}
      dismissed={tiebreakerDismissed}
      onCloseTiebreaker={handleCloseTiebreaker}
      resetButton={resetButton}
      timeLeft={timeLeft}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
    >
      Tiebreaker
    </TiebreakerScreen>
  );
};

export default TiebreakerController;
