import React, { useContext, useState } from "react";
import Audio from "../../../components/av/Audio";
import MainRoomContext from "../../../contexts/MainRoomContext";
import useTrackPublications from "../../../hooks/av/useTrackPublications";
import useAudioTrackPublications from "../../../hooks/av/useAudioTrackPublications";
import useTrack from "../../../hooks/av/useTrack";
import useCallbackRef from "../../../hooks/useCallbackRef";
import useAttachAudioTrackEffect from "../../../hooks/av/useAttachAudioTrackEffect";

const AudioController = ({ participantId }) => {
  const { participants } = useContext(MainRoomContext);

  const participant = participants[participantId];

  const trackPublications = useTrackPublications(participant);
  const audioTrackPublication = useAudioTrackPublications(trackPublications);
  const audioTrack = useTrack(audioTrackPublication);

  const [audioEl, setAudioEl] = useState();
  const audioRef = useCallbackRef(setAudioEl);

  useAttachAudioTrackEffect(audioTrack, audioEl);

  return <Audio audioRef={audioRef} />;
};

export default AudioController;
