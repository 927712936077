import styled from "styled-components";
import Button from "./Button";

export default styled(Button)`
  border: none;
  padding: 0;
  background-color: transparent;

  :hover {
    box-shadow: none;
  }

  :focus {
    box-shadow: none;
  }
`;
