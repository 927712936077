import React, { useCallback, useContext, useEffect, useState } from "react";
import LocalTracksContext from "./LocalTracksContext";
import useRoom from "../hooks/av/useRoom";
import useParticipants from "../hooks/av/useParticipants";
import useDominantSpeaker from "../hooks/av/useDominantSpeaker";
import useParticipantsWithVideo from "../hooks/av/useParticipantsWithVideo";
import SessionDataContext from "../../contexts/SessionDataContext";

const MainRoomContext = React.createContext();

export const MainRoomContextProvider = ({ roomName, token, children }) => {
  const { myHostId } = useContext(SessionDataContext);
  const {
    localAudioTrack,
    localVideoTrack,
    localVideoEnabled,
    disableLocalVideo,
    enableLocalVideo,
  } = useContext(LocalTracksContext);
  const [muted, setMuted] = useState(false);

  const { room, connecting, connect } = useRoom(
    roomName,
    token,
    localAudioTrack,
    localVideoTrack
  );

  const localParticipant = room && room.localParticipant;
  const participants = useParticipants(room);
  const dominantSpeaker = useDominantSpeaker(room);
  const participantsWithVideo = useParticipantsWithVideo(room, localVideoTrack);

  const handleMute = useCallback(
    (value) => {
      if (localParticipant) {
        if (value) {
          localParticipant.unpublishTrack(localAudioTrack);
          setMuted(true);
        } else {
          localParticipant.publishTrack(localAudioTrack);
          setMuted(false);
        }
      }
    },
    [localParticipant]
  );

  // host begins muted and camera off
  useEffect(() => {
    if (localAudioTrack && localVideoTrack && myHostId && localParticipant) {
      handleMute(true);
      disableLocalVideo();
    }
  }, [localAudioTrack, localVideoTrack, myHostId, localParticipant]);

  return (
    <MainRoomContext.Provider
      value={{
        room,
        connecting,
        connect,
        localParticipant,
        participants,
        participantsWithVideo,
        dominantSpeaker,
        localAudioTrack,
        localVideoTrack,
        muted,
        setMuted: handleMute,
        videoStopped: !localVideoEnabled,
        setVideoStopped: (value) =>
          value ? disableLocalVideo() : enableLocalVideo(),
      }}
    >
      {children}
    </MainRoomContext.Provider>
  );
};

export default MainRoomContext;
