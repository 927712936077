import React, { useContext } from "react";
import styled from "styled-components";
import { ANSWERS, FACTS, QUESTION, QUESTION_RESULTS } from "../../constants";
import TeamLeaderInput from "./TeamLeaderInput";
import TeamAnswerView from "./TeamAnswerView";
import AnswerWrapper from "./AnswerWrapper";
import AnswerTextWrapper from "./AnswerTextWrapper";
import QuestionTimer from "./QuestionTimer";
import JoiningMainRoomTimer from "./JoiningMainRoomTimer";
import { DARK_GREY_4, LIGHT_GREY_2, MUTED_TEXT } from "../../colors";
import QuizContext from "../../contexts/QuizContext";

// const logger = getLogger("Question");

const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QuestionSignPost = styled.div`
  font-size: 14px;
  color: ${DARK_GREY_4};
  margin-bottom: 10px;
`;

const QuestionBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${LIGHT_GREY_2};
  padding: 20px 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  min-height: 135px;
`;

const QuestionText = styled.div`
  font-size: 20px;
  line-height: 1.5;
`;

const Facts = styled.div`
  margin-top: 20px;
  color: ${MUTED_TEXT};
  line-height: 1.3em;

  > p {
    margin: 0;
  }
`;

export default ({ quiz, round, question, myTeamAnswer }) => {
  const { myHostId, myPlayerId, myTeamId } = useContext(QuizContext);

  const team = quiz.teams.find((team) => team.id === myTeamId);
  const player =
    team && team.players.find((player) => player.id === myPlayerId);

  const whatToShow = quiz.whatToShow;
  const host = !!myHostId;

  return (
    <QuestionWrapper>
      <QuestionBox>
        <QuestionSignPost>
          Question {question.number} of {round.numQuestions}
        </QuestionSignPost>
        <QuestionText>{question.text}</QuestionText>
        {whatToShow === QUESTION && !myTeamAnswer && !host && (
          <>
            {player.leader && <TeamLeaderInput />}
            {!player.leader && <TeamAnswerView />}
          </>
        )}
        {([QUESTION_RESULTS, FACTS].includes(whatToShow) || host) && (
          <>
            <AnswerWrapper>
              <span>Answer: </span>
              <AnswerTextWrapper>{question.answer}</AnswerTextWrapper>
            </AnswerWrapper>
            {whatToShow === FACTS && (
              <Facts
                data-cy="facts"
                dangerouslySetInnerHTML={{ __html: question.facts }}
              />
            )}
          </>
        )}
      </QuestionBox>
      {whatToShow === QUESTION && <QuestionTimer />}
      {whatToShow === ANSWERS && <JoiningMainRoomTimer />}
    </QuestionWrapper>
  );
};
