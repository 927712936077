import React from "react";
import styled from "styled-components";
import { ELEMENT_OFF_WHITE, WHITE } from "../../colors";
import LinkButton from "./LinkButton";

const StyledButton = styled(LinkButton)`
  color: ${ELEMENT_OFF_WHITE};

  &:hover:enabled,
  &.hover {
    color: ${WHITE};
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: default;
  `}
`;

const LightLinkButton = ({ ...rest }) => {
  return <StyledButton spinnerColor={ELEMENT_OFF_WHITE} {...rest} />;
};

export default LightLinkButton;
