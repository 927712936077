import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import ScreenTitle from "../../../components/titles/ScreenTitle";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import styled from "styled-components";
import Progress from "../../../components/progress/Progress";
import getTimeLeftText from "../../../util/getTimeLeftText";
import DisabledButtonTooltip from "../../../components/elements/tooltips/DisabledButtonTooltip";

const EVENT_RETURN_TIME = 30;

const PayloadWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content min-content 1fr;
  padding: 0 15px 0 10px;
`;

const ProgressWrapper = styled.div`
  margin-bottom: 20px;
`;

const GalleryWrapper = styled.div`
  height: 100%;
`;

const QuizConversationScreen = ({
  audio,
  inEvent,
  numCompletedEventQuizzes,
  numOtherEventQuizzes,
  timeLeftBeforeQuizzesClose,
  isHost,
  quizName,
  count,
  pageNum,
  pageCount,
  galleryPage,
  onPrevPage,
  onNextPage,
  onSetPage,
  onEndQuiz,
  resetButton,
  navigation,
  chat,
  modal,
}) => {
  const quizProgress = (numCompletedEventQuizzes / numOtherEventQuizzes) * 100;

  const allEventQuizzesCompleted =
    inEvent && numCompletedEventQuizzes === numOtherEventQuizzes;

  const eventReturnProgress =
    ((EVENT_RETURN_TIME + 1 - timeLeftBeforeQuizzesClose) / EVENT_RETURN_TIME) *
    100;

  let eventReturnTimeLeftMessage = getTimeLeftText(timeLeftBeforeQuizzesClose);
  if (timeLeftBeforeQuizzesClose <= 0) {
    eventReturnTimeLeftMessage = "Returning to event";
  }

  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        navigation={navigation}
        chat={chat}
        payload={
          <PayloadWrapper>
            <>
              {inEvent && !allEventQuizzesCompleted && (
                <ProgressWrapper>
                  <Progress
                    leftMessage={`${numCompletedEventQuizzes} of ${numOtherEventQuizzes} quizzes completed`}
                    progress={quizProgress}
                  />
                </ProgressWrapper>
              )}
              {inEvent && allEventQuizzesCompleted && (
                <ProgressWrapper>
                  <Progress
                    leftMessage={"Get ready for results from all quizzes"}
                    progress={eventReturnProgress}
                    rightMessage={
                      ![null, undefined].includes(timeLeftBeforeQuizzesClose) &&
                      eventReturnTimeLeftMessage
                    }
                  />
                </ProgressWrapper>
              )}
              {!inEvent && <div />}
            </>
            <ScreenTitle
              quizName={quizName}
              location={"End of Quiz Conversation"}
              count={count || 0}
              pageNum={pageNum}
              pageCount={pageCount}
              onPrevPage={onPrevPage}
              onNextPage={onNextPage}
              onSetPage={onSetPage}
            />
            <GalleryWrapper>{galleryPage}</GalleryWrapper>
          </PayloadWrapper>
        }
        actionBar={
          isHost && (
            <ActionBar>
              <DisabledButtonTooltip
                disabled={inEvent}
                content={"Quiz will automatically end on return to event"}
              >
                <LargeDarkButton disabled={inEvent} onClick={onEndQuiz}>
                  End Quiz
                </LargeDarkButton>
              </DisabledButtonTooltip>
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default QuizConversationScreen;
