export const defaultConnectOptions = {
  bandwidthProfile: {
    video: {
      mode: "grid",
    },
  },
  preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
  tracks: [],
  networkQuality: {
    // this is how much detail the api returns
    local: 3,
    remote: 3,
  },
  dominantSpeaker: true,
};

export const defaultAudioOptions = {
  logLevel: "debug",
};

export const defaultVideoOptions = {
  logLevel: "debug",
  width: { ideal: 160 },
  height: { ideal: 120 },
  frameRate: { ideal: 24 },
};
