import { gql, useQuery } from "@apollo/client";

const GET_CURRENT_QUIZ_FOR_SURVEY = gql`
  query GetCurrentQuiz {
    quiz: getCurrentQuiz {
      id
      name
      publicId
      players {
        id
      }
    }
  }
`;

const useGetCurrentQuizForSurveyQuery = () =>
  useQuery(GET_CURRENT_QUIZ_FOR_SURVEY);

export default useGetCurrentQuizForSurveyQuery;
