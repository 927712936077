import React from "react";

const RightArrowIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.854704 26.144L1.20747 25.7912L0.854705 26.144C1.08418 26.3735 1.39452 26.5 1.70598 26.5C2.00735 26.5 2.32269 26.3855 2.56015 26.1411L14.3472 14.354C14.3479 14.3533 14.3485 14.3527 14.3492 14.3521C14.8241 13.884 14.8235 13.1157 14.3559 12.6481L2.56249 0.854704C2.08955 0.381766 1.32764 0.381765 0.854704 0.854704C0.381765 1.32764 0.381765 2.08955 0.854704 2.56249L11.7916 13.4993L0.854704 24.4362C0.381765 24.9091 0.381765 25.671 0.854704 26.144Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

export default RightArrowIcon;
