import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation AssignToTeam($playerId: ID!, $teamId: ID!) {
      assignToTeam(playerId: $playerId, teamId: $teamId) {
        ok
      }
    }
  `);
