import React, { useContext, useEffect } from "react";
import QuizContext from "../../contexts/QuizContext";
import AudioParticipant from "../AudioParticipant";
import TeamRoomContext from "../../contexts/TeamRoomContext";
import getLogger from "../../util/getLogger";
import FeatureFlagContext from "../../contexts/FeatureFlagContext";
import styled from "styled-components";
import { LIGHT_REGULAR_TEXT } from "../../colors";

const logger = getLogger("TeamRoomAudio");

const Wrapper = styled.div`
  max-width: 150px;
  font-size: 14px;
  color: ${LIGHT_REGULAR_TEXT};
`;

const TeamRoomAudio = () => {
  const { myTeam, assistingTeam, assistants } = useContext(QuizContext);
  const { remoteParticipants } = useContext(TeamRoomContext);
  const { flags } = useContext(FeatureFlagContext);

  useEffect(() => {
    logger.debug("TeamRoomAudio initialized");

    return () => logger.debug("TeamRoomAudio destroyed");
  }, []);

  useEffect(() => {
    logger.debug("remoteParticipants changed", remoteParticipants);
  }, [remoteParticipants]);

  const team = myTeam || assistingTeam;
  const teamPlayers = team && team.players;

  return (
    <Wrapper data-cy="team-player-audio">
      {flags["debug"] &&
        `Remote participants: ${JSON.stringify(
          Object.keys(remoteParticipants)
        )}`}
      {assistants.map((asst) => (
        <AudioParticipant
          key={asst.id}
          participant={remoteParticipants[asst.id]}
        />
      ))}
      {teamPlayers &&
        teamPlayers.map((player) => (
          <AudioParticipant
            key={player.id}
            participant={remoteParticipants[player.id]}
          />
        ))}
    </Wrapper>
  );
};

export default TeamRoomAudio;
