import React, { useContext } from "react";
import AnswersScreen from "../../../screens/quiz/07/AnswersScreen";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import useShowQuestionResultsMutation from "../../../hooks/mutations/useShowQuestionResultsMutation";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import QuizContext from "../../../../contexts/QuizContext";
import TeamController from "../TeamController";
import getAllAudio from "../AudioController/getAllAudio";
import getHostAvatarControllerList from "../AvatarControllers/getHostAvatarControllerList";

const AnswersController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const { question, teams } = useContext(QuizContext);

  const [showQuestionResultsMutation] = useShowQuestionResultsMutation();

  const handleShowQuestionResults = async () => {
    await showQuestionResultsMutation();
  };

  return (
    <AnswersScreen
      audio={getAllAudio()}
      isHost={Boolean(myHostId)}
      hostAvatars={getHostAvatarControllerList()}
      questionText={question.text}
      answerText={question.answer}
      teams={
        teams &&
        teams.map((team) => (
          <TeamController key={team.id} team={team} showAnswer />
        ))
      }
      onShowQuestionResults={handleShowQuestionResults}
      resetButton={resetButton}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
    />
  );
};

export default AnswersController;
