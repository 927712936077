import styled from "styled-components";
import { WHITE } from "../../colors";
import { PARAGRAPH_NORMAL_BOLD } from "../../typography";

const InstructionHeading = styled.div`
  color: ${WHITE};
  ${PARAGRAPH_NORMAL_BOLD};
  margin-bottom: 5px;
`;

export default InstructionHeading;
