import Counter from "../elements/paging/Counter";
import React from "react";
import styled from "styled-components";
import { TITLE_HEADER } from "../../typography";
import { ELEMENT_OFF_WHITE, WHITE } from "../../colors";
import PagingIndicatorAndControl from "../elements/paging/PagingIndicatorAndControl";

const HeadingLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const NameAndLocation = styled.div`
  flex: 1;
  display: flex;
`;

const Name = styled.div`
  ${TITLE_HEADER}
  color: ${ELEMENT_OFF_WHITE};
  margin-right: 7px;
`;

const Location = styled.div`
  ${TITLE_HEADER}
  color: ${WHITE};
  margin-right: 7px;
`;

const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ScreenTitle = ({
  quizName,
  location,
  count,
  pageNum,
  pageCount,
  onPrevPage,
  onNextPage,
  onSetPage,
}) => {
  return (
    <HeadingLine>
      <NameAndLocation>
        <Name>{quizName}</Name>
        <Location>{location}</Location>
        {count !== undefined && (
          <CounterWrapper>
            <Counter>{count}</Counter>
          </CounterWrapper>
        )}
      </NameAndLocation>
      {pageCount > 1 && (
        <PagingIndicatorAndControl
          pageNum={pageNum}
          numOfPages={pageCount}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
          onSetPage={onSetPage}
        />
      )}
    </HeadingLine>
  );
};

export default ScreenTitle;
