import React, { useContext } from "react";
import QuestionResultsScreen from "../../../screens/quiz/08/QuestionResultsScreen";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import useShowFactsMutation from "../../../hooks/mutations/useShowFactsMutation";
import useNextQuestionMutation from "../../../hooks/mutations/useNextQuestionMutation";
import useShowRoundScoresMutation from "../../../hooks/mutations/useShowRoundScoresMutation";
import QuizContext from "../../../../contexts/QuizContext";
import TeamController from "../TeamController";
import getAllAudio from "../AudioController/getAllAudio";
import getHostAvatarControllerList from "../AvatarControllers/getHostAvatarControllerList";

const QuestionResultsController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const { question, quiz, round, teams } = useContext(QuizContext);

  const [showFactsMutation] = useShowFactsMutation();
  const [nextQuestionMutation] = useNextQuestionMutation();
  const [showRoundScoresMutation] = useShowRoundScoresMutation();

  const handleShowFacts = async () => await showFactsMutation();
  const handleNextQuestion = async () => await nextQuestionMutation();
  const handleShowRoundScores = async () => await showRoundScoresMutation();

  const moreQuestions = quiz.questionNumber < round.questions.length;

  let next;
  if (question && question.facts) {
    next = "facts";
  } else if (moreQuestions) {
    next = "question";
  } else {
    next = "round-results";
  }

  return (
    <QuestionResultsScreen
      audio={getAllAudio()}
      isHost={Boolean(myHostId)}
      hostAvatars={getHostAvatarControllerList()}
      questionText={question.text}
      answerText={question.answer}
      teams={
        teams &&
        teams.map((team) => (
          <TeamController key={team.id} team={team} showAnswer answerGraded />
        ))
      }
      next={next}
      onShowFacts={handleShowFacts}
      onNextQuestion={handleNextQuestion}
      onShowRoundScores={handleShowRoundScores}
      resetButton={resetButton}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
    />
  );
};

export default QuestionResultsController;
