import { useCallback, useContext, useEffect } from "react";
import QuizContext from "../../contexts/QuizContext";
import useInterval from "../../hooks/useInterval";
import ServerTimeContext from "../../contexts/ServerTimeContext";
import getLogger from "../../util/getLogger";
import UserContext from "../../contexts/UserContext";
import { OK } from "../Announcement";

const logger = getLogger("JoiningMainRoomTimer");

const JoiningMainRoomTimer = () => {
  const { setAnnouncement } = useContext(UserContext);
  const { mainRoomOpensAt, setDelayingMainRoomJoin } = useContext(QuizContext);
  const { getSecondsLeft } = useContext(ServerTimeContext);

  const updateSecondsLeft = useCallback(() => {
    if (mainRoomOpensAt) {
      const secondsLeft = getSecondsLeft(mainRoomOpensAt);
      logger.debug("seconds left", secondsLeft);

      if (secondsLeft > 0) {
        let text = `Rejoining main room in ${secondsLeft} second${
          secondsLeft !== 1 ? "s" : ""
        }`;

        setAnnouncement({
          text,
          OK,
        });
      } else {
        setAnnouncement(null);
        setDelayingMainRoomJoin(false);
      }
    }
  }, [mainRoomOpensAt]);

  useInterval(() => {
    updateSecondsLeft();
  }, 1000);

  useEffect(() => {
    return () => setAnnouncement(null);
  }, []);

  return null;
};

export default JoiningMainRoomTimer;
