import AudioController from "./index";
import React, { useContext } from "react";
import QuizContext from "../../../../contexts/QuizContext";

const getHostsAudio = () => {
  const { hosts } = useContext(QuizContext);

  return [...hosts].map((participant) => (
    <AudioController key={participant.id} participantId={participant.id} />
  ));
};

export default getHostsAudio;
