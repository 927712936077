import React, { useContext, useState } from "react";
import Modal from "../Modal";
import QuizContext from "../../contexts/QuizContext";
import PrimaryButton from "../buttons/PrimaryButton";
import Button from "../buttons/Button";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { BRAND, LIGHT_GREY_4 } from "../../colors";
import { MAX_AUTO_UNMUTE } from "../../constants";

const SliderWrapper = styled.div`
  margin: 0 auto 15px;
  width: 300px;

  & .rc-slider-rail {
    background-color: ${LIGHT_GREY_4};
  }

  & .rc-slider-track {
    background-color: ${BRAND};
    height: 6px;
  }

  & .rc-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -10px;
    border: 4px solid ${BRAND};
  }
`;

const CreateMultipleTeamsModal = ({ onClose }) => {
  const { players, createMultipleTeams } = useContext(QuizContext);
  const [numTeams, setNumTeams] = useState(3);
  // const [randomAssignment, setRandomAssignment] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const maxTeams = MAX_AUTO_UNMUTE;

  const handleSliderChange = (value) => {
    setNumTeams(value);
  };

  const handleAssignTeams = async () => {
    setSubmitted(true);
    await createMultipleTeams(numTeams, true);
  };

  if (!players) {
    return null;
  }

  const minTeamSize = Math.floor(players.length / numTeams);
  const maxTeamSize = Math.ceil(players.length / numTeams);
  const pluralize = !(minTeamSize === 1 && maxTeamSize === 1);

  const tooMany = maxTeamSize > 7;

  return (
    <Modal
      title="Create Multiple Teams"
      text={
        <>
          <div>Number of teams: {numTeams}</div>
          <div>
            {minTeamSize} {maxTeamSize > minTeamSize && ` - ${maxTeamSize}`}{" "}
            player{pluralize && "s"} / team
          </div>
        </>
      }
      body={
        <SliderWrapper>
          <Slider
            value={numTeams}
            min={1}
            max={maxTeams}
            onChange={handleSliderChange}
          />
        </SliderWrapper>
      }
      buttons={
        <>
          <PrimaryButton
            size="large"
            onClick={handleAssignTeams}
            disabled={tooMany || submitted}
            data-cy="create-button"
          >
            {submitted ? "Creating" : "Create"}
          </PrimaryButton>
          <Button size="large" onClick={onClose} data-cy="cancel-button">
            Cancel
          </Button>
        </>
      }
    />
  );
};

export default CreateMultipleTeamsModal;
