import React, { useContext, useState } from "react";
import QuizIntroScreen from "../../../screens/quiz/02/QuizIntroScreen";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import QuizContext from "../../../../contexts/QuizContext";
import SplitIntoTeamsController from "../modals/SplitIntoTeamsController";
import getHostsAudio from "../AudioController/getHostsAudio";
import getHostAvatarControllerList from "../AvatarControllers/getHostAvatarControllerList";

const QuizIntroController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const { quizName } = useContext(QuizContext);
  const [showSplitIntoTeams, setShowSplitIntoTeams] = useState();

  return (
    <QuizIntroScreen
      audio={getHostsAudio()}
      isHost={Boolean(myHostId)}
      hostAvatars={getHostAvatarControllerList()}
      title={quizName}
      onShowSplitIntoTeamsModal={() => setShowSplitIntoTeams(true)}
      resetButton={resetButton}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
      modal={
        <>
          {showSplitIntoTeams && (
            <SplitIntoTeamsController
              onCancel={() => setShowSplitIntoTeams(false)}
            />
          )}
        </>
      }
    />
  );
};

export default QuizIntroController;
