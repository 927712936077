import React, { useContext } from "react";
import QuizContext from "../../../../contexts/QuizContext";
import AvatarController from "./AvatarController";
import SelfieCacheContext from "../../../../contexts/SelfieCacheContext";

const getAvatarControllerList = () => {
  const { hosts, players } = useContext(QuizContext);
  const { getSelfieForParticipant } = useContext(SelfieCacheContext);

  let result = [];

  const mainHost =
    hosts &&
    hosts
      .filter((host) => getSelfieForParticipant(host.id))
      .find((host) => !host.coHost);

  const mainHostAvatar = mainHost && (
    <AvatarController key={mainHost.id} host={mainHost} id={mainHost.id} />
  );

  if (mainHostAvatar) {
    result.push(mainHostAvatar);
  }

  const playerAvatars =
    players &&
    players
      .filter((player) => getSelfieForParticipant(player.id))
      .map((player) => (
        <AvatarController key={player.id} player={player} id={player.id} />
      ));
  if (playerAvatars) {
    result = [...result, ...playerAvatars];
  }

  const coHost =
    hosts &&
    hosts
      .filter((host) => getSelfieForParticipant(host.id))
      .find((host) => host.coHost);

  const coHostAvatar = coHost && (
    <AvatarController key={coHost.id} id={coHost.id} host={coHost} />
  );
  if (coHostAvatar) {
    result = [...result, coHostAvatar];
  }

  return result;
};

export default getAvatarControllerList;
