import React from "react";
import styled from "styled-components";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";

const Wrapper = styled.div`
  width: 250px;
  min-width: 250px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 20px;
  max-height: 100%;
  overflow: hidden;
`;

const ChatArea = () => {
  return (
    <Wrapper>
      <MessageList />
      <MessageInput />
    </Wrapper>
  );
};

export default ChatArea;
