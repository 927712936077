import { useEffect, useState } from "react";

const useTrackPublicationPriority = (publication) => {
  const [priority, setPriority] = useState();

  useEffect(() => {
    if (publication) {
      const handlePriorityChanged = () => {
        setPriority(publication.publishPriority);
      };

      publication.on("publishPriorityChanged", handlePriorityChanged);

      handlePriorityChanged();

      return () => {
        publication.off("publishPriorityChanged", handlePriorityChanged);
      };
    } else {
      setPriority(undefined);
    }
  }, [publication]);

  return priority;
};

export default useTrackPublicationPriority;
