import { gql, useMutation } from "@apollo/client";

const RESPOND_TO_SURVEY = gql`
  mutation RespondToSurvey(
    $avQuality: Int
    $npsScore: Int
    $improvementAdvice: String
  ) {
    respondToSurvey(
      avQuality: $avQuality
      npsScore: $npsScore
      improvementAdvice: $improvementAdvice
    ) {
      ok
    }
  }
`;

const useRespondToSurveyMutation = () => useMutation(RESPOND_TO_SURVEY);

export default useRespondToSurveyMutation;
