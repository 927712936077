import React from "react";
import styled from "styled-components";
import GalleryLayout from "../layouts/GalleryLayout";

const GalleryWrapper = styled.div`
  height: 100%;
`;

const HostsOnIntroScreens = ({ hostAvatars }) => {
  return (
    <GalleryWrapper>
      <GalleryLayout participants={hostAvatars} />
    </GalleryWrapper>
  );
};

export default HostsOnIntroScreens;
