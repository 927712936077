import React, { useContext } from "react";
import styled from "styled-components";
import PlayerName from "./PlayerName";
import DraggablePlayer from "./DraggablePlayer";
import { useMediaQuery } from "react-responsive/src";
import { LARGE_QUERY, XLARGE_QUERY } from "./responsive";
import { LARGE, MEDIUM, XLARGE } from "../dimensions";
import _ from "lodash";
import QuizContext from "../contexts/QuizContext";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 80px;
  ${({ size }) =>
    size === LARGE &&
    `
    max-height: 150px;
  `}
  ${({ size }) =>
    size === XLARGE &&
    `
    max-height: 200px
  `}
`;

export default ({ players }) => {
  const { myPlayerId } = useContext(QuizContext);

  const isXLarge = useMediaQuery(XLARGE_QUERY);
  const isLarge = useMediaQuery(LARGE_QUERY);

  let size = MEDIUM;
  if (isXLarge) {
    size = XLARGE;
  } else if (isLarge) {
    size = LARGE;
  }

  const sortedPlayers = _.sortBy(players, [
    (player) => (player.leader ? 0 : 1),
    (player) => (player.id === myPlayerId ? 0 : 1),
    (player) => (player.name === "" ? "zzzzzzzzzzzz" : player.name),
  ]);

  return (
    <Wrapper size={size} data-cy="player-list">
      {sortedPlayers.map((player) => (
        <DraggablePlayer
          key={player.id}
          player={player}
          data-cy="draggable-player"
        >
          <PlayerName player={player} />
        </DraggablePlayer>
      ))}
    </Wrapper>
  );
};
