import React, { useContext, useState } from "react";
import styled from "styled-components";
import QuizActionButton from "../buttons/QuizActionButton";
import RemovePlayerModal from "../modals/RemovePlayerModal";
import Button from "../buttons/Button";
import QuizContext from "../../contexts/QuizContext";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  margin: 5px 0;
`;

const HostControls = ({ player }) => {
  const { makeTeamLeader } = useContext(QuizContext);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const handlePromoteClick = (playerId) => () => makeTeamLeader(playerId);

  const handleRemoveClick = () => setShowRemoveModal(true);
  const handleRemoveClose = () => setShowRemoveModal(false);

  return (
    <Wrapper>
      {!player.leader && (
        <ButtonWrapper>
          <QuizActionButton
            label="Promote"
            clickedLabel="Promoting"
            onClick={handlePromoteClick(player.id)}
          />
        </ButtonWrapper>
      )}
      <ButtonWrapper>
        <Button onClick={handleRemoveClick}>Remove</Button>
      </ButtonWrapper>
      {showRemoveModal && (
        <RemovePlayerModal
          playerId={player.id}
          name={player.name}
          onClose={handleRemoveClose}
        />
      )}
    </Wrapper>
  );
};

export default HostControls;
