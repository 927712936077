import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import TeamGridLayout from "../../../components/layouts/TeamGridLayout";
import styled from "styled-components";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import GridVerticalScrollLayout from "../../../components/layouts/GridVerticalScrollLayout";
import CompactQuestion from "../../../components/questions/CompactQuestion";

const QuestionWrapper = styled.div`
  margin-bottom: 10px;
`;

const TiebreakerResultsScreen = ({
  audio,
  isHost,
  hostAvatars,
  tiebreakerText,
  tiebreakerAnswerText,
  teamsInTiebreaker,
  onShowQuizScores,
  resetButton,
  navigation,
  chat,
  modal,
}) => {
  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        hostAvatars={hostAvatars}
        navigation={navigation}
        chat={chat}
        payload={
          <GridVerticalScrollLayout
            header={
              <QuestionWrapper>
                <CompactQuestion
                  text={tiebreakerText}
                  answerText={tiebreakerAnswerText}
                  answerCorrect
                />
              </QuestionWrapper>
            }
            content={<TeamGridLayout teams={teamsInTiebreaker} />}
          />
        }
        actionBar={
          isHost && (
            <ActionBar>
              <LargeDarkButton onClick={onShowQuizScores}>
                Show Final Scores
              </LargeDarkButton>
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default TiebreakerResultsScreen;
