import React, { useContext } from "react";
import RoundResultsScreen from "../../../screens/quiz/10/RoundResultsScreen";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import useShowTotalScoresMutation from "../../../hooks/mutations/useShowTotalScoresMutation";
import QuizContext from "../../../../contexts/QuizContext";
import getAllAudio from "../AudioController/getAllAudio";
import styled from "styled-components";
import TeamController from "../TeamController";
import getHostAvatarControllerList from "../AvatarControllers/getHostAvatarControllerList";

const StyledTeamController = styled(TeamController)`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const RoundResultsController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const {
    quizName,
    roundNumber,
    teams,
    getTeamRound,
    moreRounds,
    tieExists,
  } = useContext(QuizContext);
  const [showTotalScoresMutation] = useShowTotalScoresMutation();

  const handleQuizResults = async () => showTotalScoresMutation();

  const compareScores = (team1, team2) => {
    const teamRound1 = getTeamRound(team1);
    const teamRound2 = getTeamRound(team2);

    if (teamRound1.score < teamRound2.score) {
      return 1;
    } else if (teamRound1.score > teamRound2.score) {
      return -1;
    } else {
      return 0;
    }
  };

  const roundRankings = {};
  if (teams) {
    teams.forEach((team) => {
      const teamRound = getTeamRound(team);
      const otherTeamsWithHigherScores = teams.filter((otherTeam) => {
        const otherTeamRound = getTeamRound(otherTeam);
        return otherTeamRound.score > teamRound.score;
      });

      roundRankings[team.id] = otherTeamsWithHigherScores.length + 1;
    });
  }

  return (
    <RoundResultsScreen
      audio={getAllAudio()}
      isHost={Boolean(myHostId)}
      hostAvatars={getHostAvatarControllerList()}
      quizName={quizName}
      roundNumber={roundNumber}
      teams={
        teams &&
        teams
          .sort(compareScores)
          .map((team) => (
            <StyledTeamController
              key={team.id}
              team={team}
              leaderboard
              roundRanking={roundRankings[team.id]}
            />
          ))
      }
      moreRounds={moreRounds}
      tieExists={tieExists}
      onQuizResults={handleQuizResults}
      resetButton={resetButton}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
    />
  );
};

export default RoundResultsController;
