import React from "react";
import Modal from "../components/modals/BaseModal";
import ButtonRow from "../components/buttons/ButtonRow";
import LargeDangerButton from "../components/buttons/LargeDangerButton";
import LargeOutlineButton from "../components/buttons/LargeOutlineButton";

const DeleteTeamModal = ({ teamName, onDelete, onCancel }) => {
  return (
    <Modal
      title="Delete Team"
      text={
        teamName
          ? `Are you sure you want to delete the team "${teamName}" from the quiz?`
          : "Are you sure you want to delete this team from the quiz?"
      }
      buttons={
        <ButtonRow>
          <LargeDangerButton onClick={onDelete} data-cy-name={"Delete"}>
            Delete
          </LargeDangerButton>
          <LargeOutlineButton noSpinner onClick={onCancel}>
            Cancel
          </LargeOutlineButton>
        </ButtonRow>
      }
      onEscapeKey={onCancel}
    />
  );
};

export default DeleteTeamModal;
