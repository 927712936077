import { useContext, useEffect } from "react";
import QuizContext from "../../../contexts/QuizContext";
import SessionDataContext from "../../../contexts/SessionDataContext";

const useQuizWasEndedEffect = (handler) => {
  const { myPlayerId } = useContext(SessionDataContext);
  const { players } = useContext(QuizContext);

  useEffect(() => {
    if (
      myPlayerId &&
      players &&
      !players.find((player) => player.id === myPlayerId)
    ) {
      handler();
    }
  }, [players, myPlayerId]);
};

export default useQuizWasEndedEffect;
