import React from "react";
import GridGameScreenLayout from "../../components/layouts/GridGameScreenLayout";
import styled from "styled-components";
import ScreenTitle from "../../components/titles/ScreenTitle";
import TeamGridLayout from "../../components/layouts/TeamGridLayout";

const PayloadWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content 1fr;
`;

const LateJoinScreen = ({ quizName, teams, navigation, chat, modal }) => {
  return (
    <>
      <GridGameScreenLayout
        navigation={navigation}
        chat={chat}
        payload={
          <PayloadWrapper>
            <ScreenTitle quizName={quizName} location={"Join a Team"} />
            <TeamGridLayout teams={teams} />
          </PayloadWrapper>
        }
        modal={modal}
      />
    </>
  );
};

export default LateJoinScreen;
