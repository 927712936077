import { useContext, useEffect } from "react";
import QuizContext from "../../../contexts/QuizContext";

const useQuizWasEndedEffect = (handler) => {
  const { ended } = useContext(QuizContext);

  useEffect(() => {
    if (ended) {
      handler();
    }
  }, [ended]);
};

export default useQuizWasEndedEffect;
