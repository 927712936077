import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation ShowTotalScores {
      showTotalScores {
        quiz {
          id
          whatToShow
        }
      }
    }
  `);
