import React, { useContext, useState } from "react";
import styled from "styled-components";
import TeamEmoji from "./TeamEmoji";
import TeamName from "./TeamName";
import TeamResult from "./TeamResult";
import TeamScore from "./TeamScore";
import QuizContext from "../../../../../contexts/QuizContext";
import {
  NAME_TEAMS,
  QUESTION,
  QUIZ_RESULTS,
  ROUND_RESULTS,
  TIEBREAKER,
  TIEBREAKER_ANSWERS,
  TIEBREAKER_RESULTS,
} from "../../../../../constants";
import TeamRanking from "./TeamRanking";
import QuizActionButton from "../../../../buttons/QuizActionButton";
import DeleteTeamModal from "../../../../modals/DeleteTeamModal";
import QuizActionIconButton from "../../../../buttons/QuizActionIconButton";
import AssistIcon from "../../../../icons/AssistIcon";
import SubtleButton from "../../../../buttons/SubtleButton";
import DeleteIcon from "../../../../icons/DeleteIcon";
import { Wrapper as TeamWrapper } from "../../../Team";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  ${({ hide }) =>
    hide &&
    `
    visibility: hidden;
  
    ${TeamWrapper}:hover & {
      visibility: visible;
    }
  `}

  & button {
    margin: 0 5px;
  }
`;

const TeamNameRow = ({ team, correct, incorrect, editing, setEditing }) => {
  const {
    myHostId,
    myTeamId,
    whatToShow,
    getTeamRound,
    isNonTeamPlayer,
    joinTeam,
    leaveTeam,
    assistTeam,
  } = useContext(QuizContext);

  const [assistClicked, setAssistClicked] = useState(false);
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);

  const handleJoinClick = (teamId) => async () => {
    await joinTeam(teamId, true);
  };
  const handleLeaveClick = async () => {
    await leaveTeam();
  };

  const handleAssistClick = (teamId) => async () => {
    setAssistClicked(true);
    await assistTeam(teamId);
  };

  const handleDeleteClick = async () => setShowDeleteTeamModal(true);
  const handleCloseDeleteTeamModal = () => setShowDeleteTeamModal(false);

  let buttons = null;
  if (
    [NAME_TEAMS, QUESTION, TIEBREAKER].includes(whatToShow) ||
    isNonTeamPlayer
  ) {
    if (myHostId) {
      buttons = (
        <>
          <QuizActionIconButton
            key="assist"
            icon={AssistIcon}
            clickedLabel="Assisting"
            onClick={handleAssistClick(team.id)}
            data-cy={`Assist ${team.id}`}
          >
            <AssistIcon />
          </QuizActionIconButton>
          {whatToShow === NAME_TEAMS && (
            <SubtleButton
              key="delete"
              onClick={handleDeleteClick}
              data-cy={`Delete ${team.id}`}
            >
              <DeleteIcon />
            </SubtleButton>
          )}
        </>
      );
    } else if (whatToShow === NAME_TEAMS || isNonTeamPlayer) {
      if (team.id === myTeamId) {
        buttons = (
          <QuizActionButton
            key="leave"
            label="Leave"
            clickedLabel="Leaving"
            onClick={handleLeaveClick}
            data-cy="leave-team"
          />
        );
      } else {
        buttons = (
          <QuizActionButton
            key="join"
            label="Join"
            clickedLabel="Joining"
            onClick={handleJoinClick(team.id)}
            data-cy={`Join ${team.name}`}
          />
        );
      }
    }
  }

  const showRanking = whatToShow === QUIZ_RESULTS;
  let ranking;
  let showScore = false;
  let score;
  const teamRound = getTeamRound(team);
  if (whatToShow === ROUND_RESULTS) {
    score = teamRound && teamRound.score;
    showScore = true;
  }
  if (whatToShow === QUIZ_RESULTS) {
    score = team.score;
    ranking = team.ranking;
    showScore = true;
  }

  const showResult =
    (score !== undefined || correct || incorrect) && !isNonTeamPlayer;

  let showClosest;
  if (team.teamTiebreaker) {
    if (whatToShow === TIEBREAKER_ANSWERS && myHostId) {
      showClosest = true;
    }

    if (whatToShow === TIEBREAKER_RESULTS) {
      showClosest = true;
    }
  }

  return (
    <Wrapper>
      {showRanking && <TeamRanking ranking={ranking} />}
      <TeamEmoji team={team} editing={editing} setEditing={setEditing} />
      <TeamName team={team} />
      {showResult && (
        <TeamResult team={team} correct={correct} incorrect={incorrect} />
      )}
      {showClosest && (
        <TeamResult
          team={team}
          correct={team.teamTiebreaker.closest}
          incorrect={!team.teamTiebreaker.closest}
        />
      )}
      {showScore && <TeamScore team={team} score={score} />}
      <ButtonsWrapper hide={!!myHostId && !assistClicked}>
        {buttons}
      </ButtonsWrapper>
      {showDeleteTeamModal && (
        <DeleteTeamModal team={team} onClose={handleCloseDeleteTeamModal} />
      )}
    </Wrapper>
  );
};

export default TeamNameRow;
