import React from "react";
import Modal from "../Modal";
import Button from "../buttons/Button";
import styled from "styled-components";
import { MUTED_TEXT } from "../../colors";

const WARNING_KEY = "chat-warning-has-been-shown";

export const showWarning = () => localStorage.getItem(WARNING_KEY) !== "true";

const BodyWrapper = styled.div`
  color: ${MUTED_TEXT};
  text-align: center;
`;

const Paragraph = styled.div`
  margin-bottom: 20px;
`;

const EmphasizedParagraph = styled(Paragraph)`
  font-weight: bold;
  vertical-align: middle;
`;

const EmojiWrapper = styled.span`
  font-size: 26px;
  vertical-align: middle;
  margin: 0 5px;
`;

const ChatWarningModal = ({ onClose }) => {
  const handleClick = () => {
    localStorage.setItem(WARNING_KEY, "true");
    onClose();
  };

  return (
    <Modal
      title="Chat Visibility"
      body={
        <BodyWrapper>
          <Paragraph>
            Feel free use the quiz-wide chat to make fun of your colleagues,
            post snide remarks, and make jokes that all players will see...
          </Paragraph>
          <EmphasizedParagraph>
            <EmojiWrapper>😳</EmojiWrapper> But do not post answers here!{" "}
            <EmojiWrapper>😳</EmojiWrapper>
          </EmphasizedParagraph>
        </BodyWrapper>
      }
      buttons={
        <>
          <Button size="large" onClick={handleClick}>
            Got it
          </Button>
        </>
      }
    />
  );
};

export default ChatWarningModal;
