import React from "react";
import PageHeading from "../PageHeading";

export default () => {
  return (
    <>
      <PageHeading>Assisting Team</PageHeading>
    </>
  );
};
