/*
 * For hosts:
 *   Default: hear other hosts that are not assisting teams
 *   If assisting: hear team and other hosts assisting this team
 *
 * For players:
 *   Default: hear hosts and other team members
 *   If host assisting your team: hear assisting host and other team members
 *   If host assisting other team, hear non-assisting host and other team
 *     members
 *
 * For late joining players:
 *   No audio
 */

import { useContext } from "react";
import QuizContext from "../../../../contexts/QuizContext";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import getTeamAudio from "./getTeamAudio";
import getHostsAssistingMyTeamAudio from "./getHostsAssistingMyTeamAudio";
import getHostsNotAssistingTeamsAudio from "./getHostsNotAssistingTeamsAudio";
import getOtherHostsAssistingThisTeam from "./getOtherHostsAssistingThisTeam";

const getTeamBreakoutAudio = () => {
  const { myHostId } = useContext(SessionDataContext);
  const { myTeam, assistingTeam } = useContext(QuizContext);

  let result = [];

  if (myHostId) {
    if (!assistingTeam) {
      result = getHostsNotAssistingTeamsAudio();
    } else {
      result = [
        ...getOtherHostsAssistingThisTeam(assistingTeam),
        ...getTeamAudio(assistingTeam),
      ];
    }
  } else if (myTeam) {
    result = [
      ...getHostsNotAssistingTeamsAudio(),
      ...getHostsAssistingMyTeamAudio(),
      ...getTeamAudio(myTeam),
    ];
  } else {
    // participant is not a host and not in a team so shouldn't hear any audio
  }

  return result;
};

export default getTeamBreakoutAudio;
