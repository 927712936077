import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation AssistTeam($teamId: ID!) {
      assistTeam(teamId: $teamId) {
        ok
      }
    }
  `);
