import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation AdjustScore($teamId: ID!, $correct: Boolean!) {
      adjustScore(teamId: $teamId, correct: $correct) {
        quiz {
          id
          whatToShow
        }
      }
    }
  `);
