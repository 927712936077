import { useEffect, useState } from "react";

const useVideoTrackPublication = (publications) => {
  const [videoTrackPublication, setVideoTrackPublication] = useState();

  useEffect(() => {
    setVideoTrackPublication(
      publications.find(
        (pub) => pub.kind === "video" && !pub.trackName.includes("screen")
      )
    );
  }, [publications]);

  return videoTrackPublication;
};

export default useVideoTrackPublication;
