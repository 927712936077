import React, { useContext, useEffect, useState } from "react";
import Question from "../QuestionAndAnswer/Question";
import QuizContext from "../../contexts/QuizContext";

export default ({ quiz }) => {
  const { round, question, myTeamAnswer, teamAnswerQuestion } = useContext(
    QuizContext
  );

  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);

  const handleAnswer = async (answerText) => {
    setIsSubmittingAnswer(true);
    await teamAnswerQuestion(answerText);
  };

  useEffect(() => {
    setIsSubmittingAnswer(false);
  }, [quiz.whatToShow]);

  return (
    <Question
      quiz={quiz}
      roundNumber={round.number}
      round={round}
      questionNumber={question.number}
      question={question}
      status={status}
      myTeamAnswer={myTeamAnswer}
      onAnswer={handleAnswer}
      isSubmitting={isSubmittingAnswer}
    />
  );
};
