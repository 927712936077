import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import Leaderboard from "../../../components/leaderboard/Leaderboard";
import ScreenTitle from "../../../components/titles/ScreenTitle";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import GridVerticalScrollLayout from "../../../components/layouts/GridVerticalScrollLayout";

const RoundResultsScreen = ({
  audio,
  isHost,
  hostAvatars,
  quizName,
  roundNumber,
  teams,
  moreRounds,
  tieExists,
  onQuizResults,
  resetButton,
  navigation,
  chat,
  modal,
}) => {
  let quizResultsText = "Show Final Scores";
  if (moreRounds) {
    quizResultsText = "Show Total Scores";
  } else if (tieExists) {
    quizResultsText = "Show Tied Final Scores";
  }

  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        hostAvatars={hostAvatars}
        navigation={navigation}
        chat={chat}
        payload={
          <GridVerticalScrollLayout
            header={
              <ScreenTitle
                quizName={quizName}
                location={`Round ${roundNumber} Results`}
              />
            }
            content={<Leaderboard teams={teams} />}
          />
        }
        actionBar={
          isHost && (
            <ActionBar>
              <LargeDarkButton
                onClick={onQuizResults}
                data-cy-name={"Show Total Scores"}
              >
                {quizResultsText}
              </LargeDarkButton>
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default RoundResultsScreen;
