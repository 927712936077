import React, { useContext, useState } from "react";
import QuizResultsScreen from "../../../screens/quiz/11/QuizResultsScreen";
import QuizNavigationController from "../QuizNavigationController";
import QuizChatController from "../QuizChatController";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import QuizContext from "../../../../contexts/QuizContext";
import useNextRoundMutation from "../../../hooks/mutations/useNextRoundMutation";
import useQuizConversationMutation from "../../../hooks/mutations/useQuizConversationMutation";
import getAllAudio from "../AudioController/getAllAudio";
import SkipRemainingRoundsModal from "../../../modals/SkipRemainingRoundsModal";
import useShowTiebreakerMutation from "../../../hooks/mutations/useShowTiebreakerMutation";
import styled from "styled-components";
import TeamController from "../TeamController";
import getHostAvatarControllerList from "../AvatarControllers/getHostAvatarControllerList";

const StyledTeamController = styled(TeamController)`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const QuizResultsController = ({ resetButton }) => {
  const { myHostId } = useContext(SessionDataContext);
  const {
    quizName,
    teams,
    tiebreakerNext,
    tieExists,
    tiebreakerPlayed,
    roundNumber,
    moreRounds,
  } = useContext(QuizContext);
  const [nextRoundMutation] = useNextRoundMutation();
  const [showTiebreakerMutation] = useShowTiebreakerMutation();
  const [quizConversationMutation] = useQuizConversationMutation();
  const [
    showSkipRemainingRoundsModal,
    setShowSkipRemainingRoundsModal,
  ] = useState(false);

  const handleNextRound = async () => await nextRoundMutation();
  const handleQuizConversation = async () => await quizConversationMutation();
  const handleTiebreaker = async () => await showTiebreakerMutation();

  const handleSkipRemainingRounds = async () =>
    await quizConversationMutation();

  let next;
  if (tiebreakerPlayed) {
    next = "quiz-conversation";
  } else if (moreRounds) {
    next = "round";
  } else if (tiebreakerNext) {
    next = "tiebreaker";
  } else {
    next = "quiz-conversation";
  }

  const compareScores = (a, b) => {
    if (a.score < b.score) {
      return 1;
    } else if (a.score > b.score) {
      return -1;
    } else {
      return 0;
    }
  };

  const rankings = {};
  if (teams) {
    teams.forEach((team) => {
      const otherTeamsWithHigherScores = teams.filter((otherTeam) => {
        return otherTeam.score > team.score;
      });

      rankings[team.id] = otherTeamsWithHigherScores.length + 1;
    });
  }

  const topTeam = teams && teams.sort(compareScores)[0];
  const winningTeams =
    topTeam && teams.filter((team) => team.score === topTeam.score);
  const winningTeamEmojis =
    winningTeams && winningTeams.map((team) => team.emoji);

  return (
    <QuizResultsScreen
      audio={getAllAudio()}
      isHost={Boolean(myHostId)}
      hostAvatars={getHostAvatarControllerList()}
      quizName={quizName}
      winningTeamEmojis={winningTeamEmojis}
      teams={
        teams &&
        teams
          .sort(compareScores)
          .map((team) => (
            <StyledTeamController
              key={team.id}
              team={team}
              leaderboard
              showTrophies
              quizRanking={rankings[team.id]}
            />
          ))
      }
      next={next}
      onNextRound={handleNextRound}
      roundNumber={roundNumber}
      onShowSkipRemainingRoundsModal={() =>
        setShowSkipRemainingRoundsModal(true)
      }
      onTiebreaker={handleTiebreaker}
      onQuizConversation={handleQuizConversation}
      resetButton={resetButton}
      navigation={<QuizNavigationController />}
      chat={<QuizChatController />}
      modal={
        <>
          {showSkipRemainingRoundsModal && (
            <SkipRemainingRoundsModal
              tieExists={tieExists}
              onSkip={handleSkipRemainingRounds}
              onTiebreaker={handleTiebreaker}
              onCancel={() => setShowSkipRemainingRoundsModal(false)}
            />
          )}
        </>
      }
    />
  );
};

export default QuizResultsController;
