import { useEffect, useState } from "react";
import getLogger from "../../../util/getLogger";
import _ from "lodash";

const logger = getLogger("useParticipants");

const useParticipants = (room) => {
  const [participants, setParticipants] = useState({});

  const addParticipant = (participant) =>
    setParticipants((currentParticipants) => ({
      ...currentParticipants,
      [participant.identity]: participant,
    }));

  const removeParticipant = (participant) =>
    setParticipants((currentParticipants) =>
      _.omit(currentParticipants, participant.identity)
    );

  useEffect(() => {
    if (room) {
      const participantConnected = (participant) => {
        logger.info(`Participant connected: ${participant.identity}`);
        logger.debug(participant);
        addParticipant(participant);
      };

      const participantDisconnected = (participant) => {
        logger.info(`Participant disconnected: ${participant.identity}`);
        logger.debug(participant);
        removeParticipant(participant);
      };

      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);

      room.participants.forEach((participant) => {
        participantConnected(participant);
      });

      return () => {
        room.off("participantConnected", participantConnected);
        room.off("participantDisconnected", participantDisconnected);
      };
    }
  }, [room]);

  const localParticipant = room && room.localParticipant;

  useEffect(() => {
    if (room && localParticipant) {
      addParticipant(localParticipant);

      return () => {
        removeParticipant(localParticipant);
      };
    }
  }, [room, localParticipant]);

  return participants;
};

export default useParticipants;
