import React from "react";
import styled from "styled-components";
import ordinalSuffixOf from "../../../../../util/ordinalSuffixOf";
import { REGULAR_TEXT } from "../../../../../colors";

const Wrapper = styled.div`
  margin-top: 10px;
  flex: 0 0 60px;
  font-size: 24px;
  font-weight: bold;
  color: ${REGULAR_TEXT};
  ${({ size }) =>
    size === "small" &&
    `
    font-size: 20px;
    flex: 0 0 50px;

  `}
  ${({ size }) =>
    size === "tiny" &&
    `
    font-size: 16px;
    flex: 0 0 40px;
  `}
`;

const TeamRanking = ({ ranking }) => {
  return <Wrapper>{ordinalSuffixOf(ranking)}</Wrapper>;
};

export default TeamRanking;
