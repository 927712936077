import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation CreateMultipleTeams($numTeams: Int!, $randomAssignment: Boolean!) {
      createMultipleTeams(
        numTeams: $numTeams
        randomAssignment: $randomAssignment
      ) {
        ok
      }
    }
  `);
