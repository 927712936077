import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import ScreenTitle from "../../../components/titles/ScreenTitle";
import TeamGridLayout from "../../../components/layouts/TeamGridLayout";
import GridVerticalScrollLayout from "../../../components/layouts/GridVerticalScrollLayout";

const MeetTeamsScreen = ({
  audio,
  hostAvatars,
  isHost,
  quizName,
  teams,
  onFirstRound,
  resetButton,
  navigation,
  chat,
  modal,
}) => {
  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        hostAvatars={hostAvatars}
        navigation={navigation}
        chat={chat}
        payload={
          <GridVerticalScrollLayout
            header={
              <ScreenTitle quizName={quizName} location={"Meet the Teams"} />
            }
            content={<TeamGridLayout teams={teams} />}
          />
        }
        actionBar={
          isHost && (
            <ActionBar>
              <LargeDarkButton
                onClick={onFirstRound}
                data-cy-name={"First Round"}
              >
                First Round
              </LargeDarkButton>
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default MeetTeamsScreen;
