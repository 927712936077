import React, { useContext, useState } from "react";
import QuizContext from "../../../../contexts/QuizContext";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import DropPlayerOntoTeamController from "../DropPlayerOntoTeamController";
import ContextMenuController from "../../ContextMenuController";
import DropdownMenu from "../../../components/menus/DropdownMenu";
import styled from "styled-components";
import useDeleteTeamMutation from "../../../hooks/mutations/useDeleteTeamMutation";
import DeleteTeamModal from "../../../modals/DeleteTeamModal";
import CompactTeamController from "./CompactTeamController";
import RegularTeamController from "./RegularTeamController";
import LeaderboardTeamController from "./LeaderboardTeamController";

const TeamWrapper = styled.div`
  width: 100%;
  ${(props) =>
    props.leaderboard &&
    `
    display: flex;
    justify-content: right;
  `}
`;

const TeamController = ({
  team,
  compact,
  naming,
  leaderboard,
  roundRanking,
  quizRanking,
  showTrophies,
  inTiebreaker,
  showAnswer,
  answerGraded,
  lateJoin,
  ...rest
}) => {
  const { myHostId } = useContext(SessionDataContext);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);
  const [deleteTeamMutation] = useDeleteTeamMutation();
  const { getTeamAnswer, getTeamTiebreaker } = useContext(QuizContext);

  const teamAnswerOrTiebreaker = inTiebreaker
    ? getTeamTiebreaker(team)
    : getTeamAnswer(team);

  const handleContextMenu = () => {
    event.preventDefault();
    event.stopPropagation();
    setShowContextMenu(true);
  };

  const handleContextMenuSelect = (value) => {
    if (value === "delete") {
      setShowDeleteTeamModal(true);
    }

    setShowContextMenu(false);
  };

  const handleDeleteTeam = async () => {
    await deleteTeamMutation({ variables: { teamId: team.id } });
  };

  const isHost = Boolean(myHostId);

  let type = "regular";
  if (compact) {
    type = "compact";
  }
  if (leaderboard) {
    type = "leaderboard";
  }

  return (
    <>
      <DropPlayerOntoTeamController team={team} {...rest}>
        <TeamWrapper
          onContextMenu={isHost ? handleContextMenu : undefined}
          leaderboard={leaderboard}
        >
          {type === "compact" && (
            <CompactTeamController
              key={team.id}
              team={team}
              naming={naming}
              isHost={isHost}
              teamAnswerOrTiebreaker={teamAnswerOrTiebreaker}
            />
          )}
          {type === "regular" && (
            <RegularTeamController
              team={team}
              inTiebreaker={inTiebreaker}
              showAnswer={showAnswer}
              answerGraded={answerGraded}
              isHost={isHost}
              lateJoin={lateJoin}
            />
          )}
          {type === "leaderboard" && (
            <LeaderboardTeamController
              team={team}
              showTrophies={showTrophies}
              isHost={isHost}
              roundRanking={roundRanking}
              quizRanking={quizRanking}
            />
          )}
        </TeamWrapper>
      </DropPlayerOntoTeamController>
      {showContextMenu && (
        <ContextMenuController
          dropdownMenu={
            <DropdownMenu
              items={[{ value: "delete", label: "Delete Team" }]}
              onSelect={handleContextMenuSelect}
              onClickOutside={() => setShowContextMenu(false)}
            />
          }
        />
      )}
      {showDeleteTeamModal && (
        <DeleteTeamModal
          teamName={team.name}
          onDelete={handleDeleteTeam}
          onCancel={() => setShowDeleteTeamModal(false)}
        />
      )}
    </>
  );
};

export default TeamController;
