import styled from "styled-components";
import { LIGHT_MUTED_TEXT } from "../../colors";

const InstructionText = styled.div`
  font-size: 18px;
  color: ${LIGHT_MUTED_TEXT};
  line-height: 150%;
  font-weight: normal;
  max-width: 500px;
`;

export default InstructionText;
