import React from "react";
import TeamHeader from "../teams/TeamHeader";
import styled from "styled-components";
import GradientCard from "../elements/cards/GradientCard";
import { PARAGRAPH_LARGE_BOLD, PARAGRAPH_NORMAL } from "../../typography";
import {
  ELEMENT_LIGHT,
  ELEMENT_OFF_WHITE,
  TEXT_LIGHT,
  WHITE,
} from "../../colors";
import LargeDarkButton from "../buttons/LargeDarkButton";
import EditableEmoji from "../elements/emojis/EditableEmoji";
import LargeMainButton from "../buttons/LargeMainButton";
import MainInput from "../inputs/MainInput";
import Emoji from "../elements/emojis/Emoji";
import SpinnerIcon from "../../icons/SpinnerIcon";
import TippyTooltip from "../elements/tooltips/TippyTooltip";

const Wrapper = styled(GradientCard)`
  padding: 30px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameTeamInstruction = styled.div`
  ${PARAGRAPH_NORMAL}
  color: ${WHITE};
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  max-width: 600px;
`;

const TeamHeaderWrapper = styled.div`
  margin: 0 10px;
`;

const InputLine = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0 0 10px 10px;
  background-color: ${ELEMENT_LIGHT};
  padding: 20px 0;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  flex: 0;
  margin-right: 5px;
  display: flex;
  align-items: center;
  position: relative;
`;

const MainInputWrapper = styled.div`
  flex: 1;
  min-width: 400px;
  margin-right: 5px;
`;

const EmojiPickerWrapper = styled.div`
  position: absolute;
  right: -348px;
  z-index: 5;
`;

const EmojiWrapper = styled.div`
  margin-right: 8px;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputViewLine = styled(InputLine)`
  background-color: ${ELEMENT_OFF_WHITE};
  ${PARAGRAPH_LARGE_BOLD}
  color: ${TEXT_LIGHT};
  height: 30px;
  position: relative;
`;

const TeamNameAndEmoji = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SpinnerIconWrapper = styled.div`
  position: absolute;
  right: -30px;
  display: flex;
  align-items: center;
`;

const NameTeamCard = ({
  leader,
  leaderName,
  emoji,
  teamName,
  submitting,
  onShowEmojiPicker,
  emojiPicker,
  onTeamNameChange,
  onSubmitNameAndEmoji,
}) => {
  let tooltipContent = "Enter a name and choose an emoji to submit";
  let tooltipVisible;
  if (emoji && !teamName) {
    tooltipContent = "Enter a name to submit";
  } else if (!emoji && teamName) {
    tooltipContent = "Choose an emoji to submit";
  } else if (emoji && teamName) {
    tooltipContent = undefined;
    tooltipVisible = false;
  }

  return (
    <Wrapper>
      {leader && (
        <NameTeamInstruction>
          You are the leader of this team, so you are in charge of entering the
          name and selecting an emoji. Some examples for inspiration:
        </NameTeamInstruction>
      )}
      {!leader && (
        <NameTeamInstruction>
          Help your team come up with a team name and emoji. {leaderName} is
          responsible for submitting. Some examples for inspiration:
        </NameTeamInstruction>
      )}
      <NameTeamInstruction>
        <TeamHeaderWrapper>
          <TeamHeader emoji={"💃"} name={"Trivia Newton John"} />
        </TeamHeaderWrapper>
        <TeamHeaderWrapper>
          <TeamHeader emoji={"🔨"} name={"E=MC Hammer"} />
        </TeamHeaderWrapper>
        <TeamHeaderWrapper>
          <TeamHeader emoji={"👶"} name={"Quiz Me Baby One More Time"} />
        </TeamHeaderWrapper>
      </NameTeamInstruction>
      {leader && (
        <InputLine>
          <InputWrapper>
            <ButtonWrapper>
              {emoji ? (
                <EditableEmoji
                  onClick={onShowEmojiPicker}
                  emoji={emoji}
                  size={"xlarge"}
                />
              ) : (
                <LargeDarkButton
                  disabled={submitting}
                  onClick={onShowEmojiPicker}
                  noSpinner
                >
                  Choose Emoji
                </LargeDarkButton>
              )}
              {emojiPicker && (
                <EmojiPickerWrapper>{emojiPicker}</EmojiPickerWrapper>
              )}
            </ButtonWrapper>
            <MainInputWrapper>
              <MainInput
                name={"name"}
                placeholder={"Enter your team name"}
                value={teamName}
                disabled={submitting}
                onChange={(event) => onTeamNameChange(event.target.value)}
                onSubmit={onSubmitNameAndEmoji}
              />
            </MainInputWrapper>
            <TippyTooltip content={tooltipContent} visible={tooltipVisible}>
              <SubmitButtonWrapper>
                <LargeMainButton
                  id={"name-team-button"}
                  disabled={!(teamName && emoji) || submitting}
                  showSpinner={submitting}
                  onClick={onSubmitNameAndEmoji}
                  data-cy-name={"Submit"}
                >
                  Submit
                </LargeMainButton>
              </SubmitButtonWrapper>
            </TippyTooltip>
          </InputWrapper>
        </InputLine>
      )}
      {!leader && (
        <InputViewLine>
          {!teamName && !emoji && (
            <>Waiting for {leaderName} to enter a name and emoji.</>
          )}
          {teamName && !emoji && <>{teamName}</>}
          {emoji && !teamName && (
            <>
              <EmojiWrapper>
                <Emoji size={"large"} emoji={emoji} />
              </EmojiWrapper>
              Waiting for {leaderName} (your team leader) to enter a name
            </>
          )}
          {emoji && teamName && (
            <TeamNameAndEmoji>
              <EmojiWrapper>
                <Emoji size={"large"} emoji={emoji} />
              </EmojiWrapper>
              {teamName}
              {submitting && (
                <SpinnerIconWrapper>
                  <SpinnerIcon size={20} color={TEXT_LIGHT} />
                </SpinnerIconWrapper>
              )}
            </TeamNameAndEmoji>
          )}
        </InputViewLine>
      )}
    </Wrapper>
  );
};

export default NameTeamCard;
