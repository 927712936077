import Team from "../../../components/teams/Team";
import AvatarController from "../AvatarControllers/AvatarController";
import React, { useContext } from "react";
import useLastDominantSpeakerIdOnTeam from "./useLastDominantSpeakerIdOnTeam";
import useAdjustScoreMutation from "../../../hooks/mutations/useAdjustScoreMutation";
import useChangeClosestTeamTiebreakerMutation from "../../../hooks/mutations/useChangeClosestTeamTiebreakerMutation";
import useJoinTeamMutation from "../../../hooks/mutations/useJoinTeamMutation";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import QuizContext from "../../../../contexts/QuizContext";
import getTeamSelfies from "./getTeamSelfies";

const RegularTeamController = ({
  team,
  inTiebreaker,
  showAnswer,
  answerGraded,
  isHost,
  lateJoin,
}) => {
  const { refetch: refetchSessionData } = useContext(SessionDataContext);

  const [joinTeamMutation] = useJoinTeamMutation();
  const { getTeamAnswer, getTeamTiebreaker } = useContext(QuizContext);

  const [adjustScoreMutation] = useAdjustScoreMutation();
  const [
    changeClosestTeamTiebreaker,
  ] = useChangeClosestTeamTiebreakerMutation();

  const lastDominantSpeakerIdOnTeam = useLastDominantSpeakerIdOnTeam(team);
  const lastDominantSpeakerOnTeam = team.players.find(
    (player) => player.id === lastDominantSpeakerIdOnTeam
  );
  const teamLeader = team.players.find((player) => player.leader);

  const teamAnswerOrTiebreaker = inTiebreaker
    ? getTeamTiebreaker(team)
    : getTeamAnswer(team);

  const playerToShow = lastDominantSpeakerOnTeam || teamLeader;

  const handleChangeGrade = async () => {
    if (inTiebreaker) {
      await changeClosestTeamTiebreaker({
        variables: {
          teamId: team.id,
        },
      });
    } else {
      await adjustScoreMutation({
        variables: {
          teamId: team.id,
          correct: !teamAnswerOrTiebreaker.correct,
        },
      });
    }
  };

  const handleJoinTeam = async () => {
    await joinTeamMutation({ variables: { teamId: team.id } });
    await refetchSessionData();
  };

  return (
    <Team
      key={team.id}
      teamId={team.id}
      name={team.name}
      emoji={team.emoji}
      dominantAvatar={
        playerToShow && <AvatarController player={playerToShow} />
      }
      teamSelfies={getTeamSelfies(team)}
      inTiebreaker={inTiebreaker}
      showAnswer={showAnswer}
      answerText={teamAnswerOrTiebreaker && teamAnswerOrTiebreaker.text}
      answerCorrect={
        teamAnswerOrTiebreaker &&
        (inTiebreaker
          ? teamAnswerOrTiebreaker.closest
          : teamAnswerOrTiebreaker.correct)
      }
      answerGraded={answerGraded || isHost}
      canChangeGrade={isHost && (answerGraded ? "subtle" : true)}
      onChangeGrade={handleChangeGrade}
      lateJoin={lateJoin}
      onJoin={handleJoinTeam}
    />
  );
};

export default RegularTeamController;
