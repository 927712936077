import React from "react";
import styled from "styled-components";
import TeamList from "../../TeamListNew";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export default () => {
  return (
    <Wrapper>
      <TeamList />
    </Wrapper>
  );
};
