import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import styled from "styled-components";
import TeamGridLayout from "../../../components/layouts/TeamGridLayout";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import CompactQuestion from "../../../components/questions/CompactQuestion";
import GridVerticalScrollLayout from "../../../components/layouts/GridVerticalScrollLayout";

const QuestionWrapper = styled.div`
  margin-bottom: 10px;
`;

const QuestionResultsScreen = ({
  audio,
  isHost,
  hostAvatars,
  next = "question",
  questionText,
  answerText,
  teams,
  onShowFacts,
  onNextQuestion,
  onShowRoundScores,
  resetButton,
  navigation,
  chat,
  modal,
}) => {
  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        hostAvatars={hostAvatars}
        navigation={navigation}
        chat={chat}
        payload={
          <GridVerticalScrollLayout
            header={
              <QuestionWrapper>
                <CompactQuestion
                  text={questionText}
                  answerText={answerText}
                  answerCorrect
                />
              </QuestionWrapper>
            }
            content={<TeamGridLayout teams={teams} />}
          />
        }
        actionBar={
          isHost && (
            <ActionBar>
              {next === "facts" && (
                <LargeDarkButton onClick={onShowFacts}>
                  Show Facts
                </LargeDarkButton>
              )}
              {next === "question" && (
                <LargeDarkButton onClick={onNextQuestion}>
                  Next Question
                </LargeDarkButton>
              )}
              {next === "round-results" && (
                <LargeDarkButton onClick={onShowRoundScores}>
                  Round Results
                </LargeDarkButton>
              )}
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default QuestionResultsScreen;
