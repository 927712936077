import React, { useContext } from "react";
import styled from "styled-components";
import TeamListContainer from "./TeamListContainer";
import QuizContext from "../../../contexts/QuizContext";
import SurveyResults from "../../Survey/SurveyResults";
import DominantSpeakerContainer from "./DominantSpeakerContainer";
import { NEW_WAITING_ROOM } from "../../../flags";
import {
  NAME_TEAMS,
  QUIZ_INTRO,
  QUIZ_CONVERSATION,
  ROUND_INTRO,
} from "../../../constants";
import TeamFormationInstructions from "../../Instructions/TeamFormationInstructions";
import GameInstructions from "../../Instructions/GameInstructions";

const Wrapper = styled.section`
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  height: 1px;
  width: 100%;
`;

const Scroller = styled.div`
  height: 100%;
  width: 100%;
  overflow: scroll;
`;

const Spacer = styled.div`
  height: 150px;
`;

export default () => {
  const {
    quiz,
    started,
    ended,
    whatToShow,
    myHostId,
    assistingTeamId,
    myTeamId,
    myTeamNamed,
    roundNumber,
  } = useContext(QuizContext);

  if (!quiz) {
    return null;
  }

  const renderContent = () => {
    if (!started && NEW_WAITING_ROOM) {
      return <DominantSpeakerContainer />;
    }

    if (assistingTeamId) {
      return null;
    }

    if (!ended && whatToShow === QUIZ_CONVERSATION) {
      return <DominantSpeakerContainer />;
    }

    if (whatToShow === QUIZ_INTRO) {
      if (myHostId) {
        return <TeamFormationInstructions />;
      } else {
        return null;
      }
    }

    if (whatToShow === ROUND_INTRO) {
      if (myHostId && roundNumber === 1) {
        return <GameInstructions />;
      }
    }

    if (whatToShow === NAME_TEAMS && myTeamId && !myTeamNamed) {
      return null;
    }

    if (started && !ended) {
      return <TeamListContainer />;
    }

    if (ended && myHostId) {
      return <SurveyResults quiz={quiz} />;
    }
  };

  return (
    <Wrapper>
      <Scroller>
        {renderContent()}
        <Spacer />
      </Scroller>
    </Wrapper>
  );
};
