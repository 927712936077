import React, { useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";
import RemoteParticipantListener from "./RemoteParticipantListener";
import getLogger from "../../util/getLogger";

const Wrapper = styled.div`
  display: none;
`;

const RemoteParticipantsListener = ({
  room,
  participants,
  setParticipants,
  setParticipantsWithAudio,
  setParticipantsWithVideo,
}) => {
  const logger = getLogger(`RemoteParticipantsListener: ${room}`);

  useEffect(() => {
    const participantConnected = (participant) => {
      logger.info(`Participant connected: ${participant.identity}`);
      logger.debug(participant);
      setParticipants((currentParticipants) => ({
        ...currentParticipants,
        [participant.identity]: participant,
      }));
    };

    const participantDisconnected = (participant) => {
      logger.info(`Participant disconnected: ${participant.identity}`);
      logger.debug(participant);
      setParticipants((currentParticipants) =>
        _.omit(currentParticipants, participant.identity)
      );
    };

    const addRoomListeners = () => {
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      logger.debug("Added all room event listeners");
    };

    const removeRoomListeners = () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
      logger.debug("Removed all room event listeners");
    };

    const connectToExistingParticipants = () => {
      logger.info("Connecting to participants that are already in them room");
      room.participants.forEach((participant) => {
        participantConnected(participant);
      });
      logger.info(
        "Finished connecting to participants that were already in the room"
      );
    };

    addRoomListeners();
    connectToExistingParticipants();

    return () => {
      setParticipants({});
      removeRoomListeners();
    };
  }, []);

  return (
    <Wrapper>
      {Object.values(participants).map((participant) => (
        <RemoteParticipantListener
          key={participant.identity}
          participant={participant}
          setParticipantsWithAudio={setParticipantsWithAudio}
          setParticipantsWithVideo={setParticipantsWithVideo}
        />
      ))}
    </Wrapper>
  );
};

export default RemoteParticipantsListener;
