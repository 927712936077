import React, { useContext, useRef, useState } from "react";
import {
  // DragPreviewImage,
  useDrag,
  useDrop,
} from "react-dnd";
import ItemTypes from "../../util/dndItemTypes";
import QuizContext from "../../contexts/QuizContext";
import styled from "styled-components";
import LoadingScreen from "../LoadingScreen";
// import previewImage from "./preview.png";

const Wrapper = styled.div`
  ${({ highlight }) =>
    highlight &&
    `
    margin-left: -3px;
    border-left: 3px solid blue;
    z-index: 5;
  `}
`;

export default ({ player, children }) => {
  const { makeTeamLeader, myHostId } = useContext(QuizContext);
  const [isDropping, setIsDropping] = useState(false);

  const ref = useRef();

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.PLAYER, ...player },
    canDrag: () => !!myHostId,
    // end: (item, monitor) => {
    //   if (monitor.didDrop()) {
    //     console.log("ready to restart video for player ", item);
    //   } else {
    //     console.log("drop did not do anything, so no restart");
    //   }
    // },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.PLAYER,
    canDrop: (item) => {
      if (item.id === player.id) {
        return false;
      }
      if (item.team && player.team) {
        if (item.team.id === player.team.id) {
          if (player.leader) {
            return true;
          }
        }
      }
      return false;
    },
    drop: async (item) => {
      setIsDropping(true);
      await makeTeamLeader(item.id);
      setIsDropping(false);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  drag(drop(ref));

  return (
    <Wrapper highlight={isOver && canDrop}>
      {/*<DragPreviewImage connect={preview} src={previewImage} />*/}
      <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
        {children}
      </div>
      {isDropping && <LoadingScreen />}
    </Wrapper>
  );
};
