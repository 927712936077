import React from "react";
import styled from "styled-components";
import SubtleButton from "../buttons/SubtleButton";
import { BRAND, LIGHT_GREY_1, REGULAR_TEXT, WHITE } from "../../colors";

const Wrapper = styled.div`
  border-radius: 12px;
  background-color: ${LIGHT_GREY_1};
  display: flex;
  margin: 10px 0 20px;
`;

const ScoreWrapper = styled.div`
  background-color: ${LIGHT_GREY_1};
  color: ${REGULAR_TEXT};
  font-size: 18px;
  font-weight: bold;
  width: 48px;
  padding: 15px 0;
  text-align: center;
  border-radius: 12px;

  :hover {
    background-color: rgba(144, 96, 235, 0.1);
    cursor: pointer;
  }

  ${({ selected }) =>
    selected &&
    `
    background-color: ${BRAND};
    color: ${WHITE};
    
    :hover {
      background-color: ${BRAND};
    }
  `}
`;

const Score = ({ value, selected }) => {
  return <ScoreWrapper selected={selected}>{value}</ScoreWrapper>;
};

export default ({ value, onChange }) => {
  const scores = [...Array(10)].map((_, i) => i + 1);

  const handleClick = (score) => () => {
    onChange(score);
  };

  return (
    <Wrapper>
      {scores.map((score) => (
        <SubtleButton
          key={score}
          type="button"
          onClick={handleClick(score)}
          data-cy={`nps-${score}`}
        >
          <Score value={score} selected={score === value} />
        </SubtleButton>
      ))}
    </Wrapper>
  );
};
