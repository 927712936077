import { useCallback, useEffect, useState } from "react";
import JSConfetti from "js-confetti";
import useInterval from "../../../hooks/useInterval";

const Confetti = ({
  confettiThrows = [0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 0],
  timeBetweenThrows = 1000,
  emojis,
  onComplete,
}) => {
  const [jsConfetti, setJsConfetti] = useState();
  const [confettiThrowsCompleted, setConfettiThrowsCompleted] = useState(0);
  const [onCompleteCalled, setOnCompleteCalled] = useState(false);

  useEffect(() => {
    setJsConfetti(new JSConfetti());
  }, []);

  const throwConfetti = useCallback(() => {
    if (jsConfetti) {
      if (confettiThrowsCompleted < confettiThrows.length) {
        const showEmoji = confettiThrows[confettiThrowsCompleted];

        jsConfetti.addConfetti({
          emojis: emojis && showEmoji ? emojis : undefined,
          confettiColors: ["#F3D56A", "#F5B659", "#7EC6DC", "#8263AE"],
          confettiRadius: 8,
          confettiNumber: 150,
        });
        setConfettiThrowsCompleted((prev) => prev + 1);
      } else {
        if (!onCompleteCalled) {
          onComplete();
          setOnCompleteCalled(true);
        }
      }
    }
  }, [jsConfetti, confettiThrowsCompleted, confettiThrows, onCompleteCalled]);

  useInterval(() => {
    throwConfetti();
  }, timeBetweenThrows);

  return null;
};

export default Confetti;
