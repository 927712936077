import React from "react";
import styled from "styled-components";
import { LIGHT_GREY_1 } from "../../../colors";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  color: ${LIGHT_GREY_1};
  text-align: center;
`;

const MutedMessage = () => {
  return <Wrapper>muted</Wrapper>;
};

export default MutedMessage;
