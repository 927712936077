import React, { useContext } from "react";
import styled from "styled-components";
import QuizContext from "../../contexts/QuizContext";
import { LIGHT_GREY_2, MUTED_TEXT, REGULAR_TEXT } from "../../colors";

const RoundIntroContainer = styled.div`
  margin-top: 30px;
  background-color: ${LIGHT_GREY_2};
  border-radius: 24px;
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
`;

const RoundNumber = styled.div`
  font-size: 36px;
  color: ${MUTED_TEXT};
  line-height: 150%;
`;

const RoundTitle = styled.div`
  margin-top: 20px;
  font-size: 50px;
  line-height: 130%;
  font-weight: bold;
  color: ${REGULAR_TEXT};
  text-align: center;
`;

export default () => {
  const { round } = useContext(QuizContext);

  return (
    <RoundIntroContainer>
      <RoundNumber>Round {round.number}</RoundNumber>
      <RoundTitle>{round.title}</RoundTitle>
    </RoundIntroContainer>
  );
};
