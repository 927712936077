import React, { useContext } from "react";
import styled from "styled-components";
import QuizContext from "../../../contexts/QuizContext";
import MainArea from "../../MainArea";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
  overflow: hidden;
  padding: 20px;
`;

// const TestContainer = styled.div`
//   display: flex;
//   overflow-x: scroll;
//   width: 100%;
// `;
//
// const Test = styled.div`
//   width: 120px;
//   height: 90px;
//   background-color: red;
//   border: 1px solid blue;
//   flex-shrink: 0;
//`

export default () => {
  const { quiz } = useContext(QuizContext);

  if (!quiz) {
    return null;
  }

  return (
    <Wrapper>
      {/*<TestContainer>*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*  <Test />*/}
      {/*</TestContainer>*/}
      <MainArea />
    </Wrapper>
  );
};
