import React, { useContext } from "react";
import useAssistTeamMutation from "../../../hooks/mutations/useAssistTeamMutation";
import useFinishAssistingTeamMutation from "../../../hooks/mutations/useFinishAssistingTeamMutation";
import QuizContext from "../../../../contexts/QuizContext";
import LeaderboardTeam from "../../../components/leaderboard/LeaderboardTeam";
import getTeamSelfies from "./getTeamSelfies";

const LeaderboardTeamController = ({
  team,
  showTrophies,
  isHost,
  roundRanking,
  quizRanking,
}) => {
  const { assistingTeamId, getTeamRound } = useContext(QuizContext);

  const teamRound = getTeamRound(team);
  const score = showTrophies ? team.score : teamRound.score;

  const [assistTeamMutation] = useAssistTeamMutation();
  const [finishAssistingTeamMutation] = useFinishAssistingTeamMutation();

  const handleAssistTeam = async () => {
    await assistTeamMutation({ variables: { teamId: team.id } });
  };

  const handleFinishAssistingTeam = async () => {
    await finishAssistingTeamMutation();
  };

  return (
    <LeaderboardTeam
      key={team.id}
      position={showTrophies ? quizRanking : roundRanking}
      showTrophies={showTrophies}
      isHost={isHost}
      teamName={team.name}
      emoji={team.emoji}
      score={score}
      selfies={getTeamSelfies(team, 50)}
      onAssist={handleAssistTeam}
      assisting={team.id === assistingTeamId}
      onFinishAssisting={handleFinishAssistingTeam}
    />
  );
};

export default LeaderboardTeamController;
