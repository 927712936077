import React, { useContext } from "react";
import styled from "styled-components";
import QuizContext from "../../contexts/QuizContext";
import FakeDominantSpeaker from "./FakeDominantSpeaker";
import RealDominantSpeaker from "./RealDominantSpeaker";

const Wrapper = styled.div`
  width: 480px;
  height: 360px;
`;

const DominantSpeaker = () => {
  const { fakeDominantSpeakerId } = useContext(QuizContext);

  return (
    <Wrapper data-cy="dominant-speaker">
      {fakeDominantSpeakerId && <FakeDominantSpeaker />}
      {!fakeDominantSpeakerId && <RealDominantSpeaker isDominantSpeaker />}
    </Wrapper>
  );
};

export default DominantSpeaker;
