import React from "react";
import styled from "styled-components";
import { LIGHT_GREY_4 } from "../colors";

const Wrapper = styled.div`
  background-color: ${LIGHT_GREY_4};
  padding: 5px 7px;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 3px;
  margin-bottom: 3px;
`;

export default ({ player }) => {
  return <Wrapper data-cy="player-name">{player.name}</Wrapper>;
};
