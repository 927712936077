import QuizActionButton from "../buttons/QuizActionButton";
import DangerButton from "../buttons/DangerButton";
import React, { useContext, useState } from "react";
import ResetQuizModal from "../modals/ResetQuizModal";
import QuizContext from "../../contexts/QuizContext";

const MeetTeamsActions = () => {
  const { quiz, teams, nextRound, canResetQuiz } = useContext(QuizContext);

  const [showResetModal, setShowResetModal] = useState();

  const handleStartRound = () => nextRound();

  const handleResetClick = () => {
    setShowResetModal(true);
  };

  const handleCancelReset = () => {
    setShowResetModal(false);
  };

  const teamsAreStillNaming = !!teams.find((team) => team.name === null);

  return (
    <>
      <QuizActionButton
        size="large"
        label="Start Round"
        clickedLabel="Starting Round"
        disabled={teamsAreStillNaming}
        onClick={handleStartRound}
        data-cy="start-round-button"
      />
      {canResetQuiz && (
        <DangerButton size="large" onClick={handleResetClick}>
          Reset Quiz
        </DangerButton>
      )}
      {showResetModal && (
        <ResetQuizModal
          publicQuizId={quiz.publicId}
          onClose={handleCancelReset}
        />
      )}
    </>
  );
};

export default MeetTeamsActions;
