import React from "react";
import styled from "styled-components";
import SubtleButton from "../buttons/SubtleButton";
import { REGULAR_TEXT } from "../../colors";

const MAX_SCORE = 5;

const AvQualityWrapper = styled.div`
  display: flex;
  margin: 10px 0 20px;
  border-radius: 4px;
`;

const ScoreWrapper = styled.div`
  color: ${REGULAR_TEXT};
  font-weight: bold;
  width: 48px;
  padding: 15px 0;
  text-align: center;
  border-radius: 12px;
  font-size: 30px;
  filter: grayscale(100%);
  opacity: 0.5;

  :hover {
    cursor: pointer;
  }

  ${({ highlighted }) =>
    highlighted &&
    `
    filter: none;
    opacity: 1;
  `}
`;

const Score = ({ value, selectedValue }) => {
  let emoji = "⭐";
  if (value === MAX_SCORE - 1) {
    emoji = "🌟";
  } else if (value === MAX_SCORE) {
    emoji = "🤩";
  }

  return (
    <ScoreWrapper highlighted={selectedValue >= value}>{emoji}</ScoreWrapper>
  );
};

export default ({ value, onChange }) => {
  // const [selection, setSelection] = useState(null);

  const scores = [...Array(MAX_SCORE)].map((_, i) => i + 1);

  const handleClick = (score) => () => {
    onChange(score);
  };

  return (
    <AvQualityWrapper>
      {scores.map((score) => (
        <SubtleButton
          key={score}
          type="button"
          onClick={handleClick(score)}
          data-cy={`avQuality-${score}`}
        >
          <Score value={score} selectedValue={value} />
        </SubtleButton>
      ))}
    </AvQualityWrapper>
  );
};
