import React from "react";
import styled from "styled-components";
import PrimaryCard from "../elements/cards/PrimaryCard";
import Emoji from "../elements/emojis/Emoji";
import { ELEMENT_OFF_WHITE, WHITE } from "../../colors";
import { PARAGRAPH_NORMAL_BOLD, PARAGRAPH_SMALL_BOLD } from "../../typography";
import LoadingIcon from "../../icons/LoadingIcon";
import CrowdLayout from "../layouts/CrowdLayout";
import IconButton from "../buttons/IconButton";
import CloseIcon from "../../icons/CloseIcon";
import AssistIcon from "../../icons/AssistIcon";

const StyledCard = styled(PrimaryCard)`
  padding: 12px 15px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const LoadingIconWrapper = styled.div``;

const TeamNameAndEmojiWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const TeamNameWrapper = styled.div`
  color: ${WHITE};
  ${PARAGRAPH_NORMAL_BOLD}
`;

const LinesWrapper = styled.div`
  margin-left: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LineOneWrapper = styled.div`
  margin-bottom: 3px;
  height: 17px;
`;

const LineTwoWrapper = styled.div`
  display: flex;
  height: 17px;
`;

const AnswerWrapper = styled.div`
  color: ${ELEMENT_OFF_WHITE};
  ${PARAGRAPH_SMALL_BOLD}
  display: flex;
  align-items: center;
`;

const SelfiesWrapper = styled.div``;

const ActionsWrapper = styled.div`
  margin-left: 10px;
`;

const CompactTeam = ({
  isHost,
  naming,
  emoji,
  teamId,
  teamName,
  answer,
  selfies,
  assisting,
  onAssist,
  onFinishAssisting,
}) => {
  return (
    <StyledCard data-cy={"CompactTeam"} data-cy-id={teamId}>
      {naming && (
        <TeamNameAndEmojiWrapper>
          {emoji && <Emoji size={"large"} emoji={emoji} />}
          <LinesWrapper>
            <TeamNameWrapper>
              {teamName && <TeamNameWrapper>{teamName}</TeamNameWrapper>}
            </TeamNameWrapper>
            {!teamName && (
              <LoadingIconWrapper>
                <LoadingIcon />
              </LoadingIconWrapper>
            )}
          </LinesWrapper>
        </TeamNameAndEmojiWrapper>
      )}
      {!naming && (
        <>
          {emoji && <Emoji size={"large"} emoji={emoji} />}
          <LinesWrapper>
            <LineOneWrapper>
              {teamName && <TeamNameWrapper>{teamName}</TeamNameWrapper>}
              {!teamName && (
                <LoadingIconWrapper>
                  <LoadingIcon />
                </LoadingIconWrapper>
              )}
            </LineOneWrapper>
            <LineTwoWrapper>
              {answer && <AnswerWrapper>{answer}</AnswerWrapper>}
              {!answer && (
                <LoadingIconWrapper>
                  <LoadingIcon />
                </LoadingIconWrapper>
              )}
            </LineTwoWrapper>
          </LinesWrapper>
        </>
      )}
      <SelfiesWrapper>
        <CrowdLayout individuals={selfies} />
      </SelfiesWrapper>
      {isHost && (
        <ActionsWrapper>
          {!assisting && (
            <IconButton
              key={"assist"}
              onClick={onAssist}
              data-cy={"Assist"}
              data-cy-id={teamId}
            >
              <AssistIcon />
            </IconButton>
          )}
          {assisting && (
            <IconButton key={"finishAssisting"} onClick={onFinishAssisting}>
              <CloseIcon />
            </IconButton>
          )}
        </ActionsWrapper>
      )}
    </StyledCard>
  );
};

export default CompactTeam;
