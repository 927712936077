import styled from "styled-components";
import { PARAGRAPH_NORMAL_BOLD } from "../../typography";
import { WHITE } from "../../colors";

const InstructionList = styled.ol`
  ${PARAGRAPH_NORMAL_BOLD}
  color: ${WHITE};
  padding-left: 20px;

`;

export default InstructionList;
