import React from "react";
import GridGameScreenLayout from "../../../components/layouts/GridGameScreenLayout";
import styled from "styled-components";
import ActionBar from "../../../components/actionBar/ActionBar";
import LargeDarkButton from "../../../components/buttons/LargeDarkButton";
import ScreenTitle from "../../../components/titles/ScreenTitle";

const PayloadWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content 1fr;
  padding: 0 15px 0 10px;
`;

const GalleryWrapper = styled.div`
  height: 100%;
`;

const WaitingRoomScreen = ({
  isHost,
  quizName,
  count,
  pageNum,
  pageCount,
  galleryPage,
  onPrevPage,
  onNextPage,
  onSetPage,
  onStartQuiz,
  onShowInviteModal,
  resetButton,
  navigation,
  chat,
  audio,
  modal,
}) => {
  return (
    <>
      <GridGameScreenLayout
        audio={audio}
        navigation={navigation}
        chat={chat}
        payload={
          <PayloadWrapper>
            <ScreenTitle
              quizName={quizName}
              location={"Waiting Room"}
              count={count}
              pageNum={pageNum}
              pageCount={pageCount}
              onPrevPage={onPrevPage}
              onNextPage={onNextPage}
              onSetPage={onSetPage}
            />
            <GalleryWrapper>{galleryPage}</GalleryWrapper>
          </PayloadWrapper>
        }
        actionBar={
          isHost && (
            <ActionBar>
              <LargeDarkButton
                onClick={onStartQuiz}
                data-cy-name={"Start Quiz"}
              >
                Start Quiz
              </LargeDarkButton>
              <LargeDarkButton
                noSpinner
                onClick={onShowInviteModal}
                data-cy-name={"Invite"}
              >
                Invite
              </LargeDarkButton>
              {resetButton}
            </ActionBar>
          )
        }
        modal={modal}
      />
    </>
  );
};

export default WaitingRoomScreen;
