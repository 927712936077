import React, { useContext } from "react";
import SplitIntoTeamsModal from "../../../modals/SplitIntoTeamsModal";
import QuizContext from "../../../../contexts/QuizContext";
import useCreateMultipleTeamsMutation from "../../../hooks/mutations/useCreateMultipleTeamsMutation";

const SplitIntoTeamsController = ({ onCancel }) => {
  const { players } = useContext(QuizContext);
  const [createMultipleTeamsMutation] = useCreateMultipleTeamsMutation();

  const numOfPlayers = (players && players.length) || 0;

  const handleSplitIntoTeams = (numTeams) => {
    createMultipleTeamsMutation({
      variables: {
        numTeams,
        randomAssignment: true,
      },
    });
  };

  return (
    <SplitIntoTeamsModal
      numOfPlayers={numOfPlayers}
      onSplitIntoTeams={handleSplitIntoTeams}
      onCancel={onCancel}
    />
  );
};

export default SplitIntoTeamsController;
