import React, { useContext } from "react";
import styled from "styled-components";
import QuizContext from "../../contexts/QuizContext";
import { LIGHT_GREY_2, REGULAR_TEXT } from "../../colors";

const Wrapper = styled.div`
  margin-top: 30px;
  background-color: ${LIGHT_GREY_2};
  border-radius: 24px;
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
`;

const RoundTitle = styled.div`
  font-size: 50px;
  line-height: 130%;
  font-weight: bold;
  color: ${REGULAR_TEXT};
  text-align: center;
`;

export default () => {
  const { quiz } = useContext(QuizContext);

  return (
    <Wrapper>
      <RoundTitle>{quiz.name}</RoundTitle>
    </Wrapper>
  );
};
