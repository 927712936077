import React from "react";
import Modal from "../components/modals/BaseModal";
import ButtonRow from "../components/buttons/ButtonRow";
import LargeDangerButton from "../components/buttons/LargeDangerButton";
import LargeOutlineButton from "../components/buttons/LargeOutlineButton";

const ResetQuizModal = ({ onReset, onCancel }) => {
  return (
    <Modal
      title="Reset Quiz"
      text="Are you sure you want to reset this quiz?"
      buttons={
        <ButtonRow>
          <LargeDangerButton onClick={onReset} data-cy-name={"Reset"}>
            Reset
          </LargeDangerButton>
          <LargeOutlineButton
            noSpinner
            onClick={onCancel}
            data-cy-name={"Cancel"}
          >
            Cancel
          </LargeOutlineButton>
        </ButtonRow>
      }
      onEscapeKey={onCancel}
    />
  );
};

export default ResetQuizModal;
