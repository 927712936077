import React from "react";
import styled from "styled-components";
import { NEUTRAL_2, WHITE } from "../../../../../colors";

const Wrapper = styled.div`
  margin-left: 10px;
  background-color: ${NEUTRAL_2};
  height: 40px;
  width: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${WHITE};
  font-size: 22px;
  ${({ size }) =>
    size === "small" &&
    `
    font-size: 18px;
    height: 26px;
    width: 26px;
  `}
  ${({ size }) =>
    size === "tiny" &&
    `
    font-size: 16px;
    height: 20px;
    width: 20px;
  `}
`;

const TeamScore = ({ score }) => {
  return <Wrapper data-cy="team-score">{score}</Wrapper>;
};

export default TeamScore;
