import React from "react";
import styled from "styled-components";
import { PARAGRAPH_SMALL_BOLD } from "../../typography";
import { HIGHLIGHT_PRIMARY, HIGHLIGHT_PRIMARY_LIGHT } from "../../colors";
import BaseButton from "./BaseButton";

const StyledButton = styled(BaseButton)`
  background: none;
  border: none;
  color: ${HIGHLIGHT_PRIMARY};
  ${PARAGRAPH_SMALL_BOLD}
  cursor: pointer;
  padding: none;

  &:hover:enabled,
  &.hover {
    color: ${HIGHLIGHT_PRIMARY_LIGHT};
    text-decoration: underline;
    background: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: default;
  `}
`;

const LinkButton = ({ ...rest }) => {
  return <StyledButton spinnerColor={HIGHLIGHT_PRIMARY} {...rest} />;
};

export default LinkButton;
