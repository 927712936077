import React from "react";
import styled from "styled-components";
import { DARK_GREY_4 } from "../../../../../colors";

const Wrapper = styled.div`
  flex: 1;
  overflow: hidden;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  word-break: break-word;
  font-size: 20px;
  ${({ muted }) =>
    muted &&
    `
    color: ${DARK_GREY_4};
    font-style: italic;
  `}
`;

const TeamName = ({ team }) => {
  return (
    <Wrapper muted={!team.name} data-cy="team-name">
      {team.name || "waiting for team name..."}
    </Wrapper>
  );
};

export default TeamName;
