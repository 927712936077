import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import TeamSpeaker from "./TeamSpeaker";
import TeamInfo from "./TeamInfo";
import { useDrop } from "react-dnd";
import ItemTypes from "../../../util/dndItemTypes";
import QuizContext from "../../../contexts/QuizContext";
import LoadingScreen from "../../LoadingScreen";
import { ACCENT_4, DROP_HIGHLIGHT, LIGHT_GREY_1 } from "../../../colors";

export const Wrapper = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: top;
  min-width: 400px;
  max-width: 500px;
  background-color: ${LIGHT_GREY_1};
  padding: 20px;
  border-radius: 10px;
  ${({ highlight }) =>
    highlight &&
    `
    background-color: ${ACCENT_4};
  `}
  ${({ dropHighlight }) =>
    dropHighlight &&
    `
    background-color: ${DROP_HIGHLIGHT};
  `}
`;

const Team = ({ team }) => {
  const { assignToTeam } = useContext(QuizContext);

  const ref = useRef();
  const [isDropping, setIsDropping] = useState(false);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.PLAYER,
    canDrop: (item) => {
      if (team.players.find((player) => player.id === item.id)) {
        return false;
      }
      return true;
    },
    drop: async (item) => {
      setIsDropping(true);
      await assignToTeam(item.id, team.id);
      setIsDropping(false);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  drop(ref);

  return (
    <Wrapper ref={ref} dropHighlight={(isOver && canDrop) || isDropping}>
      <TeamSpeaker team={team} />
      <TeamInfo team={team} />
      {isDropping && <LoadingScreen />}
    </Wrapper>
  );
};

export default Team;
