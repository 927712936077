import { gql, useMutation } from "@apollo/client";

export default () =>
  useMutation(gql`
    mutation TeamAnswerQuestion($answerText: String!) {
      teamAnswerQuestion(answerText: $answerText) {
        team {
          id
        }
      }
    }
  `);
