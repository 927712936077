import React, { useContext, useEffect, useRef } from "react";
import QuizChatContext from "../../contexts/QuizChatContext";
import styled from "styled-components";
import Message from "./Message";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 0;
  flex: 1 1 auto;
  overflow: auto;
  height: 1px;
  width: 100%;
`;

const Scroller = styled.div`
  height: 100%;
  overflow: scroll;
`;

export default () => {
  const { messages, refetchedAt } = useContext(QuizChatContext);
  const scrollerRef = useRef();

  useEffect(() => {
    if (scrollerRef.current) {
      scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight;
    }
  }, [scrollerRef.current, refetchedAt]);

  if (!messages || messages.length === 0) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <Scroller ref={scrollerRef}>
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </Scroller>
    </Wrapper>
  );
};
