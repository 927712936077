import React, { useContext } from "react";
import styled from "styled-components";
import Participant from "../NewParticipant";
import MainRoomContext from "../../contexts/MainRoomContext";
import QuizContext from "../../contexts/QuizContext";
import getLogger from "../../util/getLogger";

const logger = getLogger("RealDominantSpeaker");

const Wrapper = styled.div`
  width: 480px;
  height: 360px;
`;

const ParticipantVideoWrapper = styled.div`
  display: none;
  ${({ show }) =>
    show &&
    `
    display: block;
  `}
`;

const RealDominantSpeaker = () => {
  const { participantsWithVideo, dominantSpeaker } = useContext(
    MainRoomContext
  );
  const { hosts, players } = useContext(QuizContext);

  const dominantSpeakerIdentity = dominantSpeaker && dominantSpeaker.identity;

  const lookupHostOrPlayer = (identity) =>
    hosts.find((host) => host.id === identity) ||
    players.find((player) => player.id === identity);

  return (
    <Wrapper>
      {Object.values(participantsWithVideo).map((participant) => {
        const hostOrPlayer = lookupHostOrPlayer(participant.identity);
        if (!hostOrPlayer) {
          logger.error(
            "participant without corresponding host or player",
            participant.identity
          );
          logger.debug("participant", participant);
        }
        return (
          <ParticipantVideoWrapper
            key={participant.identity}
            show={participant.identity === dominantSpeakerIdentity}
          >
            <Participant
              size="dominant"
              participant={participant}
              name={hostOrPlayer ? hostOrPlayer.name : "speaker"}
              isDominantSpeaker
            />
          </ParticipantVideoWrapper>
        );
      })}
    </Wrapper>
  );
};

export default RealDominantSpeaker;
