import React, { useContext, useEffect } from "react";
import SessionDataContext from "../../contexts/SessionDataContext";
import QuizContext from "../../contexts/QuizContext";
import inDevelopment from "../util/inDevelopment";
import inTesting from "../../util/inTesting";
import LogRocket from "logrocket";

const IdentificationContext = React.createContext();

export const IdentificationContextProvider = ({ children }) => {
  const { myHostId, myPlayerId, myTeamId } = useContext(SessionDataContext);
  const { publicQuizId, myHost, myPlayer, myTeam } = useContext(QuizContext);

  const myId = myHostId || myPlayerId;

  let myPlayerName;
  if (myPlayer) {
    if (myPlayer.leader) {
      myPlayerName = `${myPlayer.name} (leader)`;
    } else {
      myPlayerName = myPlayer.name;
    }
  }
  const myTeamName = myTeam && myTeam.name;

  let myName;
  if (myHost) {
    myName = `${publicQuizId} | ${myHost.name} (host)`;
  } else if (myPlayer) {
    if (myTeam) {
      myName = `${publicQuizId} | ${myPlayerName} | ${myTeamName}`;
    } else {
      myName = `${publicQuizId} | ${myPlayerName}`;
    }
  }

  useEffect(() => {
    if (!inDevelopment() && !inTesting()) {
      if (myHostId || (myPlayerId && myTeamName)) {
        const logRocketId = `${myId}`;

        LogRocket.identify(logRocketId, {
          name: myName,
          host: !!myHostId,
          hostId: myHostId,
          playerId: myPlayerId,
          playerName: myPlayerName,
          teamId: myTeamId,
          teamName: myTeamName,
        });
      }
    }
  }, [myHostId, myPlayerId, myTeamName]);

  return (
    <IdentificationContext.Provider value={{}}>
      {children}
    </IdentificationContext.Provider>
  );
};

export default IdentificationContext;
