import React, { useContext } from "react";
import styled from "styled-components";
import HostContainer from "./HostContainer";
import CenterContainer from "./MainContainer";
import QuizContext from "../../../contexts/QuizContext";
import {
  NAME_TEAMS,
  QUESTION,
  QUIZ_INTRO,
  TIEBREAKER,
} from "../../../constants";

const Wrapper = styled.section`
  min-height: 100px;
  width: 100%;
  display: flex;
`;

export default () => {
  const {
    quiz,
    myHostId,
    myPlayerId,
    myTeamId,
    delayingMainRoomJoin,
  } = useContext(QuizContext);

  if (!quiz) {
    return null;
  }

  let hideHosts = false;
  if (quiz.ended && !myHostId) {
    hideHosts = true;
  }
  if (
    quiz.started &&
    myPlayerId &&
    !myTeamId &&
    ![QUIZ_INTRO, NAME_TEAMS].includes(quiz.whatToShow)
  ) {
    hideHosts = true;
  }
  if ([NAME_TEAMS, QUESTION, TIEBREAKER].includes(quiz.whatToShow)) {
    hideHosts = true;
  }
  if (delayingMainRoomJoin) {
    hideHosts = true;
  }

  return (
    <Wrapper>
      {<HostContainer hide={hideHosts} coHost={false} />}
      <CenterContainer />
      {<HostContainer hide={hideHosts} coHost={true} />}
    </Wrapper>
  );
};
